import { showSnackbar } from "@/UIHelper";
import { Capacitor } from "@capacitor/core";
import { NativeBiometric, AvailableResult } from "@capgo/capacitor-native-biometric";

let helper: LoginHelperDef;
const allunlimtedDomainName = "allunlimted.com";
export async function SetLoginHelper(def: LoginHelperDef) {
    helper = def;
}

export function GetLoginHelper() { return helper; }
export async function GetUserName() { return await helper?.getUserName(); }
export async function GetDisplayName() { return await helper?.getDisplayName(); }

export async function Logout() {
    if (helper) {
        await helper.logout();
    }
}

export function IsLoginHelperSet() { return helper !== undefined; }

let bioAvailable: AvailableResult | undefined;
export async function isBiometricAvailable() {
    console.log("Web: " + Capacitor.getPlatform());
    if (Capacitor.getPlatform() === "web") { return false; }
    if (!bioAvailable) {
        bioAvailable = await NativeBiometric.isAvailable();
    }
    console.log("BIO: " + bioAvailable.isAvailable);
    return bioAvailable.isAvailable;
}

export function hasBioCredentialsStored() {
    return localStorage.hasBioCredentialsStored === "1";
}

let bioVerified = false;
async function verifyIdentity(force: boolean = false) {
    if (!bioVerified || force) {
        bioVerified = await NativeBiometric.verifyIdentity({
            reason: "Automatischer Login / Fast login!",
            title: "Log in",
            subtitle: "Schnell Einstieg/Fast login",
            description: "Einstieg ohne Passwort Eingabe/Login without password.",
        })
            .then(() => true)
            .catch(() => false);
    }
    return bioVerified;
}

export async function getBioCredential() {
    const verified = await verifyIdentity();

    if (!verified) { return; }

    const credentials = await NativeBiometric.getCredentials({
        server: allunlimtedDomainName,
    });

    return {
        user: credentials.username,
        hash: credentials.password
    };
}
export async function deleteBioCredentials() {
    // localStorage.hasBioCredentialsStored = "";
    if (await isBiometricAvailable()) {
        localStorage.hasBioCredentialsStored = "";
        await NativeBiometric.deleteCredentials({ server: allunlimtedDomainName });
    }
}
export async function setBioCredential(user: string, hash: string) {
    try {

        await verifyIdentity();
        // Save user's credentials
        await deleteBioCredentials();
        await NativeBiometric.setCredentials({
            username: user,
            password: hash,
            server: allunlimtedDomainName,
        });
        localStorage.hasBioCredentialsStored = "1";
    } catch (error) {
        showSnackbar({ text: "cannot save credentials", duration: 1000, color: "red" });
        console.error("cannot store credentials:" + error);
    }
}
