






































































import CreateList from "../CreateList";
import "chartist/dist/index.css";
import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import Chartist from "vue-chartist";
import {
  getKennungTitle,
  getKennungValueDisplay,
  getKZTitle,
  getKZValue,
  getKZValueDisplay,
} from "@/DataHelper";
import Constants from "@/Constants";
import { Globals } from "@/Globals";

Vue.use(Chartist);
@Component
export default class ChartistList extends CreateList {
  @Prop({ default: false }) protected showTitle!: boolean;
  @Prop({ default: false }) protected small!: boolean;
  @Prop({ default: 400 }) protected fixHeight!: number;
  @Prop({ default: "white" }) public color!: string;
  private myHeight: number = 400;
  private bar: boolean = true;
  private pie: boolean = false;
  private title: string = "";
  private initalized: boolean = false;
  public chartData: any = {};
  private eventHandlers: any[] = [];

  private text: string = this.$globalsBezeichnung(
    Globals.Begriff,
    "Web_verbucht",
    "verbucht"
  );
  private text1: string = this.$globalsBezeichnung(Globals.Begriff, "Web_offen", "offen");

  private options: any = {
    axisX: {
      showGrid: true,
    },
    seriesBarDistance: 10,
    reverseData: true,
    height: window.innerHeight,
    stretch: true,
    horizontalBars: true,
    stackBars: true,
    chartPadding: {
      top: 10,
      right: 15,
      bottom: 0,
      left: 80,
    },
  };
  private optionsSmall: any = {
    axisX: {
      showGrid: true,
      showLabel: true,
      offset: 0,
    },
    axisY: {
      showGrid: false,
    },
    low: 0,
    high: 12,
    seriesBarDistance: 15,
    height: "2em",
    horizontalBars: true,
    stackBars: true,
    reverseData: true,

    fullWidth: true,
    showLabel: false,
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  };
  private ratio: string = "";
  private responsiveOptions: any[] = [
    [
      "screen and (min-width: 641px) and (max-width: 1024px)",
      {
        seriesBarDistance: 5,
        axisX: {
          offset: 80,
          labelInterpolationFnc(value: any) {
            return value;
          },
        },
        axisY: {
          offset: 70,
        },
      },
    ],
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          offset: 80,
          labelInterpolationFnc(value: any) {
            return value[0];
          },
        },
        axisY: {
          offset: 70,
        },
      },
    ],
  ];
  private optionsPie = {
    height: "5em",
    showLabel: true,
  };
  private responsiveOptionsPie: any[] = [
    [
      "screen and (min-width: 640px)",
      {
        chartPadding: 30,
        labelOffset: 100,
        labelDirection: "explode",
        labelInterpolationFnc(value: number) {
          return value;
        },
      },
    ],
    [
      "screen and (min-width: 1024px)",
      {
        labelOffset: 80,
        chartPadding: 20,
        labelInterpolationFnc(value: number) {
          return value;
        },
      },
    ],
  ];
  // private plugins: any = [Chartist.pl}ugins.legend()];
  // private plugins: any = [Chartist.plugins.tooltip()];
  @Prop({ default: "Bar" }) protected type!: string;
  // doku:
  // http://gionkunz.github.io/chartist-js/getting-started.html#the-configuration-of-your-chart
  private created() {
    if (this.type === "Pie") {
      this.pie = true;
      this.bar = false;
    }
    if (this.fixHeight) {
      this.myHeight = this.fixHeight;
    } else {
      this.myHeight = window.innerHeight - 200;
    }
  }
  public mounted() {
    try {
      const me = this;
      if (me.small) {
        this.options = this.optionsSmall;
      }
      if (!this.mountedBase()) {
        return;
      }
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }
  // private labelInterpolationFnc(value: any) {
  //   const lodash = require("lodash");
  //   const sum = lodash.sum(this.data.series);
  //   return Math.round((value / sum) * 100) + "%";
  // }

  protected refreshItems() {
    try {
      const labels = [];
      const series: any = [];
      const series1 = [];
      const seriesLegend = [];
      // const seriesPerc: any = [];

      if (this.data.length > 0) {
        this.initalized = true;
        this.text = getKennungTitle("verbucht", this.columns);
        this.text1 = getKennungTitle("Differenz", this.columns);
        for (let i = 0; i < this.data.length; i++) {
          const row = this.data[i];
          const label = getKZTitle(row, "label", this.columns);
          const labelBez = getKennungValueDisplay(row, "labelBez", this.columns);
          // const image = getKZValueDisplay(row, "image", this.columns);
          const serie = getKZValue(row, "series", this.columns);
          const serie1 = getKZValue(row, "series1", this.columns);
          const serieText = getKZTitle(row, "series", this.columns);
          const serie1Text = getKZTitle(row, "series1", this.columns);
          if (labelBez && !this.pie) {
            labels.push(labelBez);
          } else if (label) {
            labels.push(label);
          }
          if (serie) {
            series.push(serie);
          }
          if (serie1) {
            series1.push(serie1);
          }
          if (serie !== "") {
            seriesLegend.push({ name: serieText, data: serie });
          }
          if (serie1 !== "") {
            seriesLegend.push({ name: serie1Text, data: serie1 });
          }
        }
        if (!this.pie && !this.small) {
          const seriesPercent: any = [];
          const lodash = require("lodash");
          let sumVal = lodash.sum(series);
          sumVal = Math.round(sumVal);
          if (sumVal > 0) {
            for (let j = 0; j < series.length; j++) {
              let val = series[j];
              val = Math.round(val);
              let perc = (val / sumVal) * 100;
              perc = Math.round(perc);
              seriesPercent.push(perc);
            }
            this.chartData = {
              labels,
              series: [seriesPercent],
            };
          } else {
            this.chartData = {
              labels,
              series: [series],
            };
          }
        } else {
          this.chartData = {
            labels,
            series: [series, series1],
          };
        }

        this.initalized = true;
        this.showLoading = false;
      } else {
        this.showLoading = false;
      }
    } finally {
      this.$emit("dataloaded");
    }
  }
}
