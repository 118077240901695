import { registerPlugin } from "@capacitor/core";

export interface AzureOAuthPlugin {
    initAzureNative(params: { clientId: string; tenantId: string, authority?: string }): Promise<void>;
    Authenticate(params: { scopes: string }): Promise<{
        token: string,
        userName: string,
        expires: string
    }>;

    getAccessTokenNative(): Promise<{ token: string }>;
    checkRefreshToken(): Promise<{ token: string }>;
    logout(): void;
    getCallbackUrl(): Promise<{ uri: string }>;
}
const AzureOAuthPlugin: AzureOAuthPlugin | undefined = registerPlugin<AzureOAuthPlugin>('AzureOAuthPlugin');

export {AzureOAuthPlugin};
