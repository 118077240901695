import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Capacitor } from "@capacitor/core";
import AUApi from "./api";
import { HttpHelperPlugin, RequestConfig } from "./plugins/HttpHelperPlugin";
declare var ALLConfig: ALLConfigType;

declare type ValidatorDelegate = (options: RequestConfig | string) => boolean;

let useNativeHttp = false;

const validators: ValidatorDelegate[] = [];

export function registerRequestValidator(validator: ValidatorDelegate) {
    validators.push(validator);
}

// implentaion from @capacitor/http plugin but with fixes caused by bug in there
// seealso:
// https://github.com/capacitor-community/http/


export async function initHttpHandling() {
    useNativeHttp = (ALLConfig.nativeHttp && (Capacitor.getPlatform() !== "web")) ?? false;
    /* if (ALLConfig.debug) {
        axios.interceptors.response.use(response => {
            console.log('Response:', JSON.stringify(response, null, 2));
            return response;
        });
    } */
}


export async function makeRequest<T>(options: RequestConfig | string): Promise<AxiosResponse<T>> {
    if (!validateRequest(options)) { throw new Error("validation failed!"); }

    console.log("request to: " + JSON.stringify(options));

    useNativeHttp = (ALLConfig.nativeHttp && (Capacitor.getPlatform() !== "web")) ?? false;
    let response: AxiosResponse<T>;
    try {
        let params: RequestConfig;
        if (typeof options === "string") {
            params = {
                method: "GET",
                url: options
            };
        } else {
            params = options;
        }
        if (!params.method) { params.method = "GET"; }
        if (!params.dataType) { params.dataType = "json"; }
        if (!params.contentType) { params.contentType = "application/json"; }

        if (params.headers) {
            for (const key of Object.keys(params.headers)) {
                params.headers[key] = "" + params.headers[key];
            }
        }

        if (!params.headers) {
            params.headers = { ID: AUApi.Current.user?.id };
        } else {
            if (!params.headers.ID) {
                params.headers.ID = AUApi.Current.user?.id;
            }
        }


        if (useNativeHttp) {
            if (!params.headers) {
                params.headers = { "Content-Type": params.contentType };
            } else if (!params.headers["Content-Type"]) {
                params.headers["Content-Type"] = params.contentType;
            }
            if (!params.headers.Accept) {
                params.headers.Accept = "application/json, text/plain, */*";
                params.headers["Accept-Encoding"] = "gzip, deflate, br";
                params.headers["Accept-Language"] = "de-DE,de;q=0.9,en-US;q=0.8,en;q=0.7";
            }
            if (ALLConfig.proxy) {
                const url = params.url.substring(ALLConfig.proxy.length);

                if (url.length === 0) { throw new Error("invalid uri"); }

                console.log("url reformatting: " + url);
                params.url = decodeURIComponent(url);
                console.log("url reformatted: " + params.url);
            }

            response = await HttpHelperPlugin.request(params);

            if (!isHttpSuccess(response.status)) {
                throw {
                    response,
                    message: response.statusText
                };
            }

            return response;
        }

        if (params.contentType === "application/x-www-form-urlencoded") {
            if (!params.headers) {
                params.headers = { "Content-Type": "application/x-www-form-urlencoded" };
            } else {
                params.headers["Content-Type"] = "application/x-www-form-urlencoded";
            }
            const bodyFormData = new URLSearchParams();
            for (const key of Object.keys(params.data)) {
                bodyFormData.append(key, params.data[key]);
            }
            params.data = bodyFormData;

        }

        response = await axios(params as AxiosRequestConfig) as AxiosResponse<T>;

        return response;
    } catch (error) {
        console.error("error in makeRequest:" + JSON.stringify(error));
        throw error;
    }
}
function validateRequest(options: string | RequestConfig) {
    return validators.every(val => val(options));
}

function isHttpSuccess(status: number) {
    console.log("status: ", status);
    const success = status >= 200 && status < 300; // default
    // if (!success) { debugger; }
    return success;
}
