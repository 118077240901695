import { render, staticRenderFns } from "./BaseVCalendar.vue?vue&type=template&id=f4776304&scoped=true"
import script from "./BaseVCalendar.vue?vue&type=script&lang=ts"
export * from "./BaseVCalendar.vue?vue&type=script&lang=ts"
import style0 from "./BaseVCalendar.vue?vue&type=style&index=0&id=f4776304&prod&scoped=true&lang=css"
import style1 from "./BaseVCalendar.vue?vue&type=style&index=1&id=f4776304&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4776304",
  null
  
)

export default component.exports