import AUApi from "@/api";
import { DialogParameter, showDialog } from "@/UIHelper";
import { getValue, getKZColumn, getKZValue } from "@/DataHelper";
import { AxiosResponse } from "axios";
import { pushSite, showSite } from "@/NavigationHelper";

export declare interface ItemContainer {
    active: boolean;
    edit: boolean;
    id: number;
    hideTitle: boolean;
}
export declare interface DashboardRow {
    groups: ItemGroup[];
    id: number;
}
export declare interface ItemGroup {
    title?: string;
    items: ItemContainer[];
    id: number;
}

export interface AufgabeItemContainer extends ItemContainer {
    aufgabe: ALLAufgabe;
    size?: number;
    orientation?: ItemOrientation;
}

export declare interface AufgabeStatus {
    icon: any;
    Nr: number;
    aic: number;
    bezeichnung: string;
    bild: string;
    darstellungAic: number;
    darstellungKennung:
    | "Balken_Chart"
    | "Balken_Horizontal"
    | "BalkenStackedMinMax"
    | "Chart_Kreis"
    | "Kalender" // vorerst wurscht
    | "Liste" // BaseList
    | "QuickView"
    | "Tabelle" // einfache tabelle
    | "KPI"; // Nachrichten
    filterAic: number;
    filterKennung: string;
    hsDefinition: number;
    kennung: string; // "VG_Uebersicht_Urlaubssaldo_VG_MA_Uebers"
    periode: boolean;
    stamm: number;
    zeitArt: "offen" | "Tag" | "Woche" | "Monat" | "Quartal" | "Jahr";
    //   zusatzInfo: boolean;
    hsAic: number;

    zuordnung: AufgabeStatusZuordnung[];
    tooltip?: string;
}

export declare interface AufgabeStatusZuordnung {
    gruppe: "Druck" | "Modell" | "Aufgabe" | "Frame" | "Hauptschirm" | "Abfrage";
    bezeichnung: string;
}

export declare interface AufgabeStatusZuordnungAufgabe {
    kennung?: string;
    prog?: number;
    aic?: number;
    gruppe?: string;
    bezeichnung: string;
    zeitraum: string;
    alias: string;
    toggleSight: string;
    name?: string;
    aicHS?: number;
    stammQuery?: string;
    auswahlQuery?: string;
    neuDialog?: string;
}
export declare type ChartType = "Line" | "Bar" | "Pie" | "BalkenStackedMinMax";
export declare interface AufgabeStatusContainer {
    size?: number;
    status: AufgabeStatus;
    titel?: string;
    zeitbereich: APIZeitbereich;
    orientation: ItemOrientation;
    params: AufgabeChartParams;
}


export declare interface AufgabeStatusModell extends AufgabeStatusZuordnung {
    aic: number;
    kennung: string;
    bezeichnung: string;
    tooltip: string;
    icon: string;
    farbe: string;
}

export declare interface AufgabeStatusDruck extends AufgabeStatusZuordnung {
    aic: number;
    kennung: string;
    bezeichnung: string;
    seitenUmbruch: boolean;
    keineGruppierung: boolean;
    periode: string;
    tooltip: string;
    pers: boolean;
    stt: number;
}
export declare interface AufgabeStatusAbfrage extends AufgabeStatusZuordnung {
    aic: number;
    bew?: number;
    kennung: string;
    bezeichnung: string;
    tooltip: string;
    stt?: number;
}
export declare interface AufgabeStatusFrame extends AufgabeStatusZuordnung {
    aic: number;
    kennung: string;
    bezeichnung: string;
    zeitraum: string;
    alias: string;
    toggleSight: string;
    name?: string;
}
export declare interface AufgabeStatusHauptschirm extends AufgabeStatusZuordnung {
    aic: number;
    kennung: string;
    bezeichnung: string;
}

export declare interface AufgabeChartParams {
    zeitbereich?: APIZeitbereich;
    query: string;
    queryData?: QueryResponse;
    aicStamm?: number;
    aic?: number;
    size?: number;
    icon?: string;
    title?: string;
    chartType?: ChartType;
    chartOrientation?: ItemOrientation;
    orientation?: ItemOrientation;
    bewegungsdaten?: boolean;
    tooltips?: boolean;
    hsDefinition?: number;

    zuordnungen?: AufgabeStatusZuordnung[];
    zusatzInfo?: AufgabeStatusZuordnungAufgabe;
    zusatzFrame?: AufgabeStatusFrame[];

    aufgabenDefinition?: QueryResponse;
    abwZeitbereich?: string;
    tooltip?: string;
    aufgabe?: ALLAufgabe;
}
let dialogOpened: boolean = false;
export async function openZusatzInfoDialog(
    zusatzInfo: AufgabeStatusZuordnungAufgabe,
    title?: string,
    aic?: number,
    aufgabenDefinition?: QueryResponse,
    zeitbereich?: APIZeitbereich,
    chartAic?: number
) {
    const inPage = chartAic && await getKZAufgabeDefinitonAsync<boolean>("dialogInPage", chartAic);
    if (chartAic && inPage === true) {
        const scrollX = window.scrollX;
        const scrollY = window.scrollY;

        const data = {
            zeitbereich,
            zusatzInfo,
            aic,
            aufgabenDefinition,
            page: true,
            onClose() {
                window.scrollTo({
                    left: scrollX,
                    top: scrollY,
                    behavior: "smooth"
                });
            }
        };
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: "smooth"
        }); // nach oben scrollen

        pushSite("DashboardChartsDetailsPopUp", data, title ?? "Details", aic, () => {
            window.scrollTo({
                left: scrollX,
                top: scrollY,
                behavior: "smooth"
            });
        });
        return;
    }

    if (dialogOpened) {
        return;
    }
    const dlg: DialogParameter = {
        site: "DashboardChartsDetailsPopUp",
        title: title ?? "Details",
        titleColor: "dvhBackground",
        titleClass: "white--text",
        data: {
            zeitbereich,
            zusatzInfo,
            aic,
            aufgabenDefinition,
            setTitle(t: string) { dlg.title = t; },
            hideTitle(hide: boolean = true) { dlg.showTitle = !hide; }
        },
        onClose: (p) => {
            dialogOpened = false;
            console.log("closed dialog...");
            return true;
        }
    };
    dialogOpened = true;
    showDialog(dlg);
}

export function getAlternateColor(ix: number) {
    return ix % 2 === 0 ? "" : "lightgray";
}

export declare interface DashboardParams {
    persoenlich: boolean;
    name: string;
    benutzergruppe: string;
    definition: DashboardRow[];
    benutzer: string;
    aic: number;
    dialog: boolean;
    dialogChange: boolean;
}

let boardDefinitions: DashboardParams[] = [];

export async function getBoardDefinitions(persoenlich: boolean, app: Vue, reload?: boolean) {
    if (reload) {
        boardDefinitions = [];
    }
    let userBg: [] = [];
    const userLokal = app.$api.user.user;
    // Benutzergruppen finden
    if (app) {
        userBg = app.$api.user.bg;
    }
    if (boardDefinitions && boardDefinitions.length > 0) {
        return boardDefinitions.filter(b => b.persoenlich === persoenlich
            && (b.benutzergruppe === null || userBg?.find((c: any) =>
                (c === b.benutzergruppe))) && ((b.benutzer === null || b.benutzer === undefined)
                || (b.benutzer && userLokal === b.benutzer)));
    }
    const response = await AUApi.Current.getQuery("Web_NameInitAufgabenDefinitionVG") as AxiosResponse<QueryResponse>;
    const query = response.data;

    const nameCol = getKZColumn("name", query.columns);
    const jsonCol = getKZColumn("json", query.columns);
    const persCol = getKZColumn("pers", query.columns);
    const userGroup = getKZColumn("usergroup", query.columns);
    const userCol = getKZColumn("user", query.columns);
    const bewAic = getKZColumn("aic", query.columns);

    boardDefinitions = query.data.map(row => {
        return {
            name: getValue(row, nameCol),
            persoenlich: getValue(row, persCol),
            benutzergruppe: getValue(row, userGroup),
            benutzer: getValue(row, userCol),
            definition: JSON.parse(getValue(row, jsonCol)),
            color: "white",
            aic: getValue(row, bewAic),
            dialog: false,
            dialogChange: false,
        };
    });
    return boardDefinitions?.filter(b => (b.persoenlich === persoenlich)
        && (b.benutzergruppe === null || userBg?.find((c: any) =>
            (c === b.benutzergruppe))) && ((b.benutzer === null || b.benutzer === undefined)
            || (b.benutzer && userLokal === b.benutzer)));
}

export function getAufgabeDefRow(aicStamm: number, aufgabenDefinition: QueryResponse) {
    if (!aufgabenDefinition) { return; }
    return aufgabenDefinition?.data.find(
        (r: any) => r.aic_Stamm === aicStamm
    );
}

export async function getKZAufgabeDefinitonAsync<T>(
    kz: string,
    aicStamm: number) {
    const def = await getAufgabeDefinition();
    return getKZAufgabeDefiniton<T>(kz, aicStamm, def);
}

export function getKZAufgabeDefiniton<T>(
    kz: string,
    aicStamm: number,
    aufgabenDefinition: QueryResponse,
    defaultVal?: T) {
    const aufgabenDefRow = getAufgabeDefRow(aicStamm, aufgabenDefinition);
    if (!aufgabenDefRow) { return defaultVal; }
    return getKZValue(aufgabenDefRow, kz, aufgabenDefinition?.columns) ?? defaultVal;
}

let aufgabenDefinitionCache: QueryResponse | undefined;
// gibt die aktuelle aufgabendefinion zurÃ¼ck cacht sie aber fÃ¼r die aktuelle session
export async function getAufgabeDefinition(): Promise<QueryResponse> {
    if (aufgabenDefinitionCache) { return aufgabenDefinitionCache; }
    try {
        const response = await AUApi.Current.getQuery(
            "Web_Status_Definitionen",
        ) as AxiosResponse<QueryResponse>;

        if (response.status < 300 && !response.data.error) {
            return aufgabenDefinitionCache = response.data;
        }
        if (response?.data.error) {
            console.error("error getting aufgabedefinion!", response?.data.error);
        }
    } catch (e) {
        console.error("error getting aufgabedefinion!", e);
    }
    return { header: [], columns: [], data: [], error: "no data from server" };
}


let quickviewSelectionCallBack: (v: boolean) => void;
export function setQuickviewSelectionCallBack(cb: ((v: boolean) => void)) {
    quickviewSelectionCallBack = cb;
}
export function callQuickviewSelectionCallBack(checked: boolean) {
    if (quickviewSelectionCallBack) {
        quickviewSelectionCallBack(checked);
    } else { console.warn("no quickviewSelectionCallBack set..."); }
}
