import { registerPlugin, WebPlugin } from "@capacitor/core";
// import { App } from "@capacitor/app";
// import { Directory, WriteFileOptions, Encoding } from "@capacitor/filesystem";

export interface AppUpdatePluginInterface {
    downloadAndInstallUpdate(req: DownloadUpdateRequest): Promise<DownloadUpdateResult>;
    checkNativeCopyAvailable(): Promise<CheckAppUpdateAvailableResult>;
    shouldFallbackToFrame(maxFallbackMajorVersion: number): Promise<ShouldFallbackToFrameResult>;
}

export interface ShouldFallbackToFrameResult {
    shouldFallback: boolean;
}
export interface CheckAppUpdateAvailableResult {
    available: boolean;
    info: string;
    index: string;
}

export interface DownloadUpdateRequest {
    message: string;
    downloadUrl: string;
    config: string;
}

export interface DownloadUpdateResult {
    available: boolean;
    index?: string;
    info?: string;
}

class AppUpdatePluginImplementation extends WebPlugin implements AppUpdatePluginInterface {
    public downloadAndInstallUpdate(req: DownloadUpdateRequest): Promise<DownloadUpdateResult> {
        console.log("no update here - why are we here ?");
        throw new Error("Method not implemented.");
    }
    public checkNativeCopyAvailable(): Promise<CheckAppUpdateAvailableResult> {
        return Promise.resolve({ available: false, index: "", info: "" });
    }
    public shouldFallbackToFrame(maxFallbackMajorVersion: number): Promise<ShouldFallbackToFrameResult> {
        return Promise.reject("not implemented");
    }
}

const AppUpdatePlugin = registerPlugin<AppUpdatePluginImplementation>(
    "AppUpdatePlugin",
    {
        web: () => new AppUpdatePluginImplementation(),
    }
);

export { AppUpdatePlugin };
