

































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
import { getColumnByKennung } from "@/DataHelper";
import { Globals } from "@/Globals";
import { getDisplay } from "@/DataHelper";

@Component
export default class EditToggleSight extends EditComponent {
  private btnItems: any[] = [];
  private actTransprt: string = "";
  private aicEig: any = "";
  private showLoading: boolean = false;
  @Prop({ default: "BEZEICHNUNG" }) public textKennung!: string;
  @Prop({ default: "WEB_ICON" }) public icon!: string;
  @Prop({ default: "setShow" }) public setShow!: string;

  constructor() {
    super();
  }
  public mounted() {
    this.data = this.getInitValue();
    this.aicEig = this.column.aicEig;
    this.btnItems = this.column.meta.combo;
    if (this.column.meta.combo) {
      this.btnItems = this.column.meta.combo;
    } else if (this.column.meta.filter) {
      const me = this;
      const filter = this.column.meta.filter;
      const query = this.$globalsAic(Globals.Begriff, filter).kennung;
      const SbRefresh = this.$globalsBegriffCache(Globals.Begriff, query, false);
      const cache = SbRefresh ? false : true; // SbRefresh = Berechtigung refresh - daher KEIN Cachen!
      if (query) {
        this.showLoading = true;
        const vecStamm: any[] = [];
        this.$api.getQuery(
          query,
          (data: any) => me.dataLoadedFromServer(data),
          0,
          this.queryZeitbereich,
          0,
          cache,
          false,
          "1",
          0,
          vecStamm
        );
      }
    }
  }
  private dataLoadedFromServer(data: any) {
    const me = this;
    const columns = data.data.columns;
    const combo = data.data.data;
    const bezCol = columns.find((c: any) => c.kennung === me.textKennung);
    const icon = columns.find((c: any) => c.kennung === me.icon);
    const setShow = columns.find((c: any) => c.kennung === me.setShow);
    const columnBez = bezCol.name;
    const iconBez = icon.name;
    let showBez = "";
    if (setShow) {
      showBez = setShow.name;
    }
    if (combo.length > 0) {
      const mapped = combo.map((row: any): any => {
        const rowData = [];
        return {
          aic: row.aic_Stamm,
          bezeichnung: row[columnBez],
          icon: row[iconBez],
          setShow: row[showBez],
        };
      });
      // gibt es nur 1nen Button - dann den initlisieren!
      if (mapped.length === 1) {
        this.checkedChanged(mapped[0]);
        this.actTransprt = mapped[0].aic;
      } else if (this.data) {
        this.actTransprt = this.data.aic;
      }
      this.showLoading = false;
      this.btnItems = mapped;
    }
    this.$emit("mountFinished");
  }
  protected checkedChanged(txt: any) {
    if (this.actTransprt === txt.aic) {
      this.actTransprt = "0";
    } else {
      this.actTransprt = txt;
    }
    this.valueChanged(this.actTransprt); // zum event triggern
  }
}
