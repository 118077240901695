
















import { Vue, Component, Prop, Provide } from "vue-property-decorator";
@Component
export default class Anwesenheiten extends Vue {
  @Prop() public aic: any;
  @Prop({ default: null }) public siteData: any;
  protected druckItems!: PrintItems[];

  private showClose: boolean = false;
  private initalized: boolean = false;
  public mounted() {
    if (this.siteData?.showBack) {
      this.showClose = this.siteData.showBack;
    }
    const formular = this.$globalsKennung(this.Globals.Begriff, "SoftTermAnwesenheiten");
    if (formular) {
      this.druckItems = formular.druck ?? [];
    }
    this.initalized = true;
  }
}
