























































































































import { Vue, Component } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import EditTextArea from "@/editor/EditTextArea.vue";

@Component
export default class EditHotline extends EditPage {
  constructor() {
    super();
    this.query = "Web_DVH_Anlage_Hotline"; // Definiert die Abfrage fÃ¼r diese Seite
  }

  public disabled: boolean = false; // Deaktiviert die Eingabefelder, falls true
  public clearable: boolean = false; // ErmÃ¶glicht das LÃ¶schen des Eingabefeldinhalts, falls aktiviert
  private deleteVisible: boolean = false; // Kontrolliert die Sichtbarkeit der LÃ¶schen-SchaltflÃ¤che

  public mounted() {
    this.showLoading = true;
    this.mountedBase(); // Initialisiere die BasisfunktionalitÃ¤ten der geerbten EditPage-Komponente
    if (this.data && this.data.aic_Bew_pool) {
      this.deleteVisible = true; // Setzt die LÃ¶schen-SchaltflÃ¤che auf sichtbar, wenn bestimmte Daten vorhanden sind
    }
  }

  public bewDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.onDelete(this.data.aic_Bew_pool, ""); // FÃ¼hrt die LÃ¶schmethode aus
      this.$emit("back"); // Emittiert das 'back'-Ereignis, um zur vorherigen Ansicht zurÃ¼ckzukehren
    }
  }
}
