


























































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { Globals } from "@/Globals";
import CreateList from "@/components/CreateList";
import { getMonthNames } from "../../editor/CalenderHelper";
import {
  getValue,
  getKZValueDisplay,
  getKZTitle,
  getColumnByKennung,
  getKennungValue,
} from "@/DataHelper";
import { showSnackbar } from "@/UIHelper";

@Component
export default class UestAntrag extends CreateList {
  constructor() {
    super();
    this.queryMutable = "WebAnzeige_monatl_Zulagen";
  }
  // @Prop() public zeitbereich!: APIZeitbereich | null;
  private sperre: boolean = false;
  // @Prop() public aic: any;
  @Prop({ default: 0 }) protected ampelZulageMutable!: number;
  public titleCard: string = "";
  protected myHeight: number = 900;
  private datenLeer: boolean = true;
  private iconAmpel: string = "";
  private aicBew!: number | null;
  private showSave: boolean = false;
  private antrag!: Date | null;
  private mounted() {
    try {
      const me = this;
      if (this.zeitbereich) {
        this.editZeitbereich = {
          von: this.zeitbereich.von,
          bis: this.zeitbereich.bis,
          bereich: "Monat",
        };

        const day = this.editZeitbereich.von;
        this.titleCard = getMonthNames()[day.getMonth()];
      }

      if (!this.mountedBase()) {
        return;
      }
      // setTimeout(() => {
      //   this.setAmpel();
      // }, 500);
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }
  protected setValue(item: any, index: number, evt: any) {
    this.data[index][item.col.name] = evt;
  }
  // private setAmpel() {
  //   if (this.ampelZulageMutable === 1) {
  //     this.sperre = true;
  //   }
  //   this.iconAmpel =
  //     "<img src='/" +
  //     this.ampelZulageMutable +
  //     "_Ampel.png' alt='" +
  //     this.ampelZulageMutable +
  //     "' />";
  //   this.showLoading = false;
  // }
  protected onSave(item: any, index: number) {
    if (this.antrag === null) {
      const text = "bitte Daten vollstÃ¤ndig erfassen";
      showSnackbar({
        text,
        duration: 4000,
        color: "error",
      });
      return;
    }
    this.showLoading = true;
    const me = this;
    this.aicBew = this.data[index].aic_Bew_pool;
    const x: any = this.data[index];
    const result = this.$api
      .SaveData(
        this.header,
        0,
        this.aicBew,
        x,
        this.columns,
        this.zeitbereich,
        null,
        (res: any) => me.saved(res),
        (ex: any) => {
          this.showLoading = false;
          let errorMessage = "";
          if (ex.response) {
            errorMessage = ex.response.data.error;
          } else {
            errorMessage = ex;
          }
          this.$root.$emit("alert", {
            text: errorMessage,
            type: "error",
          });
          console.error("ERROR: " + ex);
        }
      )
      .then(() => {
        this.$emit("back", this.updateDataForAsync);
      });
  }
  private updateDataForAsync: any = null;

  protected saved(res: any) {
    this.showLoading = false;
    this.showSave = false;
    if (!res.nothingHappend) {
      // wenn nix passiert is aber weitermachen ;)
      showSnackbar({
        text: this.$globalsBegriffMemo(
          Globals.Begriff,
          "Datensatz_gespeichert",
          "Daten wurden gespeichert"
        ),
        duration: 2000,
      });
    } else {
      showSnackbar(
        this.$globalsBegriffMemo(
          Globals.Begriff,
          "Datensatz_keine_Aenderung",
          "Daten wurden nicht verÃ¤ndert, kein Speichern durchgefÃ¼hrt!"
        )
      );
    }

    let updateData = null;
    if (res && res.data) {
      updateData = res.data;
    }
    this.updateDataForAsync = updateData;
    if (updateData.data && updateData.data.length > 0) {
      const update = updateData.data[0];
      this.data[0].aic_Bew_pool = update.aic_Bew_pool;
      this.initialized = false;
      const me = this;
      this.columns.forEach((col) => {
        if (update[col.name] !== undefined) {
          me.data[col.name] = update[col.name];
          console.log("updated " + col.name + " -> " + update[col.name]);
        }
      });

      this.$nextTick(() => (this.initialized = true));
    } else if (updateData.aic) {
      this.data[0].aic_Bew_pool = updateData.aic;
      this.initialized = false;
      const me = this;
      this.$nextTick(() => (this.initialized = true));
    }
  }

  protected refreshItems() {
    if (this.data.length > 0) {
      this.datenLeer = false;
    }
    this.items = []; // ausleeren fÃ¼r refresh

    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      let header = getKZValueDisplay(row, "header", this.columns);
      const title = getKZValueDisplay(row, "titel", this.columns);
      header = title + " h " + header;

      const col = getColumnByKennung("ZE_ANTRAG_ZULAGE", null, this.columns);
      let title2 = getValue(row, col);
      let subtitel = getKZValueDisplay(row, "subtitel", this.columns);
      if (this.sperre && subtitel) {
        subtitel = subtitel + ", Anzahl: " + title2;
        title2 = "";
      }
      const subtitelHeader = getKZTitle(row, "subtitel", this.columns);

      const color = getKennungValue(row, "FARBE", this.columns);

      this.items.push({
        header,
        title,
        title2,
        subtitel,
        subtitelHeader,
        inset: true,
        aic: row.aic_Bew_pool,
        col,
        color,
      });
    }
  }
}
