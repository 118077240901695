

















































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import {
  getKennungTitle,
  getKZValueDisplay,
  getKennungValueDisplay,
  getKZValue,
} from "@/DataHelper";
// import { Globals } from "@/Globals";

@Component
export default class DialogSonstigeBelege extends EditPage {
  constructor() {
    super();
    this.query = "Web_Detail_VG_SonstigeBelege";
  }
  protected showCalc: boolean = false;
  private showDeleteMask: boolean = false;
  private kostenart: string = "";
  private showEssen: boolean = false;
  private showKredit: boolean = false;
  private showBezahlt: boolean = false;
  private showKst: boolean = false;
  private day: string = this.siteData.zeitbereich.von.toLocaleDateString();
  private name: string = "";
  private iconAmpel: string = "";
  protected initialize() {
    this.day = getKennungValueDisplay(this.data, "VALIDITY", this.columns);
    this.name = getKZValueDisplay(this.data, "name", this.columns);
    const bezahlt = getKennungTitle("RK_NOTPAYED", this.columns);
    if (bezahlt) {
      this.showBezahlt = true;
    }
    const kredit = getKennungTitle("RK_FIRMENKREDITKARTE", this.columns);
    if (kredit) {
      this.showKredit = true;
    }
    const essen = getKennungTitle("RK_ANZ_PERS", this.columns);
    if (essen) {
      this.showEssen = true;
    }
    const kostenstelle = getKennungTitle("COST_CENTRE", this.columns);
    if (kostenstelle) {
      this.showKst = true;
    }
    this.showCalc = getKZValue(this.data, "showCalc", this.columns);
    // const ampel = getKZValue(this.data, "ampel", this.columns);
    // let numberAmpel = 0;
    // if (ampel === 160) {
    //   numberAmpel = 1;
    // } else if (ampel === 120) {
    //   numberAmpel = 2;
    // } else if (ampel === 170) {
    //   numberAmpel = 1;
    // }
    // this.iconAmpel =
    //   "<img src='/" + numberAmpel + "_Ampel.png' alt='" + numberAmpel + "' />";
  }
  protected onCalculated() {
    this.siteData.reloadData = true;
    this.reloadData();
  }
}
