import { registerPlugin, WebPlugin } from "@capacitor/core";
import axios, { AxiosRequestConfig, AxiosResponse} from "axios";

export interface RequestHeaderConfig {
    [key: string]: string | number | undefined | null;
}

export interface RequestConfig {
    url: string;
    method?: "GET" | "POST" | "DELETE" | "HEAD" | "PATCH" | "PUT";
    headers?: RequestHeaderConfig;
    data?: any;
    dataType?: string;
    contentType?: string;
    responseType?: string;
}

export interface HttpHelperPluginInterface {
    request<T>(req: RequestConfig): Promise<AxiosResponse<T>>;
}

class HttpHelperPluginImplementation extends WebPlugin implements HttpHelperPluginInterface {
    public async request<T>(params: RequestConfig): Promise<AxiosResponse> {
        if (params.contentType === "application/x-www-form-urlencoded") {
            if (!params.headers) {
                params.headers = { "Content-Type": "application/x-www-form-urlencoded" };
            } else {
                params.headers["Content-Type"] = "application/x-www-form-urlencoded";
            }
            const bodyFormData = new URLSearchParams();
            for (const key of Object.keys(params.data)) {
                bodyFormData.append(key, params.data[key]);
            }
            params.data = bodyFormData;
        }

        const response = await axios(params as AxiosRequestConfig) as AxiosResponse<T>;
        return response;
    }
}

const HttpHelperPlugin = registerPlugin<HttpHelperPluginImplementation>(
    "HttpHelper",
    {
        web: () => new HttpHelperPluginImplementation(),
    }
);

export { HttpHelperPlugin };
