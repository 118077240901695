























































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
// import Constants from "@/Constants";
import { getKennungTitle, getKennungValueDisplay } from "@/DataHelper";
import { showSnackbar } from "@/UIHelper";
import { VarUebergabe } from "@/CalcHelperStatic";

@Component
export default class Grenzubertritt extends EditPage {
  private ort: string = "";
  private showFahrzeug: boolean = false;
  private onlyFahrzeug: boolean = false;
  private selectFahrzeug: boolean = false;
  private fahrtkosten: boolean = false;
  constructor() {
    super();
  }
  public mounted() {
    if (!this.siteData.ort && this.checkBegriff("Web_RK_Fahrzeugwechsel")) {
      this.showFahrzeug = true;
    }
    if (this.siteData.fahrzeug) {
      this.onlyFahrzeug = true;
    }
    this.mountedBase();
  }
  protected initialize() {
    this.selectFahrzeug = getKennungValueDisplay(
      this.data,
      "RK_TRANSPORTMITTEL",
      this.columns
    );
    this.ort = getKennungValueDisplay(this.data, "CITY", this.columns);
  }
  public save() {
    let varUebergabe: VarUebergabe[] = [];
    if (this.siteData.varUbergabe) {
      varUebergabe = this.siteData.varUbergabe;
    }
    if (this.onlyFahrzeug && !this.selectFahrzeug) {
      let meldung = this.$globalsBegriffMemo(
        this.Globals.Begriff,
        "WEB_SpeichernNichtMoeglich",
        "Speichern kann nicht durchgefÃ¼hrt werden da folgende Daten leer sind"
      );
      meldung = meldung + ": Fahrzeug";
      showSnackbar({
        text: meldung,
        color: "warning",
      });
      return;
    }
    if (this.ort) {
      this.onSave(this.data, "", "", 0, true, varUebergabe);
    } else {
      const ortText = getKennungTitle("CITY", this.columns);

      let meldung = this.$globalsBegriffMemo(
        this.Globals.Begriff,
        "WEB_SpeichernNichtMoeglich",
        "Speichern kann nicht durchgefÃ¼hrt werden da folgende Daten leer sind"
      );
      meldung = meldung + ": " + ortText;
      showSnackbar({
        text: meldung,
        color: "warning",
      });
    }
  }
  private checkValue(evt: any) {
    this.fahrtkosten = evt.value.setShow;
  }
}
