























import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "./EditComponent";

@Component
export default class GridMapper extends Vue {
  @Prop() public value!: any;
  @Prop() public column!: any;
  @Prop() public row!: any;
  @Prop() public uid!: any;
  @Prop() public lastRow: any;

  private isInitialized: boolean = false;
  private componentName: string = "- not initialized -";
  private editValue: any = null;
  private lastValue: any = null;

  public created() {
    console.log("gridmapper created");
  }

  public mounted() {
    try {
      console.log("gridmapper mounted");
      if (this.column) {
        this.componentName = this.column.title;
      }
      this.componentName = this.getEditComponent(this.column);
      this.editValue = this.getEditValue(this.row, this.column);
      this.lastValue = this.getEditValue(this.lastRow, this.column);
      this.isInitialized = true;
    } catch (err) {
      console.log(err);
    }
  }

  public setValue(val: any) {
    console.log("gridmapper setValue");
    // value mÃ¼ssen wir nicht setzen suchen wir uns extra raus...
  }

  public getValue(): any {
    console.log("gridmapper getValue");
    return this.editValue;
  }

  private valueChanged(val: any) {
    this.$emit("input", val);
    if (this.isInitialized) {
      this.editValue = val;
    }
  }

  private getEditValue(x: any, field: any) {
    if (x) {
      return x[field.name];
    }
    return x;
  }
  private getEditComponent(field: any) {
    const typeName =
      "edit" +
      field.type.substring(0, 1).toUpperCase() +
      field.type.substring(1); // richtig formatieren ==> der Compontenname wird hier zusammengesetzt!
    console.log(typeName);
    return typeName;
  }

  public validate(): boolean {
    const comp = this.$refs.comp as EditComponent;
    try {
      const x = comp.validate();
      return x;
    } catch {
      return true;
    }
  }
}
