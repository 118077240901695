var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"padding":"0"},attrs:{"color":"grey lighten-4","flat":"","tile":""}},[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title',[_vm._t("title",function(){return [_vm._v("persÃ¶nliches Dashboard")]})],2),_vm._t("titlemenu-end"),(_vm.editMode)?_c('v-btn',{attrs:{"icon":"","title":"Zeile am Ende hinzufÃ¼gen"},on:{"click":function($event){return _vm.addRow()}}},[_c('v-icon',[_vm._v("add")])],1):_vm._e(),(!_vm.editMode && !_vm.readonly)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleEditMode()}}},[_c('v-icon',[_vm._v("dashboard_customize")])],1):_vm._e(),(_vm.editMode)?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.toggleEditMode()}}},[_c('v-icon',[_vm._v("save")])],1):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","rounded":"0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1)]}}])},[_vm._t("dotMenu",function(){return [_vm._v("Menuinhalt leer")]})],2)],2),_c('v-card-text',{staticStyle:{"padding":"0"}},[_c('v-container',{staticStyle:{"padding":"0"},attrs:{"fluid":""}},_vm._l((_vm.rows),function(row,rix){return _c('v-row',{key:'r' + rix,attrs:{"id":'r' + row.id,"no-gutters":""}},[_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"cols":"12","no-gutters":""}},[(_vm.editMode)?_c('v-system-bar',{attrs:{"color":"primary","dark":""}},[_c('span',[_vm._v(_vm._s(_vm.$begriffBezeichnung("Web_Zeile")))]),_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-table-row")]),_c('span',[_vm._v(" "+_vm._s(rix)+": ")]),_c('v-btn',{staticClass:"ml-5",attrs:{"icon":"","x-small":"","title":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'Web_ZeileEntfernen',
                  'Zeile entfernen'
                )},on:{"click":function($event){return _vm.removeRow(row)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"ml-5",attrs:{"icon":"","title":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'Web_ZeileDavorHinzufuegen',
                  'Zeile davor einfÃ¼gen'
                )},on:{"click":function($event){return _vm.insertRow(rix)}}},[_c('v-icon',[_vm._v("mdi-table-row-plus-before")])],1),_c('v-btn',{attrs:{"icon":"","title":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'Web_ZeileDanachEinfuegen',
                  'Zeile danach einfÃ¼gen'
                )},on:{"click":function($event){return _vm.insertRow(rix + 1)}}},[_c('v-icon',[_vm._v("mdi-table-row-plus-after")])],1),_c('span',{staticClass:"ml-5"},[_vm._v(" "+_vm._s(_vm.$begriffBezeichnung("Web_Spalte")))]),_c('v-icon',[_vm._v("mdi-table-column")]),_c('v-btn',{attrs:{"icon":"","title":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'Web_SpalteHinzufuegen',
                  'Spalte hinzufÃ¼gen'
                )},on:{"click":function($event){return _vm.addGroup(row)}}},[_c('v-icon',[_vm._v("add")])],1),_vm._t("groupEditTitle")],2):_vm._e()],1),_vm._l((row.groups),function(grp,gix){return _c('v-col',{key:'group' + gix,attrs:{"no-gutters":"","cols":_vm.getGroupCols(row, grp)}},[_c('draggable',{staticClass:"list-group",attrs:{"list":grp.items,"id":'grp' + grp.id,"draggable":".item","group":"a","disabled":!_vm.editMode}},[(_vm.editMode)?_c('div',{staticClass:"btn-group list-group-item",attrs:{"slot":"header","role":"group","aria-label":"Basic example"},slot:"header"},[_c('v-system-bar',{attrs:{"color":"blue","dark":""}},[_vm._v(" "+_vm._s(_vm.$begriffBezeichnung("Web_Aufgaben"))+" "+_vm._s(gix)+" - "+_vm._s(rix)+": "),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","x-small":"","title":_vm.$globalsBezeichnung(
                      _vm.Globals.Begriff,
                      'Web_AufgabeEntfernen',
                      'Aufgabe entfernen'
                    )},on:{"click":function($event){return _vm.removeGroup(row, grp)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"ml-5",attrs:{"icon":"","x-small":"","title":_vm.$globalsBezeichnung(
                      _vm.Globals.Begriff,
                      'Web_neuesElement',
                      'neues Element'
                    )},on:{"click":function($event){return _vm.addItem(grp)}}},[_c('v-icon',[_vm._v("edit")])],1),_vm._t("itemEditTitle")],2),_c('span')],1):_vm._e(),_vm._l((grp.items),function(item,iix){return _c('v-card',{key:'item-' + item.id,staticClass:"item",staticStyle:{"padding":"0"},attrs:{"flat":"","id":'item-' + item.id}},[_c('v-card-title',[(!item.hideTitle)?_vm._t("itemTitle",function(){return [_vm._v(" Item "+_vm._s(item.id)+" ( "+_vm._s(rix)+" - "+_vm._s(gix)+" - "+_vm._s(iix)+") ")]},{"item":item}):_vm._e()],2),_c('v-card-text',{staticStyle:{"padding":"0"}},[_vm._t("itemContent",function(){return [_vm._v("---")]},{"item":item})],2)],1)})],2)],1)})],2)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }