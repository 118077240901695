



































import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import AUApi from "@/api";
import { getKZValue } from "@/DataHelper";
import Constants from "@/Constants";
import {
  AufgabeStatus,
  AufgabeStatusZuordnung,
  getAufgabeDefRow
} from "../ChartTypesAndFunctions";
import { showSnackbar } from "@/UIHelper";
import ChartBase from "./ChartBase";

@Component
export default class DashboardKalender extends ChartBase {
  @Prop({ default: 150 }) public height?: number;
  // @Prop({default: ()=>  {} }) public status?: AufgabeStatus;

  private initialized: boolean = false;
  private editorComponent: string = "RkMitarbeiterTemplateDialogReisen"; // TODO weghauen - is nur annahme
  private formular: string = "Web_Dashboard_MA"; // TODO weghauen - is nur annahme
  @Prop() protected druckItems!: any;

  public mounted() {
    if (!this.params.aicStamm || !this.aufgabenDefinition) {
      console.log("no query given for ui");
      return;
    }
    this.editorComponent = this.getKZAufgabeDefiniton("editorComponent", null);
    this.formular = this.getKZAufgabeDefiniton("formular", null);
    this.druckItems = this.params.zuordnungen?.filter(z => z.gruppe === "Druck");
    // const row = this.aufgabenDefinition.data.find(
    //   (r: any) => r.aic_Stamm === this.params.aicStamm
    // );
    // const columns = this.aufgabenDefinition.columns;
    // if (row) {
    //   this.editorComponent = getKZValue(row, "editorComponent", columns);
    //   this.formular = getKZValue(row, "formular", columns);
    // } else {
    //   console.log("keine Aufgabedefinion fÃ¼r status", this.status);
    // }

    this.initialized = true;
  }
  protected QvAICsChanged(): void {
    // nothing to do here
  }
}
