





















































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Globals } from "@/Globals";
import AUApi from "@/api";
import { unionResults, getDisplay } from "@/DataHelper";
import { AxiosResponse } from "axios";
import { initial, max } from "lodash";
import { DialogParameter, showDialog } from "@/UIHelper";
import BaseFilter from "./common/BaseFilter.vue";
import Constants from "@/Constants";
import { onShowSite } from "@/NavigationHelper";

declare interface HsAbfragen {
  aicAbf: number;
  aicEig: number;
  kennung: string;
  aicStt: number;
  aicSttVon: number;
  art: string;
  standardFormular: Site;
}

declare interface Site {
  aic: number;
  alias?: string;
  allgemein: boolean;
  bezeichnung: string;
  defBezeichnung: string;
  kennung: string;
  kennungG: string;
  stt: number;
  zeitraum: string;
  fullscreen?: boolean;
  admin?: boolean;
  auswahlQuery?: string;
  stammQuery?: string;
  neuDialog?: string;
}

@Component
export default class TreeGridView extends Vue {
  @Prop() public data!: QueryResponse;

  @Prop() public query!: string;
  @Prop() public editZeitbereich!: APIZeitbereich;
  @Prop() public selectable?: boolean;
  @Prop({ default: () => [] }) protected hsAbfragen!: HsAbfragen[];
  @Prop({ default: () => [] }) protected hsFormulare!: HsAbfragen[];
  @Prop() protected printItems!: PrintItems[];
  private hsSubAbfragen: HsAbfragen[] = [];
  @Prop() protected hsAic!: number;
  @Prop() public showSelectAustritt?: boolean;

  @Prop({ default: false }) public openAll!: boolean;

  public items: TreeItem[] = [];
  public filteredItems: TreeItem[] = [];
  public initialized = false;
  public showLoading = false;

  public filteredData: FilteredQueryResponse | null = null;
  public columns: DataColumn[] = [];

  private showVorAustritt: boolean = false;
  private isMobile: boolean = false;

  public getDisplay(row: [], c: DataColumn) {
    return getDisplay(row, c);
  }

  public openCloseAll(open: boolean = true) {
    (this.$refs.treeviewControl as any).updateAll(open);
  }

  public async mounted() {
    if ((Constants.isMobile && this.$isPhone()) || window.innerWidth < 600) {
      this.isMobile = true;
    }
    this.refreshData();
  }
  @Watch("hsAic")
  @Watch("hsAbfragen")
  @Watch("hsFormulare")
  public async refreshData() {
    this.showLoading = true;
    const data = await this.$api.getHSStruktur(
      (x: any) => x,
      this.hsAic,
      this.editZeitbereich,
      false
    );
    console.log("BaseTreeView data loaded");
    // Fehlerbehandlung
    let error = data?.data as ErrorResponse;
    if (!data || error.error) {
      error = error ?? "no data";
      this.$api.onfail(error);
      console.log(error);
      alert("ACHTUNG: " + error);
      this.$emit("back");
      return;
    }
    const items = data.data as TreeItem[];
    this.columns = [];
    try {
      items.forEach((row) => {
        const stt = row.stt;
        // aus dem Stammtyp kann farbe und Icon ausgelesen werden
        const stamm = this.$globalsAic(Globals.Stt, stt);
        // const frame = this.$globalsFrame("Begriff", false, stamm, true);
        if (stamm) {
          row.icon = stamm.icon;
          row.color = stamm.farbe;
        }
      });
    } catch (e) {
      console.log(e);
    }
    try {
      if (this.hsAbfragen) {
        this.hsSubAbfragen = this.hsAbfragen;
        await this.loadSubQuerys();
        this.mapSubDataToItems(items, this.hsSubData!);
        this.filterItemsRecursive(items, [], "");
      } else {
        this.initialized = true;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.items = items;
      this.showLoading = false;
      this.initialized = true;
    }
  }

  private filterItemsRecursive(
    items: TreeItem[],
    rows: any[],
    searchText?: string
  ): TreeItem[] {
    const treeItems: TreeItem[] = [];

    items.forEach((i) => {
      const match =
        (i.row && rows.find((r) => r.aic_Stamm === i.row.aic_Stamm)) ||
        (searchText && i.bezeichnung.toLowerCase().indexOf(searchText) >= 0) ||
        (!searchText && !i.row);

      let children: TreeItem[] = [];
      if (i.children && i.children.length > 0) {
        children = this.filterItemsRecursive(i.children, rows, searchText);
      }
      if (match || children.length > 0) {
        if (this.showVorAustritt || (!this.showVorAustritt && !i.weg)) {
          treeItems.push({
            aic: i.aic,
            color: i.color,
            header: i.header,
            depth: i.depth,
            icon: i.icon,
            row: i.row,
            stt: i.stt,
            sttBez: i.sttBez,
            subFrame: i.subFrame,
            subtitel: i.subtitel,
            title: i.title,
            bezeichnung: i.bezeichnung,
            children, // gefilterete children hinzufÃ¼gen
            weg: i.weg, // vor. ausgetretene MA - sollte farblich markiert werden!
          });
        }
      }
    });
    return treeItems;
  }

  public filteredDataChanged(data: FilteredQueryResponse) {
    if (data.filterActive) {
      this.filteredItems = this.filterItemsRecursive(
        this.items,
        data.data,
        data.searchText?.toLocaleLowerCase()
      );
    } else {
      this.filteredItems = this.items;
    }

    this.refreshData();
  }

  private maxDepth = 0;
  public mapSubDataToItems(items: TreeItem[], hsSubData: QueryResponse, depth = 0) {
    if (this.maxDepth < depth) {
      this.maxDepth = depth;
    }
    items.forEach((item) => {
      // const stt = item.stt;
      const subdata = hsSubData;
      if (subdata) {
        item.row = subdata.data.find((row) => row.aic_Stamm === item.aic);
        item.depth = depth;
      }
      if (item.children) {
        this.mapSubDataToItems(item.children, hsSubData, depth + 1);
      }
    });
  }

  private async loadSubQuerys() {
    try {
      if (this.hsSubAbfragen.length > 0) {
        const promises = this.hsSubAbfragen.map(async (row) => {
          if (row && row.kennung) {
            const query = row.kennung;
            const response = await AUApi.Current.getQuery(query, undefined);
            return this.dataLoadedSubAbf(response, row.aicStt);
          }
        });
        let datas = (await Promise.all(promises)) as QueryResponse[];
        datas = datas.filter((d) => (d ? true : false));
        if (datas.length === 1) {
          this.hsSubData = datas[0];
        } else {
          this.hsSubData = unionResults(datas as QueryResponse[]);
        }
        this.columns = (this.hsSubData?.columns ?? []).filter((c) => !c.meta.invisible);
      }
    } catch (error) {
      console.error(error);
      alert("ACHTUNG FEHLER: " + error);
      this.$emit("back");
    } finally {
      // alles geladen?
    }
  }

  public hsSubData?: QueryResponse;
  public getFilterData() {
    return this.hsSubData;
  }
  private async dataLoadedSubAbf(data: AxiosResponse<QueryResponse>, stt: number) {
    try {
      console.log("SubAbfragen loaded");
      // Fehlerbehandlung
      if (data.data.error !== undefined) {
        this.$api.onfail(data.data.error);
        throw new Error(data.data.error);
      }
    } catch (e) {
      console.error(e);
      return;
    }
    return data.data;
  }
  public depthIntent = 20;
  public firstColumns() {
    return Math.max(1, 12 - (this.columns.length + 2));
  }

  private openFormular(item: TreeItem) {
    this.openFrame(item.stt, item.aic);
  }

  protected openFrame(stt: number, aicStamm: number) {
    const gridData = this.hsFormulare.filter((x: any) => x.aicStt === stt);
    if (!gridData) {
      return;
    } else {
      const site = gridData[0]?.standardFormular;
      let siteData = "EditStamm";
      let begr;
      let title = "Stammdaten";
      let auswahlQuery = "";
      let neuDialog = "";
      let stammQuery = "";
      if (site) {
        if (site.stammQuery) {
          siteData = "BaseStammMaske";
          stammQuery = site.stammQuery;
          auswahlQuery = site.auswahlQuery ?? "";
          neuDialog = site.neuDialog ?? "";
        } else {
          siteData = site.alias ?? site.kennung;
        }
        title = site.bezeichnung ?? "Stammdaten";
      } else {
        begr = this.$globalsStt(Globals.Begriff, stt, "", true);
      }
      const navData = {
        aic: aicStamm,
        showClose: true,
        site,
        onReloadData: false,
        query: begr?.kennung,
        stammQuery,
        auswahlQuery,
        neuDialog,
      };

      onShowSite({
        title,
        site: siteData,
        data: navData,
        callOnLeave: () => {
          if (navData.onReloadData === true) {
            setTimeout(() => this.$emit("reload"));
          }
          return true;
        },
      });
    }
  }
  private getRowStyle(item: any) {
    if (item.weg) {
      return { color: "red" };
    } else {
      return { flex: "nowrap" };
    }
  }
  private setIsWeg(x: boolean) {
    this.initialized = false;
    if (!x) {
      this.filteredItems = this.filterItemsRecursive(this.items, [], "");
      this.$nextTick(() => {
        this.initialized = true;
      });
    } else {
      this.filteredItems = this.items;
      this.initialized = true;
    }
  }
}
