export // nothing to export here
{ };

declare global {
  interface Date {
    getDay(): number;
    getDate(): number;
    getMonth(): number;
    addDays(days: number): Date;
    daysInMonth(month?: number | Date): number;
    addMonths(months: number, stickToMonthEnd: boolean): Date;
    addHours(h: number): Date;
    addMinutes(min: number): Date;

    getWeekNumber(): number;
    toISOStringWithTZ(): string;
    toISOStringWithTZWithMs(): string;
    toISOStringWithTZGMT(): string;
    getFullYear(): number;
    getMinutes(): number;
    getSeconds(): number;
    getHours(): number;

    diffInDays(bis: Date): number;
    diffInMonths(bis: Date): number;
    diffInMinutes(bis: Date): number;
    firstOfMonth(): Date;
    lastInMonth(): Date;
    firstOfWeek(): Date;
    asLocalDateString(): string;

    toISOStringWithDifferentTimeZone(newTimeZoneOffsetInMin: number): string;
    getISOTimeZoneOffsetInMin(dateInISO: string): number;
  }

  interface DateConstructor {
    daysInMonth: (month: number | Date) => number;
    doesRangesIntersect: (
      von1: Date,
      bis1: Date,
      von2: Date,
      bis2: Date
    ) => boolean;

    isISOStringLocal(dateInISO: string): boolean;
    getISOTimeZoneOffsetInMin(dateInISO: string): number;
  }
}


// tslint:disable-next-line: only-arrow-functions
Date.isISOStringLocal = function(dateInISO: string): boolean {
  const offlocal = new Date().getTimezoneOffset() * -1;
  const offIso = Date.getISOTimeZoneOffsetInMin(dateInISO);
  return offlocal === offIso;
};

// tslint:disable-next-line: only-arrow-functions
Date.getISOTimeZoneOffsetInMin = function(dateInISO: string): number {
  let offset = 0;
  if (dateInISO.endsWith("Z")) { return 0; }
  const ixT = dateInISO.indexOf("T");
  if (ixT <= 0) { throw Error("not a valid datetime string"); }
  const timeStr = dateInISO.substring(ixT + 1);

  let ix = timeStr.indexOf("+");
  if (ix < 0) {
    ix = timeStr.lastIndexOf("-");
  }
  if (ix > 0) {
    // wir splitten mit dem vorzeichen damit haben wir via stunde automatisch + oder - richtig drin
    const zz = timeStr.substring(ix);
    const parts = zz.split(':');

    if (parts.length > 0) {
      offset += parseInt(parts[0], 10) * 60;
    }
    if (parts.length > 1) { offset += parseInt(parts[1], 10); }
  }

  return offset;
};

Date.prototype.addDays = function(days: number) {
  const date = new Date(this.valueOf());
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

// tslint:disable-next-line: only-arrow-functions
Date.prototype.diffInDays = function(bis: Date) {
  const von = new Date(this.valueOf());
  const diff =
    Math.round((bis.valueOf() - von.valueOf()) / (1000 * 60 * 60 * 24)) + 1;
  return diff;
};


Date.prototype.diffInMinutes = function(bis: Date) {
  const von = new Date(this.valueOf());
  const diff =
    Math.round((bis.valueOf() - von.valueOf()) / (1000 * 60)) + 1;
  return diff - 1;
};

// tslint:disable-next-line: only-arrow-functions
Date.prototype.diffInMonths = function(d2: Date) {
  const d1 = new Date(this.valueOf());
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};



// tslint:disable-next-line: only-arrow-functions
Date.prototype.firstOfMonth = function() {
  const date = new Date(this.valueOf());
  const month = date.getMonth();
  const year = date.getFullYear();

  return new Date(year, month, 1);
};
// tslint:disable-next-line: only-arrow-functions
Date.prototype.firstOfWeek = function() {
  const first = new Date();
  const day = first.getDay();
  const diff = first.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(first.setDate(diff));

};
// tslint:disable-next-line: only-arrow-functions
Date.prototype.daysInMonth = function(anyDateInMonth?: number | Date) {
  if (typeof anyDateInMonth === "number") {
    anyDateInMonth = new Date(2000, anyDateInMonth, 1);
  }
  if (!anyDateInMonth) {
    anyDateInMonth = new Date(this.valueOf());
  }
  return anyDateInMonth.lastInMonth().getDate();
};

Date.prototype.lastInMonth = function() {
  const anyDateInMonth = new Date(this.valueOf());
  return new Date(
    anyDateInMonth.getFullYear(),
    anyDateInMonth.getMonth() + 1,
    0
  );
};

Date.prototype.addHours = function(h) {
  const date = new Date(this);
  date.setTime(date.getTime() + h * 60 * 60 * 1000);
  return date;
};

Date.prototype.addMinutes = function(min) {
  const date = new Date(this);
  date.setTime(date.getTime() + min * 60 * 1000);
  return date;
};

Date.daysInMonth = Date.prototype.daysInMonth;

// tslint:disable-next-line: only-arrow-functions
Date.doesRangesIntersect = (
  eventVon: Date,
  eventBis: Date,
  von: Date,
  bis: Date
): boolean => {
  const vonInRange = eventVon >= von && eventVon < bis;
  const bisInRange = eventBis > von && eventBis <= bis;
  const overlapping = eventVon < von && eventBis > bis;
  if (vonInRange || bisInRange || overlapping) {
    return true;
  }
  return false;
};

Date.prototype.addMonths = function(months: number, stickToMonthEnd: boolean) {
  const date = new Date(this);
  let day = date.getDate();
  let month = date.getMonth() + months;
  let year = date.getFullYear();

  if (month > 12) {
    // Jahr korrigieren wenn Ã¼ber Jahresende hinausgeschossen
    const yearsToAdd = month / 12;
    month = month % 12;
    year = Math.floor(year + yearsToAdd);
  }

  if (month < 0) {
    const yearsToSubstract = Math.abs(month / 12);
    month = 12 - Math.abs(month % 12);
    year = Math.floor(year - yearsToSubstract);
  }

  // wir bleiben am Monatsletzten picken ;)
  if (stickToMonthEnd === true && day === new Date().daysInMonth(date)) {
    day = 0; // Monat is 1-basierend in JS daher ist Tag 0 eines Monats der letzte Tag des Vormonats ;)
    months++;
  } else if (day > Date.daysInMonth(date)) {
    day = Date.daysInMonth(new Date(year, month, 1));
  }

  const result = new Date(year, month, day);

  return result;
};

// https://die-aktuelle-kalenderwoche.de/kalenderwochen-in-javascript
Date.prototype.getWeekNumber = function() {
  // Copy date so don't modify original
  const date = new Date(this.valueOf());
  // In JavaScript the Sunday has value 0 as return value of getDay() function.
  // So we have to order them first ascending from Monday to Sunday
  // Monday: ((1+6) % 7) = 0
  // Tuesday	((2+6) % 7) = 1
  // Wednesday:	((3+6) % 7) = 2
  // Thursday:	((4+6) % 7) = 3
  // Friday: ((5+6) % 7) = 4
  // Saturday:	((6+6) % 7) = 5
  // Sunday: ((0+6) % 7) = 6
  // (3 - result) is necessary to get the Thursday of the current week.
  // If we want to have Tuesday it would be (1-result)
  const currentThursday = new Date(
    date.getTime() + (3 - ((date.getDay() + 6) % 7)) * 86400000
  );

  // At the beginnig or end of a year the thursday could be in another year.
  const yearOfThursday = currentThursday.getFullYear();

  // Get first Thursday of the year
  const firstThursday = new Date(
    new Date(yearOfThursday, 0, 4).getTime() +
    (3 - ((new Date(yearOfThursday, 0, 4).getDay() + 6) % 7)) * 86400000
  );

  // +1	we start with week number 1
  // +0.5 an easy and dirty way to round result (in combinationen with Math.floor)
  const weekNumber = Math.floor(
    1 +
    0.5 +
    (currentThursday.getTime() - firstThursday.getTime()) / 86400000 / 7
  );
  return weekNumber;
};

Date.prototype.toISOStringWithDifferentTimeZone = function(newTimeZoneOffsetInMin: number): string {
  const dif = newTimeZoneOffsetInMin >= 0 ? "+" : "-";
  const pad = (num: number) => {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? "0" : "") + norm;
  };
  return (
    this.getFullYear() +
    "-" +
    pad(this.getMonth() + 1) +
    "-" +
    pad(this.getDate()) +
    "T" +
    pad(this.getHours()) +
    ":" +
    pad(this.getMinutes()) +
    ":" +
    pad(this.getSeconds()) +
    dif +
    pad(newTimeZoneOffsetInMin / 60) +
    ":" +
    pad(newTimeZoneOffsetInMin % 60)
  );
};

Date.prototype.toISOStringWithTZ = function() {
  const tzo = -this.getTimezoneOffset();
  // das MINUS Darf NICHT entfernt werden - sonst stimmen die TimePicker nicht mehr!
  return this.toISOStringWithDifferentTimeZone(tzo);
};

Date.prototype.asLocalDateString = function() {
  const tzo = -this.getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";
  const pad = (num: number) => {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? "0" : "") + norm;
  };
  return (
    this.getFullYear() +
    "-" +
    pad(this.getMonth() + 1) +
    "-" +
    pad(this.getDate()) +
    "T" +
    pad(0) +
    ":" +
    pad(0) +
    ":" +
    pad(0) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
};

Date.prototype.toISOStringWithTZWithMs = function() {
  const tzo = -this.getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";
  const pad = (num: number) => {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? "0" : "") + norm;
  };
  return (
    this.getFullYear() +
    "-" +
    pad(this.getMonth() + 1) +
    "-" +
    pad(this.getDate()) +
    "T" +
    pad(this.getHours()) +
    ":" +
    pad(this.getMinutes()) +
    ":" +
    pad(this.getSeconds()) +
    "." +
    pad(this.getMilliseconds()) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
};
Date.prototype.toISOStringWithTZGMT = function() {
  const tzo = -this.getTimezoneOffset();
  // das MINUS Darf NICHT entfernt werden - sonst stimmen die TimePicker nicht mehr!
  const dif = tzo >= 0 ? "+" : "-";
  const pad = (num: number) => {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? "0" : "") + norm;
  };
  let rueckgabe = this.getFullYear() +
    "-" +
    pad(this.getMonth() + 1) +
    "-" +
    pad(this.getDate()) +
    "T" +
    pad(this.getHours()) +
    ":" +
    pad(this.getMinutes()) +
    ":" +
    pad(this.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60);
  rueckgabe = rueckgabe.substr(0, 10)
    .concat("T00:00:00");
  return rueckgabe;
};


