




























































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { showSnackbar } from "@/UIHelper";
import BaseEditor from "@/components/BaseEditor.vue";
import { User } from "@/components/EditPage";
import EditPage from "@/components/EditPage";
import { getKennungValueDisplay } from "@/DataHelper";
@Component
export default class BenutzerMitarbeiter extends EditPage {
  constructor() {
    super();
  }
  @Prop() protected stammBez!: string;
  @Prop() protected stammKen!: string;
  @Prop() protected stammsatz!: number;
  protected reloadUser: boolean = false;
  private selectedUser: any;
  protected user: User[] = [];
  private bezeichnung: string = "";
  private kennung: string = "";
  private avatar: string = "";
  private text: string = "Benutzer: ";
  private initalized: boolean = false;

  private showDelete: boolean = false;
  private showSave: boolean = false;
  public mounted() {
    this.reloadData();
  }
  protected async reloadData() {
    const userData = await this.$api.getUser(this.stammsatz, 0);
    const allUser = userData.data.data;
    this.user = allUser.filter((e: any) => e.test !== true);
    if (this.user.length > 0) {
      this.dataLoaded(userData);
    } else {
      // daten fÃ¼r vorlage holen
      // this.text = "Vorlage wÃ¤hlen: ";
      const userVorlage = await this.$api.getUser(0, 1);
      this.user = userVorlage.data.data;
      if (userVorlage.data.data) {
        this.showKopie = true;
        this.dataLoaded(userVorlage);
      }
    }
    this.data = this.user[0];
  }

  public initialize() {
    if (this.showKopie) {
      this.kennung = this.stammKen;
      this.bezeichnung = this.stammBez;
      this.text = "Vorlage wÃ¤hlen: ";
    }
    if ((this.selectedUser?.length === 0 || !this.selectedUser) && this.initialized) {
      this.selectedUser = this.user[0];
      this.$forceUpdate();
    }
    if (!this.showKopie) {
      const avatarData = getKennungValueDisplay(this.user, "Bild", this.columns);
      if (avatarData) {
        if (avatarData.data) {
          this.avatar = avatarData.data;
        } else {
          this.loadImage(avatarData);
          this.avatar = this.avatarIcon;
        }
      }
    }

    this.initalized = true;
    this.initialized = true;
  }
  private async deleteUser() {
    try {
      this.$api.deleteUser(this.selectedUser.aic).then((res: any) => {
        const meldung = res.data.info;
        if (meldung) {
          showSnackbar({
            text: meldung,
            color: "info",
          });
        }
      });
      const userData = await this.$api.getUser(0, 0);
      if (userData) {
        const allUser = userData.data.data;
        this.data = allUser.filter((e: any) => e.test !== true);
        this.$forceUpdate();
      }

      this.initUser(false);
    } catch {
      // Meldung
    }
  }
  private initUser(kopie: boolean) {
    if (!kopie) {
      this.selectedUser = [];
    }
  }
  private async neuAnlageUser(kopie: boolean, change: boolean) {
    let aic = 0;
    let copyAic = 0;
    if (kopie) {
      copyAic = this.selectedUser.aic;
    } else {
      aic = this.selectedUser.aic;
    }
    if (change) {
      this.kennung = this.selectedUser.kennung;
    }
    this.$api
      .newUser(
        aic,
        this.kennung,
        this.bezeichnung,
        2,
        "",
        "",
        this.data.sprache.aic,
        this.data.land.aic,
        this.stammsatz,
        0,
        copyAic
      )
      .then((res: any) => {
        const changed = res.data.aic;
        const fehler = res.data.info;
        if (fehler) {
          showSnackbar({
            text: fehler,
            color: "error",
          });
        } else if (changed) {
          showSnackbar({
            text: this.$globalsBegriffMemo(
              this.Globals.Begriff,
              "Benutzer_anlegen",
              "Benutzer wurde erfolgreich angelegt/geÃ¤ndert!"
            ),
            color: "info",
          });
          this.showKopie = false;
          this.text = "Benutzer";
          this.initialized = false;
          this.selectedUser = [];
          this.showSave = false;
          this.reloadData();
        }
      });
  }

  protected selectedUserChanged(args: User) {
    this.initialized = false;
    const user: any = this.user.find((m: any) => m.bezeichnung === args);
    this.selectedUser = user;
    this.data = user;
    if (this.showKopie) {
      this.kennung = this.stammKennung;
      this.bezeichnung = this.stammBez;
    }
    setTimeout(() => this.initialize(), 100);
  }
  private filterFunction(item: any, queryText: string, itemText: string): boolean {
    queryText = queryText.toLowerCase();
    if (item.headerHtml) {
      return true;
    }
    if (
      (itemText && itemText.toLowerCase && itemText.toLowerCase() + "").startsWith(
        queryText
      )
    ) {
      return true;
    }
    if (item.rowData) {
      const x = item.rowData.find(
        (t: string) => t && t.toLowerCase && (t.toLowerCase() + "").startsWith(queryText)
      );
      if (x) {
        return true;
      }
    }
    return false;
  }
  private resetPassword() {
    this.$api.resetPassword(this.selectedUser.kennung).then((res: any) => {
      const fehler = res.data.info;
      if (fehler) {
        showSnackbar({
          text: fehler,
          color: "error",
        });
      } else {
        showSnackbar({
          text: this.$globalsBegriffMemo(
            this.Globals.Begriff,
            "Web_PW_zurueckgesetzt",
            "Passwort wurde erfolgreich zurÃ¼ckgesetzt!"
          ),
          color: "info",
        });
      }
    });
  }
}
