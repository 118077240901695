import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { fixKennungForProperty } from "@/DataHelper";
import IQueryDataProvider from "./IQueryDataProvider";
import { scrollIntoView, showDialog } from "@/UIHelper";
import { formatDate } from "@/editor/CalenderHelper";

@Component
export default class EditComponent extends Vue {
  @Prop() public query?: string;
  @Prop() private initValue?: any;
  @Prop({ default: "" }) public initStichtag?: string; // info Ã¼ber Ãnderungsstichtag
  @Prop({ default: "" }) public initPassValue?: string; // Wert aus Ãbergeordneten Ebene
  @Prop() private aic!: number;
  @Prop({ default: null }) public lastValue: any;
  @Prop() public queryColumn: any;
  @Prop() public queryZeitbereich!: APIZeitbereich;
  @Prop({ default: false }) public openFromDialog!: boolean;

  @Prop({default: false}) public disableValueChangeTracker!: boolean;
  public column: any;
  private valueToInit: any;
  public imAEditComponent = true;
  protected scrollMeIntoView(e: Event) {
    scrollIntoView(e);
  }

  // property fÃ¼r html
  @Prop({ default: false }) public isRequired!: boolean;
  @Prop({ default: false }) public readonly!: boolean;
  // inernal member fÃ¼r rendering und binding
  public required: boolean = this.isRequired;
  public requiredRender() { return this.required; }


  @Prop({ default: true }) public show!: boolean;
  public showMutable!: boolean;
  // Property disabled zum initialisieren und wird im html verwendet...
  @Prop({ default: false }) public disabled!: boolean;
  // member variable welche intern zum rendern und modifizieren verwendet wird...
  public enabled: boolean = !this.disabled;

  @Prop({ default: "" }) public title!: string;
  protected description: string = "";
  @Prop({ default: null }) public kennung!: string;
  @Prop({ default: null }) public kennungKz!: string;
  @Prop() public stammtyp!: number;
  public showStichtag: boolean = false;
  public hintText: string = "";
  private style: string = "";
  private success: boolean = false;

  // lokale aktuelle Daten und Konfig
  protected data: any = this.initValue;
  protected backupData: any = this.data;

  // Tooltip
  protected tooltip: string = "";
  protected showtooltip: boolean = false;
  protected tipposition: any = "TopCenter";

  // validation
  protected requiredMessage: string = this.$globalsBezeichnung(
    this.Globals.Begriff,
    "Web_Pflichtfeld",
    "Pflichtfeld"
  );
  protected errorText: string = "";

  constructor() {
    super();
    this.showMutable = true;
  }
  // protected sperrBegriff = this.$globalsKennung(this.Globals.Begriff, this.query);
  private getColumnByKennung(
    columns: any[],
    kennung: string,
    kz: string | null = null
  ) {
    kennung = fixKennungForProperty(kennung);
    if (!kennung || !columns) {
      return null;
    }
    kennung = kennung.toLowerCase();
    const col = columns.find(
      c =>
        c.kennung &&
        fixKennungForProperty(c.kennung).toLowerCase() === kennung &&
        (kz === null || c.meta.kz === kz)
    );
    return col;
  }

  public mounted() {
    this.mountedBase();
    this.$emit("mountFinished");
  }

  protected mountedBase() {
    try {
      this.column = this.queryColumn;
      this.description = this.title;
      this.showMutable = this.show;
      this.required = this.isRequired;
      if (!this.column && this.kennung) {
        let parent: Vue = this.$parent;
        let parentComponent: IQueryDataProvider = (this
          .$parent as unknown) as IQueryDataProvider;
        do {
          if (!parent) {
            break;
          }
          if (parentComponent.columns && parentComponent.columns) {
            if (parentComponent.columns.length === 0) {
              console.error("no columns loaded in parent component...");
              return;
            }
            const column = this.getColumnByKennung(
              parentComponent.columns,
              this.kennung,
              this.kennungKz
            );

            if (column) {
              if (parentComponent.data) {
                this.data = parentComponent.data[column.name];
                this.valueToInit = parentComponent.data[column.name];
              }
              this.column = column;
              this.description = column.title;
              parentComponent.registerEditComponent(this, column);
              break;
            } else {
              console.error(
                "column " + this.kennung + " not found in parent component!!!"
              );
              return;
            }
          }

          parent = parent.$parent;
          parentComponent = (parent as unknown) as IQueryDataProvider;
        } while (true);
      }

      if (this.column) {
        this.initTooltip();
      } else {
        // wenn keine Column da - dann prÃ¼fen ob es ein Query gibt!
        if (this.query) {
          return;
        } else {
          console.log(" no column given...");
          return;
        }
      }
      if (
        this.column.meta.invisible != null
        // Spalte ist auf unsichtbar!
      ) {
        this.showMutable = false;
      }
      this.enabled = true;
      // this.disabled = false; // das darf ich nicht setzen - sonst kann man es nicht mehr steuern..
      if (
        this.column.meta.readOnly != null || this.readonly
        // neuArt KEIN bedeutet nur das nicht vorgefÃ¼llt werden darf!
        // this.column.meta.neuArt === "kein"
      ) {
        this.enabled = false;
        // this.disabled = true;
      }
      if (this.column.meta.required != null) {
        this.required = true;
      }
      if (this.column.meta.stichtag) {
        this.showStichtag = true;
      }
      this.$emit("addValidation", {
        field: this,
        isValid: this.validate
      });
    } catch (e) {
      console.error("error mounting component: " + typeof this, e);
    }
  }

  public setBackupData() {
    this.backupData = this.data;
  }

  public updated() {
    this.validate();
    this.setBackupData();
  }
  public getInitValue(): any {
    return this.getInitValueBase();
  }

  protected getDefault() {
    return "";
  }

  protected getInitValueBase(): any {
    let initValue = this.initValue;
    if (this.valueToInit && !initValue) {
      initValue = this.valueToInit;
    }
    if (this.column && this.column.meta && !initValue) {
      if (this.column.meta.keinVorfuellen) {
        return null;
      } // immer leer machen...
      if (this.column.meta.required) {
        initValue = this.getDefault();
        this.errorText = this.requiredMessage;
        this.valueChanged(initValue, true);
      }

      if (this.column.meta.neuArt) {
        if (this.column.meta.neuArt === "Last") {
          let lastValue = this.lastValue;
          // wenn nicht explizit Ã¼bergben - dann gilt das initValue als lastValue
          if (!lastValue) {
            lastValue = initValue;
          }
          return lastValue;
        }
        if (this.column.meta.neuArt === "Limit") { // Max Wert bzw erste Wert der Combobox
          let lastValue = this.lastValue;
          if (!lastValue) {
            lastValue = initValue;
          }
          return lastValue;
        }
        if (this.column.meta.neuArt === "kein") {
          return null;
        }
        if (this.column.meta.neuArt === "Sync") {
          // noch umbauen - da soll dann das VOn bei Datum vorgefÃ¼llt werden!
          let lastValue = this.lastValue;
          // wenn nicht explizit Ã¼bergben - dann gilt das initValue als lastValue
          if (!lastValue) {
            lastValue = initValue;
          }
          return lastValue;
        }
      }
    }
    return initValue;
  }

  // validate function validert immer
  public validate(): boolean {
    return true; // bin dumm und sicher valide
  }

  // initialisiert den Tooltop anhand der Column Daten
  protected initTooltip() {
    if (!this.column.meta) {
      return;
    }
    if (this.column.meta.tooltip != null) {
      this.showtooltip = true;
      this.tooltip = this.column.meta.tooltip;
    }
  }

  protected valueChanged(data: any, ausInit?: boolean) {
    try {
      // if (this.column?.kennung === "STAMMSTICHTAG") {
      //   this.$api.stichtag = data;
      // }
      if (this.column?.meta?.stichtag && !ausInit && this.$api.stichtag) {
        const aicEigenschaft = this.column.aicEig;
        const aicStamm = this.aic;
        const stichtag = new Date(this.$api.stichtag);
        const dateStichtag = stichtag.toISOStringWithTZ().substr(0, 10);
        // brauch den Ãnderungsstichtag aus BaseEditor...
        this.$api.getStichtag(aicEigenschaft, aicStamm, dateStichtag).then((x: any) => {
          const stichtage = x.data.data;
          if (stichtage && stichtage.length > 0) {
            const abDatum = stichtage[0].ab;
            const ab = formatDate(new Date(abDatum), "dd.MM.yyyy");
            const daten = stichtage[0].daten.bezeichnung;
            this.errorText = "nachfolgender Stichtag vorhanden: " + ab + ", " + daten;
          }
        });
      }
      this.validate();
      if (ausInit) {
        // es wurde nur Initialisiert aber nicht geÃ¤ndert!
        // this.$emit("initValue", data);
      } else {
        this.style = "color: red lighten-2";
        this.$emit("changeValue", data);
      }
      this.$emit("change", data);
      this.$emit("input", data);
      this.$emit("seteditvalue", { value: data, field: this.column });
    } catch (e) {
      console.error(
        "Error in field '" + this.description + "' on valueChanged:" + e
      );
    }
  }
  public loadStichtag() {
    const aicEigenschaft = this.column.aicEig;
    const aicStamm = this.aic;
    this.$api.getStichtag(aicEigenschaft, aicStamm).then((x: any) => {
      const stichtage = x.data;
      if (stichtage) {
        showDialog({
          showTitle: false,
          title: "",
          width: 800,
          site: "BaseStichtage",
          fullscreen: false,
          data: {
            zeitbereich: this.$api.zeitbereich,
            data: stichtage,
          },
          titleColor: "dvhBackground",
          titleClass: "white--text",
          onClose: () => {
            return true;
          },
        });
      }
      // stichtage holen...
    });
  }
}
