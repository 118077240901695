var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"height":_vm.myHeight - 30,"width":"100%"}},[_c('v-card',{staticClass:"pa-0",attrs:{"loading":_vm.showLoading}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"indeterminate":true,"color":"dvhSlider"}})],1),_c('v-card-title',{staticClass:"dvhBackground",staticStyle:{"padding":"0px"}},[_c('v-spacer'),_c('DashboardChartsZuordnungenMenu',{attrs:{"aic":_vm.queryAIC,"printItems":_vm.printItems,"modellItems":_vm.modelle,"showButtons":true,"dark":true}}),_c('CommonBaseButtons',{attrs:{"createVisible":false,"refreshVisible":false,"deleteVisible":false,"saveVisible":false,"backColor":"white","showBackText":false,"backIcon":"mdi-close"}})],1),_c('v-tabs',{model:{value:(_vm.activeTabIndex),callback:function ($$v) {_vm.activeTabIndex=$$v},expression:"activeTabIndex"}},[_c('v-tab',[_vm._v("Ãberweisungen")]),_c('v-tab-item',[_c('v-card',[(_vm.initialized)?_c('BaseVGrid',{attrs:{"query":"WEB_Ueberweisungsliste","zeitbereich":_vm.zeitbereich,"queryAic":_vm.queryAIC,"showClose":false}}):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('CalcButton',{staticClass:"white--text pa-0",staticStyle:{"align":"left","height":"auto"},attrs:{"active-class":"purple white--text","text":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'LV_Zahlungsvorschlag_Reisekosten',
                  'Zahlungsvorschlag'
                ),"modell":"LV_Zahlungsvorschlag_Reisekosten","color":"dvhBackground darken-1","icon":"play_circle_outline","zeitbereich":_vm.editZeitbereich},on:{"calculated":_vm.onCalculated}}),_c('CalcButton',{staticClass:"white--text pa-0",staticStyle:{"align":"left","height":"auto"},attrs:{"active-class":"purple white--text","text":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'RK_Telebanking_Ueberweisung',
                  'Telebanking'
                ),"modell":"RK_Telebanking_Ueberweisung","color":"dvhBackground darken-1","icon":"play_circle_outline","zeitbereich":_vm.editZeitbereich},on:{"calculated":_vm.onCalculated}})],1)],1)],1),_c('v-tab',[_vm._v("durchgefÃ¼hrte Zahlungen")]),_c('v-tab-item',[_c('v-card',[(_vm.initialized)?_c('BaseVGrid',{attrs:{"query":"WEB_erfolgteUeberweisungen_Firma","zeitbereich":_vm.zeitbereich,"queryAic":_vm.queryAIC,"showClose":false}}):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('CalcButton',{staticClass:"white--text pa-0",staticStyle:{"align":"left","height":"auto"},attrs:{"active-class":"purple white--text","text":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'RK_Storno_Zahlungsvorschlag',
                  'Storno'
                ),"modell":"RK_Storno_Zahlungsvorschlag","color":"dvhBackground darken-1","icon":"play_circle_outline","zeitbereich":_vm.editZeitbereich},on:{"calculated":_vm.onCalculated}})],1)],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }