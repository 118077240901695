<!-- dient als Platzhalter, wenn eine leer FlÃ¤che erzeugt werden soll-->

<template>
  <v-spacer></v-spacer>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      data: ""
    };
  }
};
</script>
<style scoped>
</style>
