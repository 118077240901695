// import { BarcodeScanner, BarcodeScannerPlugin } from "@capacitor-community/barcode-scanner";
import {
    BarcodeScanner,
} from '@capacitor-mlkit/barcode-scanning';
import { Capacitor } from "@capacitor/core";

import { getDeviceInfo } from "./DeviceInfoPlugin";

export interface BarcodeScanResult {
    content?: string;
    type?: string;
    cancelled: boolean;
}

let hideApp: () => Promise<void>;
let showApp: () => Promise<void>;
export function initializeBarcodeWrapper(
    hideAppCallback: () => Promise<void>,
    showAppCallback: () => Promise<void>) {
    hideApp = hideAppCallback;
    showApp = showAppCallback;

    (window as any).testBarocdeAppHide = hideApp;
    (window as any).testBarocdeAppShow = showApp;
}

export function scanBarcode(callback?: (content: BarcodeScanResult) => void): Promise<BarcodeScanResult> {
        return scanBarcodeNew(callback);
}

async function scanSingleBarcode(): Promise<{ content: string, format: string }> {
    return new Promise(async resolve => {
        document.querySelector('body')?.classList.add('barcode-scanner-active');

        const listener = await BarcodeScanner.addListener(
            'barcodeScanned',
            async result => {
                await listener.remove();
                document
                    .querySelector('body')
                    ?.classList.remove('barcode-scanner-active');
                await BarcodeScanner.stopScan();
                resolve({ content: result.barcode.rawValue, format: result.barcode.format });
            },
        );

        await BarcodeScanner.startScan();
    });
}

let prepared = false;
async function scanBarcodeNew(callback?: (content: BarcodeScanResult) => void): Promise<BarcodeScanResult> {
    if (!prepared) {
        try {
            const perm = await BarcodeScanner.checkPermissions();
            if (perm.camera === "denied") {
                await BarcodeScanner.requestPermissions();
            }
        } catch (e) {
            // nothin todo
        }
        if (Capacitor.getPlatform() === "android") {
            const avail = await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable();
            if (!avail.available) {
                await BarcodeScanner.installGoogleBarcodeScannerModule();
            }
        }
        prepared = true;
    }

    await hideApp();
    const result = await scanSingleBarcode();

    await showApp();
    const res: BarcodeScanResult = {
        content: result.content,
        type: result.format,
        cancelled: false
    };
    return res;
}

export async function cancelScan() {
    BarcodeScanner.stopScan();
    await showApp();
}

let torchOn = false;
export async function toggleBarcodeScannerTorch() {
    if (!torchOn) {
        BarcodeScanner.enableTorch();
        torchOn = true;
    } else {
        BarcodeScanner.disableTorch();
        torchOn = false;
    }
}
