




























































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";

Vue.use(DateRangePickerPlugin);
@Component
export default class BaseZeitraum extends Vue {
  // props: [],
  private created() {
    // this.months = this.$api.code.gruppe.monat;
    for (
      let i: any = new Date().getFullYear() - 3;
      i < new Date().getFullYear() + 3;
      i++
    ) {
      this.years.push(i);
    }

    // for (let i = 0; i < this.months.length; i++) {
    //   this.monthsToBind.push({ id: i, text: this.months[i] });
    // }

    if (this.$api.zeitbereich.bereich === "Tag") {
      this.activeTab = 0;
    } else if (this.$api.zeitbereich.bereich === "Woche") {
      this.activeTab = 1;
    } else if (this.$api.zeitbereich.bereich === "Monat") {
      this.activeTab = 2;
    } else if (this.$api.zeitbereich.bereich === "Quartal") {
      this.activeTab = 3;
    } else if (this.$api.zeitbereich.bereich === "Jahr") {
      this.activeTab = 4;
    }
    // Datum-Daten setzen
    const date = new Date();
    this.day.from = this.$api.zeitbereich.von.toISOString().substr(0, 10);
    this.day.to = this.$api.zeitbereich.bis.toISOString().substr(0, 10);

    // Monat-Daten setzen
    this.monthNeu.fromDate = this.$api.zeitbereich.von.getMonth();
    this.monthNeu.toDate = this.$api.zeitbereich.bis.getMonth();
    // this.month.fromIx = this.monthsToBind[this.$api.zeitbereich.von.getMonth()];
    // this.month.toIx = this.monthsToBind[this.$api.zeitbereich.bis.getMonth()];

    // this.month.fromYear = "" + this.$api.zeitbereich.von.getFullYear();
    // this.month.toYear = "" + this.$api.zeitbereich.bis.getFullYear();

    // Quartale befÃ¼llen und Daten setzen
    for (let x = 0; x < this.years.length; x++) {
      const y = this.years[x];
      for (let q = 1; q <= 4; q++) {
        let fromMonth: any = 0;
        // const toMonth = 2;
        if (q === 2) {
          fromMonth = 3;
          // toMonth = 5;
        } else if (q === 3) {
          fromMonth = 6;
          // toMonth = 8;
        } else if (q === 4) {
          fromMonth = 9;
          // toMonth = 11;
        }
        const fromdate: any = new Date(y, fromMonth, 1);

        const val: any = q + "" + y;

        const obj: any = { text: q + ". Quartal " + y, val };

        if (fromdate <= this.$api.zeitbereich.von) {
          this.quart.from = obj;
        }
        if (fromdate < this.$api.zeitbereich.bis) {
          this.quart.to = obj;
        }
        // Befehl geht nicht?
        // this.quarts.push(obj);
      }
    }

    this.fillWeeks();

    this.year.fromYear = this.$api.zeitbereich.von.getFullYear();
    this.year.toYear = this.$api.zeitbereich.bis.getFullYear();
  }
  private placeholder: any = "Selct a Range";
  private startVal: any = this.$api.zeitbereich.von;
  private endVal: any = this.$api.zeitbereich.bis;
  private formatMonth: string = "MMM yyyy";
  // private labelVon: string = this.$begriffBezeichnung("von");
  // private labelBis: string = this.$begriffBezeichnung("bis");
  // private labelJahr: string = this.$begriffBezeichnung("Jahr");
  private dialog: boolean = false;
  private activeTab: any = 0;
  private day: any = { from: new Date(), to: null };
  private week: any = {
    from: { date: null, text: "" },
    to: { date: null, text: "" }
  };
  private weekErrors: any = null;
  private weeksToBind: [] = [];
  private monthNeu: any = {
    fromMonth: new Date().getMonth(),
    toMonth: new Date().getMonth()
  };
  private month: any = {
    fromIx: 0,
    toIx: 0,
    fromYear: new Date().getFullYear(),
    toYear: new Date().getFullYear()
  };
  private quart: any = {
    from: "",
    to: ""
  };
  private year: any = {
    fromYear: new Date().getFullYear(),
    toYear: new Date().getFullYear()
  };
  private monthsToBind: [] = [];
  private months: any = [
    "Januar",
    "Februar",
    "MÃ¤rz",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
  ];
  private years: any = [];
  private quarts: any = [];

  private todayClick() {
    if (this.activeTab === 0) {
      // "tabDay"
      this.day.from = this.doOnDateStr(new Date(), (f: any) => f.addDays(0));
      this.day.to = this.doOnDateStr(new Date(), (f: any) => f.addDays(0));
    } else if (this.activeTab === 1) {
      // "tabWoche"
      // this.week.from.date = this.fillWeeks(new Date(), (f: any) => f.addDays(0));
      // this.week.to.date = this.fillWeeks(new Date(), (f: any) => f.addDays(0));
    } else if (this.activeTab === 2) {
      // "tabMonth"
    } else if (this.activeTab === 3) {
      // "tabQuart"
    } else if (this.activeTab === 4) {
      // "tabYear"
    }
    this.setApiValue();
  }
  private fillWeeks() {
    const wochenjahreVergangenheit = 2;
    const wochenjahreZukunft = 1;

    const wochenJahre = wochenjahreVergangenheit + wochenjahreZukunft + 1; // +1 fÃ¼r das aktuelleJahr
    const wochenAnzahl = wochenJahre * 52;

    let constzterMontag = new Date();
    const heute = new Date();
    if (constzterMontag.getDay() !== 1) {
      constzterMontag = constzterMontag.addDays(constzterMontag.getDay() - 1);
    }
    const ersterMontag = constzterMontag.addDays(
      wochenjahreVergangenheit * 52 * 7 * -1
    ); // ersten Montag setzen
    for (let i = 0; i < wochenAnzahl; i++) {
      const dt: any = ersterMontag.addDays(7 * i);
      const wk: any = {
        date: dt,
        text:
          this.$begriffBezeichnung("KW") +
          dt.getWeekNumber() +
          " - " +
          dt.getFullYear() +
          " " +
          this.$begriffBezeichnung("ab") +
          " " +
          dt.getDate() +
          "." +
          (dt.getMonth() + 1)
      };
      if (
        this.$api.zeitbereich.von >= dt &&
        this.$api.zeitbereich.von < dt.addDays(7)
      ) {
        this.week.from = wk;
      }
      if (
        this.$api.zeitbereich.bis >= dt &&
        this.$api.zeitbereich.bis < dt.addDays(7)
      ) {
        this.week.to = wk;
      }
      // nÃ¤chste Befehl geht leider nicht ??
      // this.weeksToBind.push(wk);
    }
  }
  private doOnDateStr(dateStr: any, fn: any) {
    let date = new Date(dateStr);
    date = fn(date);
    return date.toISOString().substring(0, 10);
  }
  private minusClick() {
    if (this.activeTab === 0) {
      // "tabDay"
      this.day.from = this.doOnDateStr(this.day.from, (f: any) =>
        f.addDays(-1)
      );
      this.day.to = this.doOnDateStr(this.day.to, (f: any) => f.addDays(-1));
    } else if (this.activeTab === 1) {
      // "tabWoche"
      // this.week.from.date = this.fillWeeks(this.week.from.date, (f: any) =>
      //   f.addDays(-7)
      // );
      // this.week.to.date = this.fillWeeks(this.week.to.date, (f: any) =>
      //   f.addDays(-7)
      // );
    } else if (this.activeTab === 2) {
      // "tabMonth"
    } else if (this.activeTab === 3) {
      // "tabQuart"
    } else if (this.activeTab === 4) {
      // "tabYear"
    }
    this.setApiValue();
  }
  private plusClick() {
    if (this.activeTab === 0) {
      // "tabDay"
      this.day.from = this.doOnDateStr(this.day.from, (f: any) => f.addDays(1));
      this.day.to = this.doOnDateStr(this.day.to, (f: any) => f.addDays(1));
    } else if (this.activeTab === 1) {
      // "tabWoche"
      // this.week.from.date = this.fillWeeks(this.week.from.date, (f: any) =>
      //   f.addDays(7)
      // );
      // this.week.to.date = this.fillWeeks(this.week.to.date, (f: any) =>
      //   f.addDays(7)
      // );
    } else if (this.activeTab === 2) {
      // "tabMonth"
      this.day.from = this.doOnDateStr(this.day.from, (f: any) =>
        f.addMonths(-1)
      );
      this.day.to = this.doOnDateStr(this.day.to, (f: any) => f.addMonths(-1));
    } else if (this.activeTab === 3) {
      // "tabQuart"
    } else if (this.activeTab === 4) {
      // "tabYear"
    }
    this.setApiValue();
  }

  private addDays(date: any, days: any) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  private tabChanged(tab: any) {
    this.activeTab = tab;
    this.setApiValue();
  }
  private getQuartFromVal(val: any) {
    if (typeof val === "object") {
      val = val.val;
    }
    const q = val.substr(0, 1);
    const y = val.substr(1);

    let fromMonth: any = 0;
    let toMonth: any = 2;
    if (q === 2) {
      fromMonth = 3;
      toMonth = 5;
    } else if (q === 3) {
      fromMonth = 6;
      toMonth = 8;
    } else if (q === 4) {
      fromMonth = 9;
      toMonth = 12;
    }
    const fromdate = new Date(y, fromMonth, 1);
    const todate = new Date(y, toMonth, 1);
    return { from: fromdate, to: todate };
  }
  private setApiValue() {
    if (this.activeTab === 0) {
      // "tabDay"
      this.$api.zeitbereich.bereich = this.$begriffBezeichnung("Tag");
      this.$api.zeitbereich.von = new Date(this.day.from);
      this.$api.zeitbereich.bis = new Date(this.day.to);
    } else if (this.activeTab === 1) {
      // "tabWeek"
      if (this.week.from.date > this.week.to.date) {
        this.weekErrors = this.$begriffBezeichnung("Datum_falsch");
        return;
      }
      this.weekErrors = null;
      this.$api.zeitbereich.bereich = this.$begriffBezeichnung("Woche");
      this.$api.zeitbereich.von = this.week.from.date;
      this.$api.zeitbereich.bis = this.week.to.date.addDays(7);
    } else if (this.activeTab === 2) {
      // "tabMonth"
      this.$api.zeitbereich.bereich = "Monat";
      const dateVon = this.$api.zeitbereich.von;
      const dateBis = this.$api.zeitbereich.bis;

      this.monthNeu.fromMonth = new Date(
        dateVon.getFullYear(),
        dateVon.getMonth(),
        1
      );
      this.monthNeu.toMonth = new Date(
        dateBis.getFullYear(),
        dateBis.getMonth() + 1,
        0
      );

      // this.$api.zeitbereich.bereich = "Monat";
      // this.$api.zeitbereich.von = new Date(
      //   this.month.fromYear,
      //   this.month.fromIx.id,
      //   1
      // );
      // this.$api.zeitbereich.bis = new Date(
      //   this.month.toYear,
      //   this.month.toIx.id + 1,
      //   1
      // );
    } else if (this.activeTab === 3) {
      // "tabQuart"
      this.$api.zeitbereich.bereich = this.$begriffBezeichnung("Quartal");

      this.$api.zeitbereich.von = this.getQuartFromVal(this.quart.from).from;
      this.$api.zeitbereich.bis = this.getQuartFromVal(this.quart.to).to;
    } else if (this.activeTab === 4) {
      // "tabYear"
      this.$api.zeitbereich.bereich = this.$begriffBezeichnung("Jahr");
      this.$api.zeitbereich.von = new Date(this.year.fromYear, 1, 1);
      this.$api.zeitbereich.bis = new Date(this.year.toYear + 1, 1, 1);
    }
    // console.log("--> neuer Zeitbereich: " + this.$api.getAPIZeitbereich())
  }
  private valueChanged() {
    setTimeout(this.setApiValue, 50);
  }
}
