








































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { getDisplay } from "@/DataHelper";
import EditComponent from "@/editor/EditComponent";
import BaseSyncGrid from "./BaseSyncGrid.vue";
import { Globals } from "@/Globals";
import { showSnackbar } from "@/UIHelper";

// dieser in Place editor is fÃ¼r im Grid ohne Editmodus gedacht
@Component
export default class GridInPlaceEditor extends Vue {
  public txt: any = "-";
  public column: any;
  public grid!: BaseSyncGrid;
  private showMenu: boolean = false;
  private offset: boolean = false;
  private uid: any = "";

  private isInitialized: boolean = false;
  private componentName: string = "- not initialized -";
  private editValue: any = null;
  private lastValue: any = null;
  private showLoading: boolean = true;

  public row!: any;
  public lastRow!: any;
  public backupValue!: any;
  private currentVal: any;

  public created() {
    this.componentName = this.getEditComponent(this.column);
  }

  public mounted() {
    if (this.data && this.data.row && this.column) {
      this.row = this.data.row;
      this.txt = getDisplay(this.data.row, this.column);
      this.uid = this.column.uid;
      // this.componentName = this.getEditComponent(this.column);
      this.editValue = this.getEditValue(this.row, this.column);
      this.lastValue = this.getEditValue(this.lastRow, this.column);
      this.isInitialized = true;
      this.showLoading = false;
      this.backupValue = JSON.parse(JSON.stringify(this.row));
    }
  }

  private data: any = { index: -1 };
  private clicked(e: any) {
    console.log("clicked");
  }

  private clickPencil() {
    this.showMenu = true;
  }

  private getEditValue(x: any, field: any) {
    if (x) {
      return x[field.name];
    }
    return x;
  }
  private getEditComponent(field: any) {
    const typeName =
      "edit" +
      field.type.substring(0, 1).toUpperCase() +
      field.type.substring(1); // richtig formatieren ==> der Compontenname wird hier zusammengesetzt!
    console.log(typeName);
    return typeName;
  }

  public validate(): boolean {
    const comp = this.$refs.comp as EditComponent;
    try {
      const x = comp.validate();
      return x;
    } catch {
      return true;
    }
  }

  private valueChanged(val: any) {
    // console.log("valueChanged...");
    this.currentVal = val;
  }

  private save() {
    this.showLoading = true;

    const me = this;

    const val = this.row[this.column.name];
    if (val === this.currentVal) {
      // nix passiert ;)
      this.showLoading = false;
      this.showMenu = false;
      return;
    }

    this.row[this.column.name] = this.currentVal;

    this.$api.SaveData(
      this.grid.header,
      0,
      this.row.aic_Bew_pool,
      this.row,
      this.grid.allColumns,
      "",
      this.backupValue,
      () => {
        me.showLoading = false;
        me.showMenu = false;

        const property = me.grid.fixKennungForProperty(me.column);
        const gridrow = me.grid.data.find((row: any) => row.row === me.row);

        gridrow[property] = getDisplay(me.row, me.column);
        showSnackbar(
          this.$globalsBegriffMemo(
            Globals.Begriff,
            "Datensatz_gespeichert",
            "Daten wurden gespeichert"
          )
        );
      },
      (ex: any) => {
        this.showLoading = false;
        this.showMenu = false;
        console.log(ex);
        alert("Entschuldigung es ist ein Fehler beim Speichern aufgetreten");
      }
    );
  }
}
