






















































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import CalcButton from "@/components/CalcButton.vue";
// import VCardDialog from "@/components/Common/VCardDialog.vue";
import VButtonCarousel from "@/components/common/VButtonCarousel.vue";
import Constants from "@/Constants";
import { DialogParameter, showDialog } from "@/UIHelper";
import { getKennungValueDisplay, getKennungValue, getKZValue } from "@/DataHelper";

@Component
export default class Nacherfassung extends EditPage {
  constructor() {
    super();
  }
  private anzeige: boolean = true;
  private letzteBuchung: string = "---";
  private letzteBuchungUm: string = "---";
  private memo: string = "";
  private abwUhrzeit: Date | null = null;
  private uhrzeit: string | null = "";
  private format: string = "HH:mm";
  private begriff: any = null;
  private absolute: boolean = true;
  private dateString: Date | null = null;
  private timeDate: Date = new Date();
  private showCarousel: boolean = true;
  private showZeitzone: boolean = false;
  private zeitzones: ListItems[] = [];
  private offset!: number;
  private changedoffset!: number;
  private offsetChange: boolean = false;
  private offsetString!: string;
  private aktZeitzone: string = "";
  private reiseBeginn: boolean = true;
  private zwischenTag: boolean = false;
  private onlyRK: boolean = false;
  private onlyBDE: boolean = false;
  private editaic: number = this.siteData.aic;

  public mounted() {
    // data provided by calling page
    // this.showResult = true;

    // am Handy sollen die Buttons angezeigt werden!
    if (Constants.isMobile) {
      this.showCarousel = false;
    }
    if (!this.checkBegriff("SoftTerm_Stempelungen")) {
      this.onlyRK = true;
    }
    const reisen = this.checkBegriff("Web_ReiseUebersichtBuchungen");
    if (reisen) {
      this.query = "Web_ReiseUebersichtBuchungen";
    }
    this.mountedBase();
    const zeitzone = this.checkBegriff("PZE_Web_Zeitzone_Nacherfassen");
    if (zeitzone) {
      this.showZeitzone = true;

      this.offset = new Date().getTimezoneOffset() * -1;
      this.query = "Web_Erfassung_Zeitzone";
      this.$api.getQuery(
        // Query
        this.query,
        // Done function
        (data: any) => this.dataLoadedZone(data),
        0,
        this.zeitbereich,
        0,
        false,
        false,
        "",
        0,
        []
      );
    }
    if (this.siteData) {
      this.timeInvalid = true;
      if (this.siteData.zeitbereich) {
        this.abwUhrzeit = this.siteData.zeitbereich.von;
        this.dateString = this.siteData.zeitbereich.von.toLocaleDateString();
        // Zeitzone GMT - sonst rechnet er bei Amerika zB einen Tag weg!
        this.timeDate = this.siteData.zeitbereich.von.toISOStringWithTZGMT();
      } else {
        // sollte der markierte Tag aus der Time Line sein??
        this.abwUhrzeit = this.$api.zeitbereich.von;
        this.dateString = this.siteData.zeitbereich.von.toLocaleDateString();
      }
    }
  }
  protected dataLoadedZone(data: any) {
    if (data.data.error !== undefined) {
      this.$api.onfail(data.data.error);
      console.log(data.data.error);
      alert("ACHTUNG: " + data.data.error);
      this.$emit("back");
      return;
    }
    const columns = data.data.columns;
    const dataZone = data.data.data;
    if (dataZone?.length > 0) {
      for (let i = 0; i < dataZone.length; i++) {
        const row = dataZone[i];
        const title = getKennungValueDisplay(row, "BEZEICHNUNG", columns);
        const aic = getKennungValueDisplay(row, "SYSAIC", columns);
        const differenz = getKennungValue(row, "ZONE_MIN", columns);
        let select = false;
        // muss mit der Winterzeit vergleichen - weil sonst hab ich es im Sommer um 1 h falsch"!
        const wzOffset = new Date("1970-01-01").getTimezoneOffset() * -1;
        if (wzOffset === differenz) {
          select = true;
        }
        const subtitel = getKennungValueDisplay(row, "KURZBEZEICHNUNG", columns);
        this.zeitzones.push({
          title,
          subtitel,
          aic,
          select,
          differenz,
        });
      }
    } else {
      this.showZeitzone = false;
    }
  }
  private selectChange(val: any) {
    const select = val.select;
    this.aktZeitzone = val.aic;
    this.offsetChange = false;
    if (this.offset !== val.differenz) {
      this.offsetChange = true;
      // this.offset = lokale zeitzone, zb -60
      this.changedoffset = val.differenz;
      // differnz ist zb -120 - die neue Zeitzone
      // offsetString = MET / UTC... etc die kÃ¼rzel fÃ¼r die Zeitzone
      this.offsetString = val.subtitel;
      if (this.abwUhrzeit) {
        this.uhrzeit = new Date(this.abwUhrzeit).toISOStringWithDifferentTimeZone(
          this.changedoffset
        );
      }
    } else {
      this.uhrzeit = "";
    }
    const tempZone: any = this.zeitzones;
    for (let i = 0; i < tempZone.length; i++) {
      if (this.aktZeitzone !== tempZone[i].aic) {
        tempZone[i].select = false;
      }
    }
    this.zeitzones = tempZone;
  }
  protected initialize() {
    const me = this;
    me.reiseBeginn = false;
    if (me.data?.aic_Stamm) {
      const endeReise = getKennungValue(me.data, "RK_REISE_BEENDET", me.columns);
      const beginnReise = getKennungValue(this.data, "BEGIN", this.columns);
      const abfahrt = getKZValue(this.data, "Abfahrt", this.columns);
      const ankunftOri = getKZValue(this.data, "Ankunft", this.columns);
      let ankunft = getKZValue(this.data, "Ankunft", this.columns);
      if (ankunft) {
        ankunft = new Date(ankunft).toISOStringWithTZ().substr(0, 10);
        ankunft = new Date(ankunft).toISOStringWithTZGMT();
      }
      // nur wenn die reise noch nicht beendet ist - sperren!"
      const beginnDate = new Date(beginnReise);
      const endeDate = new Date(endeReise);
      const von = this.siteData.zeitbereich.von;
      const bis = this.siteData.zeitbereich.bis;
      const bisZZ = this.siteData.zeitbereich.bis.toISOStringWithTZGMT();
      if (!endeReise) {
        me.reiseBeginn = true;
      } else if (
        beginnDate.valueOf() !== von.valueOf() &&
        endeDate.valueOf() !== bis.valueOf()
      ) {
        // an Tagen zwischen Beginn und Ende darf kein neuer Beginn eingetragen werden
        me.reiseBeginn = true;
        me.zwischenTag = true;
      }
      // gibt es schon mehrere Reisezeiten Buchungen
      // auch in der Zukunft dann darf ich nicht mehr davor BEENDEN!
      if (
        ankunft &&
        abfahrt !== ankunftOri &&
        ankunft.valueOf() !== bisZZ.valueOf() &&
        ankunft.valueOf() >= bisZZ.valueOf()
      ) {
        me.zwischenTag = true;
      }
    }
    if (me.siteData.nurBDE) {
      me.onlyBDE = true;
    }
    setTimeout(() => {
      me.clearAndFocus();
    }, 200);
  }

  private clearAndFocus() {
    this.abwUhrzeit = null;
    let timepicker: any = this.$refs.timePicker;
    if (timepicker) {
      if (timepicker.length && timepicker.length > 0) {
        timepicker = timepicker[0];
      }
    }
    timepicker?.clearAndFocus(); // es kommt immer ein Error
    this.timeInvalid = true;
  }
  private modell: string | null = null;
  private dialog: boolean = false;
  private dialog1: boolean = false;
  private dialog2: boolean = false;
  private dialog3: boolean = false;
  private expand: boolean = false;
  private timeInvalid: boolean = false;
  protected showResult: boolean = false;

  private validateTimePicker() {
    let timepicker: any = this.$refs.timePicker;
    if (timepicker.length && timepicker.length > 0) {
      timepicker = timepicker[0];
    }
    this.timeInvalid = !timepicker.validate();
  }

  private timeChanged(d: any) {
    console.log("timechanged - " + this.timeInvalid + " - " + d, JSON.stringify(d));
    try {
      this.validateTimePicker();
      if (!d) {
        console.log("time changed: null");
        this.abwUhrzeit = null;
        return;
      }
      if (!this.timeInvalid && d) {
        if (typeof d === "string") {
          this.abwUhrzeit = new Date(d);
          // wurde bereits die Zeitzone geÃ¤ndert, dann muss auch bei der nÃ¤chsten Buchung die Zone gepÃ¼ft werden
          if (this.offsetChange) {
            this.uhrzeit = new Date(this.abwUhrzeit).toISOStringWithDifferentTimeZone(
              this.changedoffset
            );
          }
          return;
        }

        const time = d.value as Date;
        console.log("time changed: " + time.toISOStringWithTZ());
        this.abwUhrzeit = time;
      }
    } finally {
      this.$forceUpdate();
    }
  }
  private openBDE() {
    const varUbergabe: any[] = [];
    let showFullscreen = false;
    if (Constants.isMobile && this.$isPhone()) {
      showFullscreen = true;
    }
    if (!this.aktZeitzone) {
      // nur wenn keine Zeitzone ausgewÃ¤hlt ist!
      const lokalOffset = new Date().getTimezoneOffset() * -1;
      const serverTime = this.$api.user.time;
      const serverOffset = Date.getISOTimeZoneOffsetInMin(serverTime);
      if (lokalOffset !== serverOffset) {
        const newtimeZone = new Date("2021-01-01").getTimezoneOffset() * -1;
        varUbergabe.push({
          var: "differentTimeZone",
          type: "boolean",
          wert: true,
          art: 1,
          user: this.$api.user.user, // wirklich die user ID
          perm: false,
        });
        varUbergabe.push({
          var: "newtimeZone",
          type: "int",
          wert: newtimeZone,
          art: 1,
          user: this.$api.user.user,
          perm: false,
        });
        // this.$api.setVariable(varUbergabe, "");
      }
    } else {
      varUbergabe.push({
        var: "Zeitzone",
        type: "int",
        wert: this.aktZeitzone,
        art: 1,
        user: this.$api.user.user, // wirklich die user ID
        perm: false,
      });
    }
    if (this.abwUhrzeit) {
      let wert = new Date(this.abwUhrzeit).toISOStringWithTZ();
      if (this.offsetChange && this.uhrzeit) {
        wert = this.uhrzeit;
      }
      varUbergabe.push({
        var: "WebabwUhrzeit",
        type: "string",
        wert,
        art: 1,
        user: this.$api.user.user,
        perm: false,
      });
      let aic;
      if (this.editaic) {
        aic = this.editaic;
      }
      showDialog({
        title: "BDE Erfassung",
        titleColor: "dvhBackground",
        titleClass: "white--text",
        width: 400,
        fullscreen: showFullscreen,
        height: 400,
        data: {
          zeitbereich: this.siteData.zeitbereich,
          aic,
          varUbergabe,
          admin: this.siteData.admin,
        },
        site: "SoftTermSoftTermBde",
        okButton: false,
        onClose: (dlg: DialogParameter) => {
          const x = dlg?.returnedData;
          if (x?.ergebnis) {
            this.letzteBuchungUm = "";
            this.letzteBuchung = x.ergebnis;
          }
          // this.$api.deleteVariable();
          this.memo = "";
          this.abwUhrzeit = null;
          this.clearAndFocus();
          return true;
        },
      });
    }
  }
  public onBack() {
    this.$emit("goBack");
  }
  private onCalculating(args: any) {
    // sicherheitshalber erst im nÃ¤chsten tick ausleeren damit ma nicht gleich ein update im button auslÃ¶sen..
    this.$nextTick(() => {
      this.memo = "";
      this.abwUhrzeit = null;
      this.uhrzeit = "";
    });
  }
  protected onCalculated(args: any) {
    this.letzteBuchung = args.text;
    this.letzteBuchung = this.letzteBuchung.slice(0, 10);
    this.letzteBuchungUm = args.ergebnis;
    if (args.JSON?.Gueltigkeit) {
      this.siteData.abwZeitraum = args.JSON.Gueltigkeit;
    }
    if (args.JSON?.fullReload) {
      this.siteData.fullReload = args.JSON.fullReload;
    }
    if (args.ergebnis) {
      this.letzteBuchung = "";
    }
    this.memo = "";
    this.abwUhrzeit = null;
    this.uhrzeit = "";
    this.clearAndFocus();
  }

  private get initTimeValue() {
    if (this.abwUhrzeit) {
      return this.abwUhrzeit.toISOStringWithTZ();
    }
    return null;
  }

  private dienstreiseBeginn(site: string, ende: boolean) {
    let title = "";
    if (ende) {
      title = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_RK_DR_Ende",
        "Dienstreise Ende"
      );
    } else {
      title = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_RK_DR_Beginn",
        "Dienstreise Beginn"
      );
    }
    const isPhone = this.$isPhone();
    // console.log("Mobil: " + Constants.isMobile + "Phone" + isPhone);
    let showFullscreen = false;
    if (Constants.isMobile || this.$isPhone()) {
      showFullscreen = true;
    }
    const varUbergabe: any[] = [];
    if (!this.aktZeitzone) {
      // nur wenn keine Zeitzone ausgewÃ¤hlt ist!
      const lokalOffset = new Date().getTimezoneOffset() * -1;
      const serverTime = this.$api.user.time;
      const serverOffset = Date.getISOTimeZoneOffsetInMin(serverTime);
      if (lokalOffset !== serverOffset) {
        const newtimeZone = new Date("2021-01-01").getTimezoneOffset() * -1;
        varUbergabe.push({
          var: "differentTimeZone",
          type: "boolean",
          wert: true,
          art: 1,
          user: this.$api.user.user, // wirklich die user ID
          perm: false,
        });
        varUbergabe.push({
          var: "newtimeZone",
          type: "int",
          wert: newtimeZone,
          art: 1,
          user: this.$api.user.user,
          perm: false,
        });
        // this.$api.setVariable(varUbergabe, "");
      }
    } else {
      varUbergabe.push({
        var: "Zeitzone",
        type: "int",
        wert: this.aktZeitzone,
        art: 1,
        user: this.$api.user.user, // wirklich die user ID
        perm: false,
      });
    }
    if (this.abwUhrzeit) {
      let wert = new Date(this.abwUhrzeit).toISOStringWithTZ();
      if (this.offsetChange && this.uhrzeit) {
        wert = this.uhrzeit;
      }
      varUbergabe.push({
        var: "WebabwUhrzeit",
        type: "string",
        wert,
        art: 1,
        user: this.$api.user.user,
        perm: false,
      });
      // this.$api.setVariable(varUbergabe, "");
      let height = 400;
      if (showFullscreen) {
        height = window.innerHeight;
      }
      console.info(height);
      showDialog({
        data: { zeitbereich: this.siteData.zeitbereich, varUbergabe },
        title,
        titleColor: "dvhBackground",
        titleClass: "white--text",
        width: 500,
        fullscreen: showFullscreen,
        height,
        site,
        okButton: false,
        onClose: (dlg: DialogParameter) => {
          const x = dlg?.returnedData;
          if (x) {
            this.letzteBuchung = x.ergebnis;
            if (x.JSON?.fullReload) {
              this.siteData.fullReload = x.JSON.fullReload;
            }
          }
          // this.$api.deleteVariable();
          this.memo = "";
          this.abwUhrzeit = null;
          this.reloadData();
          this.clearAndFocus();
          return true;
        },
      });
    }
  }
  private ortswechsel(ort: boolean, fahrzeug?: boolean) {
    let showFullscreen = false;
    let title = this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_RK_Grenzuebertritt",
      "GrenzÃ¼bertritt"
    );
    const varUbergabe: any[] = [];
    if (fahrzeug) {
      title = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_RK_Fahrzeugwechsel",
        "Fahrzeugwechsel"
      );
      varUbergabe.push({
        var: "Fahrzeugwechsel",
        type: "boolean",
        wert: true,
        art: 1,
        user: this.$api.user.user,
        perm: false,
      });
    }
    if (Constants.isMobile && this.$isPhone()) {
      showFullscreen = true;
    }
    const site = "SoftTermReisenGrenzuebertritt";
    let query = "WEB_RK_Ortswechsel_RZ";
    if (ort) {
      title = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_RK_Ortswechsel",
        "Zwischenstopp"
      );
      // site = "SoftTermReisenOrtswechsel";
      query = "Web_RK_Zwischenstop";
    }
    if (!this.aktZeitzone) {
      // nur wenn keine Zeitzone ausgewÃ¤hlt ist!
      const lokalOffset = new Date().getTimezoneOffset() * -1;
      const serverTime = this.$api.user.time;
      const serverOffset = Date.getISOTimeZoneOffsetInMin(serverTime);
      if (lokalOffset !== serverOffset) {
        const newtimeZone = new Date("2021-01-01").getTimezoneOffset() * -1;
        varUbergabe.push({
          var: "differentTimeZone",
          type: "boolean",
          wert: true,
          art: 1,
          user: this.$api.user.user, // wirklich die user ID
          perm: false,
        });
        varUbergabe.push({
          var: "newtimeZone",
          type: "int",
          wert: newtimeZone,
          art: 1,
          user: this.$api.user.user,
          perm: false,
        });
        // this.$api.setVariable(varUbergabe, "");
      }
    } else {
      varUbergabe.push({
        var: "Zeitzone",
        type: "int",
        wert: this.aktZeitzone,
        art: 1,
        user: this.$api.user.user, // wirklich die user ID
        perm: false,
      });
    }
    if (this.abwUhrzeit) {
      let wert = new Date(this.abwUhrzeit).toISOStringWithTZ();
      if (this.offsetChange && this.uhrzeit) {
        wert = this.uhrzeit;
      }
      varUbergabe.push({
        var: "WebabwUhrzeit",
        type: "string",
        wert,
        art: 1,
        user: this.$api.user.user,
        perm: false,
      });
      // this.$api.setVariable(varUbergabe, "");
      showDialog({
        data: {
          zeitbereich: this.siteData.zeitbereich,
          query,
          ort,
          fahrzeug,
          varUbergabe,
        },
        title,
        titleColor: "dvhBackground",
        titleClass: "white--text",
        width: 400,
        fullscreen: showFullscreen,
        height: 400,
        site,
        okButton: false,
        onClose: (dlg: DialogParameter) => {
          const x = dlg?.returnedData;
          if (x) {
            this.letzteBuchung = x.ergebnis;
          }
          // this.$api.deleteVariable("WebabwUhrzeit");
          this.memo = "";
          this.abwUhrzeit = null;
          this.uhrzeit = null;
          this.clearAndFocus();
          return true;
        },
      });
    }
  }
}
