




























































































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getKennungValueDisplay } from "@/DataHelper";
import { Globals } from "@/Globals";

@Component
export default class EditStempelantrag extends EditPage {
  constructor() {
    super();
    if (this.siteData.freigabe) {
      this.disabled = true;
      this.deleteVisible = false;
      this.query = "Web_Freigabe_Stempelantrag";
    } else {
      this.query = "Web_editStempelantrag";
    }
    this.showLoading = true;
  }
  protected disabled: boolean = false;
  protected deleteVisible: boolean = true;
  protected saveVisible: boolean = false;
  private backVisible: boolean = false;
  private showZeitzone: boolean = false;
  private showbde: boolean = false;
  private editAntragReisen: boolean = false;
  private datenloeschen: boolean = true;

  public mounted() {
    const zeitzone = this.checkBegriff("PZE_Web_Zeitzone_Nacherfassen");
    if (zeitzone) {
      this.showZeitzone = true;
    }
    if (this.siteData) {
      if (this.siteData.bde) {
        this.showbde = true;
      }
      if (this.siteData.oriAIC === 0) {
        this.datenloeschen = false;
      }
      if (this.siteData.reise) {
        this.editAntragReisen = true;
      }
      if (this.siteData.noDelete) {
        this.deleteVisible = false;
      }
      if (this.siteData.edit) {
        this.backVisible = true;
      }
    }
    this.mountedBase();
  }
  private save() {
    const aic: string = "0";
    const me = this;
    this.onSave(0, "", "", aic)?.then(() => {
      this.$nextTick(() => me.$emit("save"));
    });
  }
  protected initialize() {
    const antrag = getKennungValueDisplay(this.data, "ZE_ANTRAG_BOOL3", this.columns);
    if ((antrag === 1 && !this.siteData.freigabe) || !this.header.edit) {
      this.disabled = true;
    }
  }
  public bewDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.onDelete(this.data.aic_Bew_pool, "");
      this.$emit("back");
    }
  }
  private checkChanged(val: any) {
    this.saveVisible = true;
  }
  private changedTime(x: any) {
    if (x) {
      this.saveVisible = true;
    }
  }
}
