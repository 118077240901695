










































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import CreateList from "../CreateList";
import draggable from "vuedraggable";
Vue.component("draggable", draggable);
import { Container } from "vue-dndrop";
Vue.component("container", Container);
import { getKZValue, getKZValueDisplay } from "@/DataHelper";

@Component
export default class Board extends CreateList {
  private initBoard: boolean = false;
  private columnBoard: ColumnBoard[] = [];
  private showDropdown: any;
  private item: any = {};
  private subForm: string = "DvhEditVersion";
  private queryBoard: string = "Web_Version_Status";
  private avatarIcon: any;
  protected colWidth!: number | string;
  protected colMinWidth: number | string = 100;
  protected colMaxWidth: number | string = 300;
  private mounted() {
    this.query = "Web_Version_KanbanBoard";
    this.mountedBase();
  }
  private async setKanban() {
    if (this.queryBoard) {
      const response = await this.$api.getQuery(
        this.queryBoard,
        undefined,
        this.queryAIC,
        this.editZeitbereich,
        0,
        false,
        false,
        "",
        0,
        this.vecStamm,
        "",
        false,
        0,
        [],
        this.jokerStt
      );
      if (response) {
        const dataStatus: any = response.data.data;
        const colStatus: any = response.data.columns;
        for (let i = 0; i < dataStatus.length; i++) {
          const row = dataStatus[i];
          const name = getKZValue(row, "name", colStatus);
          const status = getKZValueDisplay(row, "status", colStatus);
          let color = getKZValueDisplay(row, "color", colStatus);
          if (!color) {
            color = "#dc3545";
          }
          if (name) {
            this.columnBoard.push({
              name,
              id: i,
              status,
              columnItems: [],
              color,
            });
          }
        }
        if (this.columnBoard && this.columnBoard.length > 0) {
          for (let r = 0; r < this.columnBoard.length; r++) {
            const board = this.columnBoard[r];
            if (board && this.item && this.item.length > 0) {
              const style = "border-left: 5px solid " + board.color + ";";
              for (let x = 0; x < this.item.length; x++) {
                const rowItem = this.item[x];
                const statusItem = getKZValueDisplay(rowItem, "status", this.columns);
                if (board.status === statusItem) {
                  const title = getKZValueDisplay(rowItem, "title", this.columns);
                  const date = getKZValueDisplay(rowItem, "date", this.columns);
                  const text = getKZValueDisplay(rowItem, "text", this.columns);
                  const user = getKZValueDisplay(rowItem, "user", this.columns);
                  let avatar = getKZValueDisplay(rowItem, "avatar", this.columns);
                  if (avatar) {
                    if (avatar.data) {
                      avatar = avatar.data;
                    } else {
                      this.loadImage(avatar);
                      avatar = this.avatarIcon;
                    }
                  }
                  const task = getKZValueDisplay(rowItem, "number", this.columns);
                  board.columnItems.push({
                    task,
                    id: rowItem.aic_Bew_pool,
                    title,
                    date,
                    status: statusItem,
                    user,
                    avatar,
                    aic_BewPool: rowItem.aic_Bew_pool,
                    currentStatus: statusItem,
                    dropdownId: x,
                    style,
                  });
                }
              }
            }
          }

          this.initBoard = true;
        }
      }
    }
  }
  private loadImage(avatar: any) {
    this.avatarIcon = this.$api.fullUrl(
      "imageM/" + this.$api.user.id + ":" + +avatar.aic + "_" + avatar.name
    );
  }
  protected refreshItems() {
    this.item = this.data;
    if (this.queryBoard) {
      this.setKanban();
    }
  }
  private selectStatus(item: any) {
    this.showDropdown = {
      id: item.id,
    };
  }
  private get columnStyle(): string {
    return `width: ${this.colWidth}px;
          min-width: ${this.colMinWidth}px;
          max-width: ${this.colMaxWidth}px;
          `;
  }
  private onBack() {
    this.columnBoard.push();
    // neue Aufgabe dazu...
  }
}
declare interface ColumnBoard {
  id: number;
  name: string;
  status?: string;
  columnItems: ColumnItems[];
  color?: string;
}
declare interface ColumnItems {
  id: number;
  task: string;
  title?: string;
  date?: string;
  user?: string;
  status?: string;
  avatar?: string;
  style?: string;
  currentStatus?: string;
  aic_BewPool?: number;
  dropdownId?: any;
}
