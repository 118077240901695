import * as LZString from "lz-string";
import AUApi from './api';

declare var ALLConfig: ALLConfigType;

export function getConfigDataString(config: ALLConfigType = ALLConfig): string {
    const dbs = [];
    const rootUrls: any[] = [];

    if (!config) {
        config = ALLConfig;
    }

    // in der AUApi - steht die gewÃ¤hlte DB - wenn es mehrere gibt
    // gibt es eine CurrentDB muss die vorziehen IMMER!
    if (AUApi?.Current?.dB) {
        config.dB = AUApi.Current.dB;
    }
    // if (!config.dB && AUApi?.Current?.dB) {
    //     config.dB = AUApi.Current.dB;
    // }
    const db = config.DBs.find(d => d.db === config.dB);
    if (db) { dbs.push(db); }
    let path = "/ALL/UNLIMITED/";
    if (config.path) {
        path = config.path;
    }
    let url = config.rootUrl;
    if (url === undefined) {
        // die RootUrl kann leer sein - dann muss sie aus der location geholt werden!
        // 17.3.2021 unbedingt path nehmen - fÃ¼r SubOrdner!
        url = window.location.origin + path;
    }
    if (url) { rootUrls.push(url); }

    const cfg: ALLConfigType = {
        rootUrl: url,
        proxy: config.proxy,
        dB: db?.db ?? config.dB,
        DBs: dbs,
        initialized: true,
        path,
        nativeHttp: config.nativeHttp,
        roots: rootUrls,
        azureConfig: config.azureConfig,
    };
    console.log("rootUrl fÃ¼r QR: " + cfg.rootUrl);
    console.log("db fÃ¼r QR: " + cfg.dB);
    console.log("proxy fÃ¼r QR: " + cfg.proxy);
    console.log("aktuelle DB laut AUAPI: " + AUApi?.Current?.dB);
    if (cfg.azureConfig) {
    console.log("AZURE Config CLient ID: " + cfg.azureConfig.msalConfig.auth.clientId);
    }
    return LZString.compressToBase64(JSON.stringify(cfg));
}


export function readConfigDataString(data: string) {
    const json = LZString.decompressFromBase64(data);
    if (!json) { throw new Error("invalid config"); }
    const cfg = JSON.parse(json) as ALLConfigType;
    return cfg;
}
