var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"dvhMenu",staticStyle:{"z-index":"300"},attrs:{"dark":"","app":"","mini-variant":_vm.myMini,"mini-variant-width":80,"temporary":"","absolute":""},on:{"update:miniVariant":function($event){_vm.myMini=$event},"update:mini-variant":function($event){_vm.myMini=$event},"transitionend":_vm.changedMe},model:{value:(_vm.myDrawer),callback:function ($$v) {_vm.myDrawer=$$v},expression:"myDrawer"}},[_c('v-layout',{attrs:{"column":"","align-center":""}},[_c('v-flex',{staticClass:"mt-5"},[(_vm.showAvatar)?_c('v-avatar',{attrs:{"size":"60","rounded":""}},[_c('v-img',{attrs:{"src":_vm.avatar}})],1):_vm._e(),(!_vm.myMini)?_c('v-row',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"grey--text subheading mt-1",attrs:{"text":""}},on),[_vm._v(_vm._s(_vm.username))])]}}],null,false,1134094016)},[_c('v-list',_vm._l((_vm.userItems),function(userItem,ux){return _c('v-list-item',{key:'ui' + ux},[_c('v-list-item-content',[(userItem.subItems)?_c('div',[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',[_vm._v(_vm._s(userItem.icon))]),_vm._v(" Â  "+_vm._s(userItem.text)+" ")],1)]}}],null,true)},[_c('v-list',_vm._l((userItem.subItems),function(subitem,ix){return _c('v-list-item',{key:'si' + ix},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onUserItemClick(subitem)}}},[_c('v-icon',{attrs:{"color":subitem.color}},[_vm._v(_vm._s(subitem.icon))]),_vm._v(" Â  "+_vm._s(subitem.text)+" ")],1)],1)}),1)],1)],1):_vm._e(),(!userItem.subItems)?_c('div',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onUserItemClick(userItem)}}},[_c('v-icon',[_vm._v(_vm._s(userItem.icon))]),_vm._v(" Â  "+_vm._s(userItem.text)+" ")],1)],1):_vm._e()])],1)}),1)],1)],1):_vm._e()],1)],1),_c('v-list',{staticClass:"pa-0 dvhMenu"},[_c('v-divider',{staticClass:"mx-4",attrs:{"inset":""}}),_c('v-list-item',{staticStyle:{"cursor":"pointer"}},[_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.myMini = !_vm.myMini}}},[_c('v-icon',[_vm._v("chevron_left")])],1)],1)],1),_vm._l((_vm.menuItems),function(item,i){return _c('v-list-item',{key:i,staticStyle:{"cursor":"pointer"},attrs:{"value":"true"}},[_c('v-list-item-action',{on:{"click":function($event){_vm.onShowSite(item.site, null, item.name);
          _vm.mini = true;}}},[(!item.subsites)?_c('v-btn',{attrs:{"icon":"","title":item.name}},[(item.icon)?_c('v-icon',{domProps:{"innerHTML":_vm._s(item.icon)}}):_vm._e(),(item.img)?_c('img',{staticStyle:{"height":"30px","width":"30px","border-radius":"100%"},attrs:{"src":item.img}}):_vm._e()],1):_vm._e(),(item.subsites)?_c('v-menu',{class:'submenu' + i,attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"slot":"activator","icon":""},slot:"activator"},on),[(item.icon)?_c('v-icon',{domProps:{"innerHTML":_vm._s(item.icon)}}):_vm._e(),(item.img)?_c('img',{staticStyle:{"height":"30px","width":"30px","border-radius":"100%"},attrs:{"src":item.img}}):_vm._e()],1)]}}],null,true)},[_c('v-list',{staticClass:"dvhMenu",attrs:{"dark":""}},_vm._l((item.subsites),function(sub,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.onShowSite(sub.site, null, sub.name)}}},[_c('v-list-item-action',[(sub.icon)?_c('v-icon',{attrs:{"text":""},domProps:{"innerHTML":_vm._s(sub.icon)}}):_vm._e(),(sub.img)?_c('img',{staticStyle:{"height":"30px","width":"30px","border-radius":"100%","display":"inline"},attrs:{"src":sub.img}}):_vm._e()],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung(_vm.Globals.Begriff, sub.name, sub.name))+" ")])],1)}),1)],1):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{on:{"click":function($event){return _vm.onShowSite(item.site)}}},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung(_vm.Globals.Begriff, item.name, item.name))+" ")])],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }