






































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
import { Globals } from "@/Globals";
import Constants from "@/Constants";
import { Capacitor } from "@capacitor/core";
import { scanBarcode, BarcodeScanResult } from "@/BarcodeScanWrapper";
import { getDisplay } from "@/DataHelper";
import { showSnackbar, showDialog } from "@/UIHelper";
import { Keyboard } from "@capacitor/keyboard";
import { onShowSite } from "@/NavigationHelper";
import { isPhone } from "../DeviceInfoPlugin";
import ValueChangeTracker from "./ValueChangeTracker.vue";

@Component
export default class EditComboBox extends EditComponent {
  // props: ["initValue", "column", "description", "query"],
  @Prop({ default: "BEZEICHNUNG" }) public textKennung!: string;
  @Prop({ default: false }) public fireOnText!: boolean;
  @Prop({ default: false }) public barcodeEnabled!: boolean;
  @Prop({ default: false }) public clearable!: boolean;
  @Prop({ default: false }) public dark!: boolean;
  @Prop({ default: false }) public noChange!: boolean;
  @Prop({ default: false }) public austrittLaden!: boolean;
  @Prop() public selectedItem!: number;
  @Prop({ default: "Scannen Sie einen Barcode" }) public barcodePrompt!: string;
  @Prop({ default: false }) public loadFavorite!: boolean;
  protected barcodeButtonVisible = false;
  private austritte: string = "0";
  protected zeitbereich!: APIZeitbereich | null;
  private rawlist: any[] = [];
  private list: any[] = [];
  private nullElement: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wÃ¤hlen"
    ),
  };
  private selected: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wÃ¤hlen"
    ),
  };
  private cacheItems: boolean = false;
  private currentText: string = "";
  private aicMode: boolean = true;
  private field: any = this.queryColumn;
  private errorMessages: string[] = [];
  private showSettings: boolean = false;
  private sysAbfrage: any = null;
  private selectedText: string = "";
  private height: string = "400px";
  private menuwith: string = "100%";
  private autofill: boolean = true; // die Combobox wird automatisch gefÃ¼llt bei TRUE
  private favColumn: any = null; // column fÃ¼r Favoriten wenn vorhanden...
  private barcodeColumn: any = null;
  private initialized: boolean = false;

  private showLoading: boolean = false;
  private favorite: boolean = false;
  private kennungFavoritCol: string = "FAVORIT";
  private kennungBarcodeCol: string = "Barcode";
  private fields: any = { text: "bezeichnung", value: "aic" };
  private showSelect: boolean = false;
  private useAutocomplete: boolean = false;
  private model: any = null;
  private search: string = "";
  private anzahlCols: number = 10;

  // moved refresh out of mounted to call it from extern :P
  public refreshData(cacheQuery: boolean = true) {
    const me = this;
    if (this.query && !this.kennung) {
      // wird ein Query Ã¼bergeben, dann muss ich die Daten fÃ¼r die
      // ComboBox erst laden! dann ist die Combo NICHT Teil einer Abfrage
      this.showLoading = true;
      const vecStamm: any[] = [];
      return this.$api.getQuery(
        this.query,
        (data: any) => me.dataLoadedFromServer(data),
        0, // Stammsatz fÃ¼r die ABfrage muss in diesem fall LEER Sein
        this.zeitbereich, // Zeitbereich muss auch Ã¼bergeben werden...
        0,
        cacheQuery, // cachen ein ausschalten nach Definition
        false,
        "1",
        0,
        vecStamm,
        "",
        false,
        0,
        [],
        [],
        this.austritte
      );
    }
    return Promise.resolve();
  }

  public mounted() {
    try {
      this.mountedBase();
      if (this.queryZeitbereich) {
        this.zeitbereich = this.queryZeitbereich;
      }
      const me = this;
      this.refreshData();

      if (!this.field && this.column) {
        this.field = this.column;
      }

      // mit dem Parameter kein Subformular - kann alles Ã¼bersteuert werden!
      if (this.field) {
        // const subFormular = !this.field.meta.noSubFom;
        if (this.field.meta.keinVorfuellen) {
          this.useAutocomplete = true;
        }
      }
      if (Constants.isMobile && !this.useAutocomplete && this.enabled) {
        this.showSelect = true;
      }
      // console.log("enabled: " + this.enabled);
      if (this.enabled || this.column.meta.mobil) {
        if (this.column && this.column.meta.Stt) {
          if (this.field.meta.noSubFom) {
            this.showSettings = false;
          } else {
            const stt = this.field.meta.Stt;
            const begr = this.$globalsStt(Globals.Begriff, stt, "", true);
            if (begr) {
              this.sysAbfrage = begr.kennung;
              this.showSettings = true;
            }
          }
        }
        if (this.field && this.field.meta && this.field.meta.combo) {
          if (this.data) {
            if (this.data.aic !== undefined) {
              const element = this.field.meta.combo.find(
                (f: any) => f.aic === this.data.aic
              );
              // console.log("element - Zeile 274: " + element);
              if (element) {
                this.selected = element;
                this.selectedText = element.bezeichnung;
              }
            } else {
              const ix = this.field.meta.combo.indexOf(this.data);
              this.selected = this.data;
              this.autofill = false;
              /* wenn die Combobox Ã¼ber eine andere Abfrage geladen werden muss - autofill auf FALSE stellen! */
            }
          }
          for (let i = 0; i < this.field.meta.combo.length; i++) {
            const entry = this.field.meta.combo[i];
            if (typeof entry === "object") {
              this.list.push(entry);
              this.aicMode = true;
            } else {
              this.list.push({ aic: entry, bezeichnung: entry });
              this.aicMode = false;
            }
          }
          this.rawlist = this.list;
          console.log(
            "loaded list with " + this.list.length + " items for " + this.description
          );
          this.enabled = this.list.length > 0;
          // bei nur Barcode - am Handy muss Combobox gefÃ¼llt werden aber nur Ã¼ber barcode ausgewÃ¤hlt werden
          if (this.column.meta.mobil && this.column.meta.readOnly) {
            this.enabled = false;
          }
          // data selber kann leer sein dann soll die Combobox laut definition vorgefÃ¼llt werden
          this.data = this.getInitValue();
          if (this.data) {
            const element = this.data;
            this.selected = element;
            this.selectedText = element.bezeichnung;
            if (this.initStichtag) {
              this.showStichtag = true;
              this.hintText = "ab: " + this.initStichtag;
            }
            if (this.initPassValue) {
              this.hintText = "Wert aus: " + this.initPassValue;
            }
          }
          this.updateList();
          if (this.barcodeButtonVisible) {
            this.anzahlCols = 10;
          } else if (
            this.showSettings &&
            (this.barcodeButtonVisible || this.useAutocomplete)
          ) {
            this.anzahlCols = 11;
          } else if (this.useAutocomplete || this.showSettings) {
            this.anzahlCols = 11;
          } else {
            this.anzahlCols = 12;
          }
          this.initialized = true;
        } else if (this.field && this.field.meta.filter) {
          // const me = this;
          const filter = this.field.meta.filter;
          const query = this.$globalsAic(Globals.Begriff, filter).kennung;
          const SbRefresh = this.$globalsBegriffCache(Globals.Begriff, query, false);
          const cache = SbRefresh ? false : true; // SbRefresh = Berechtigung refresh - daher KEIN Cachen!
          // query aus der Begriffstabelle laden - und dmait Inhalt fÃ¼r die ComboBox - wenn COMBO leer ist!
          if (query) {
            this.showLoading = true;
            const vecStamm: any[] = [];
            if (!this.useAutocomplete || this.loadFavorite) {
              let suche = "";
              if (this.loadFavorite && this.data) {
                suche = this.data.bezeichnung + "%";
              }

              this.$api.getQuery(
                query,
                (data: any) => me.dataLoadedFromServer(data),
                0, // Stammsatz fÃ¼r die ABfrage muss in diesem fall LEER Sein
                this.zeitbereich, // Zeitbereich muss auch Ã¼bergeben werden...
                0,
                cache, // cachen ein ausschalten nach Definition
                false,
                "1",
                0,
                vecStamm,
                suche,
                false,
                0,
                [],
                [],
                this.austritte
              );
            } else {
              this.anzahlCols = 11;
              this.initialized = true;
              // bei autoComplete sollen nur die erforderlichen Daten geladen werdern
              this.data = this.getInitValue();
              if (this.data) {
                const element = this.data;
                this.selected = element;
                this.selectedText = element.bezeichnung;
                if (this.initStichtag) {
                  this.showStichtag = true;
                  this.hintText = "ab: " + this.initStichtag;
                }
              }
              if (this.data?.bezeichnung) {
                this.reloadList(this.data.bezeichnung);
              }
            }
          } else {
            console.warn(
              "kann query fÃ¼r begriff " +
                filter +
                " im Feld '" +
                this.description +
                "' (aic: " +
                this.column.aic +
                ") nicht finden!"
            );
          }
        } else {
          let name = "" + this.kennung;
          if (this.field) {
            name += " " + this.field.name;
          }
          if (this.barcodeButtonVisible) {
            this.anzahlCols = 10;
          } else if (
            this.showSettings &&
            (this.barcodeButtonVisible || this.useAutocomplete)
          ) {
            this.anzahlCols = 11;
          } else if (this.useAutocomplete || this.showSettings) {
            this.anzahlCols = 11;
          } else {
            this.anzahlCols = 12;
          }
          console.log("EditCombobox: no data and config for " + name);

          this.initialized = true;
        }
      } else if (this.data) {
        const element = this.data;
        this.selected = element;
        if (this.initStichtag) {
          this.showStichtag = true;
          this.hintText = "ab: " + this.initStichtag;
        }
        if (this.useAutocomplete) {
          this.list.push(element);
          this.initialized = true;
          this.aicMode = true;
        }
      } else {
        this.data = this.getInitValue();
        if (this.data) {
          const element = this.data;
          this.selected = element;
          this.selectedText = element.bezeichnung;
          if (this.initStichtag) {
            this.showStichtag = true;
            this.hintText = "ab: " + this.initStichtag;
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
    this.$emit("mountFinished", this.selected);
  }
  @Watch("search")
  private findSearch(val: string) {
    if (!val) {
      this.showLoading = false;
      return;
    }
    if (val !== this.selected?.bezeichnung) {
      this.reloadList(val);
    }
  }
  protected checkBegriff(kennung: string) {
    const x = this.$globalsKennung(Globals.Begriff, kennung, false);
    return x;
  }
  private async reloadList(val: string) {
    // von DB Daten holen!
    const stammtyp = this.field.meta.Stt;
    const me = this;
    const suche = val + "%";
    // const valKlein = val.toLowerCase();
    // let stringCheck = valKlein.indexOf("Ã¶");
    // if (stringCheck > 0) {
    //   suche = "";
    // } else {
    //   stringCheck = valKlein.indexOf("Ã¤");
    // }
    // if (stringCheck > 0) {
    //   suche = "";
    // } else {
    //   stringCheck = valKlein.indexOf("Ã¼");
    // }
    // if (stringCheck > 0) {
    //   suche = "";
    // }
    const vecStamm: any[] = [];
    const cache = false;
    let query;
    if (this.query) {
      query = this.query;
    } else {
      const filter = this.field.meta.filter;
      query = this.$globalsAic(Globals.Begriff, filter).kennung;
    }
    this.$api.getQuery(
      query,
      (data: any) => me.dataLoadedFromServer(data),
      0, // Stammsatz fÃ¼r die ABfrage muss in diesem fall LEER Sein
      this.zeitbereich, // Zeitbereich muss auch Ã¼bergeben werden...
      0,
      cache, // cachen ein ausschalten nach Definition
      false,
      "1",
      0,
      vecStamm,
      suche,
      false,
      0,
      [],
      [],
      this.austritte
    );
  }

  private timerID = 0;

  private filterFunction(item: any, queryText: string, itemText: string): boolean {
    queryText = queryText.toLowerCase();
    if (item.headerHtml) {
      return true;
    }
    if (
      (itemText && itemText.toLowerCase && itemText.toLowerCase() + "").startsWith(
        queryText
      )
    ) {
      return true;
    }
    if (item.rowData) {
      const x = item.rowData.find(
        (t: string) => t && t.toLowerCase && (t.toLowerCase() + "").startsWith(queryText)
      );
      if (x) {
        return true;
      }
    }
    return false;
  }

  private updateList() {
    const me = this;
    this.list = this.computeList(); // .filter(item => me.filterFunction(item, evt, ""));
  }

  private computeList() {
    if (!this.favColumn) {
      this.favorite = false;

      return this.rawlist;
    }

    const me = this;
    this.favorite = true;
    const sortedlist = this.rawlist.sort((a: any, b: any) => {
      let f1 = "0";
      if (a.headerHtml) {
        return -1;
      }
      if (b.headerHtml) {
        return 1;
      }
      if (a.row) {
        f1 = a.row[me.favColumn.name];
      }
      let f2 = "0";
      if (b.row) {
        f2 = b.row[me.favColumn.name];
      }

      if (!f1) {
        f1 = "";
      }
      if (!f2) {
        f2 = "";
      }

      f1 += "_" + a.bezeichnung;
      f2 += "_" + b.bezeichnung;
      if (f1.toLowerCase() === f2.toLowerCase()) {
        return 0;
      }
      if (f1 > f2) {
        return 1;
      } else {
        return -1;
      }
    });

    this.enabled = this.list.length > 0;
    if (this.column?.meta.mobil && this.column?.meta.readOnly) {
      this.enabled = false;
    }
    return sortedlist;
  }
  private dataLoadedFromServer(data: any) {
    try {
      this.showLoading = false;
      const header = data.data.header;
      const columns = data.data.columns;
      if (this.query) {
        this.description = "";
      }

      let headerHtml = "<span class='ddHeader'>";
      const me = this;
      let visibleColumns: any[] = [];
      if (Constants.isMobile && this.$isPhone()) {
        visibleColumns = columns.filter(
          (c: any) =>
            c.meta &&
            !c.meta.invisible &&
            c.meta.mobil &&
            c.kennung !== me.kennungFavoritCol &&
            c.kz !== me.kennungBarcodeCol
        );
        if (!visibleColumns) {
          visibleColumns = columns.filter(
            (c: any) =>
              c.meta &&
              !c.meta.invisible &&
              !c.meta.mobil &&
              c.kennung !== me.kennungFavoritCol &&
              c.kz !== me.kennungBarcodeCol
          );
        }
      } else {
        visibleColumns = columns.filter(
          (c: any) =>
            c.meta &&
            !c.meta.invisible &&
            !c.meta.mobil &&
            c.kennung !== me.kennungFavoritCol &&
            c.kz !== me.kennungBarcodeCol
        );
      }
      const favColumn = (this.favColumn = columns.find(
        (c: any) => c.kennung === me.kennungFavoritCol
      ));
      const barcodeColumn = (this.barcodeColumn = columns.find(
        (c: any) => c.meta.kz === me.kennungBarcodeCol
      ));

      // barcode icon sichtbar machen wenn in da app && wenn berechtigt
      // achtung BARCODE geht nur in der APP - da der Capacitor erforderlich

      this.barcodeButtonVisible =
        barcodeColumn &&
        Capacitor.getPlatform() !== "web" &&
        this.checkBegriff("Web_Barcode");

      let basePercent: number = 100;
      if (favColumn) {
        basePercent = 80;
      }

      const headerItem: any = {};
      // const allVisibleColumns = visibleColumns.concat(favColumn);
      let allVisibleColumns = visibleColumns;
      if (favColumn) {
        allVisibleColumns = visibleColumns.concat(favColumn);
      }

      const visibleColsWithWidthCount =
        visibleColumns.filter((col) => col.meta.webWidth)?.length ?? 0;
      const visibleColumnsLength = visibleColumns.length;
      let percent = "";

      if (visibleColumnsLength !== visibleColsWithWidthCount) {
        percent = (
          basePercent /
          (visibleColumns.length - visibleColsWithWidthCount)
        ).toFixed(0);
      } else {
        percent = (basePercent / visibleColsWithWidthCount).toFixed(0);
      }
      for (let i = 0; i < visibleColumns.length; i++) {
        let percentColumn = percent;
        if (visibleColumns[i].meta.webWidth) {
          // gibt es eine Einstellung - dann die nehmen!
          percentColumn = visibleColumns[i].meta.webWidth;
        }
        headerHtml +=
          "<span style='display: table-cell;width:" +
          percentColumn +
          "%'>" +
          allVisibleColumns[i].title +
          "</span>";

        headerItem[allVisibleColumns[i].name] = {
          aic: -1,
          bezeichnung: allVisibleColumns[i].title,
        };
      }

      headerItem.headerHtml =
        headerHtml +
        (favColumn
          ? "<i aria-hidden='true' class='v-icon notranslate v-icon--dense material-icons theme--light black--text' style='color:gray!important'>favorite_border</i>"
          : "") +
        "</span>";

      // zuerst spalte raussuchen und name -> propertyname in data row...
      let bezCol = columns.find((c: any) => c.kennung === me.textKennung);
      if (!bezCol) {
        bezCol = columns.find((col: any) => col.meta.sortiert === 1);
        if (!bezCol) {
          bezCol = columns.find((c: any) => c.datatype === "Stringx");
        }
      }
      const columnBez = bezCol.name;

      const combo = data.data.data;
      if (combo.length > 0) {
        this.aicMode = true;
        const mapped = combo.map((row: any): any => {
          const rowData = [];
          for (let i = 0; i < visibleColumns.length; i++) {
            const column = visibleColumns[i];
            rowData.push(getDisplay(row, column));
          }
          let barcode = "";
          if (barcodeColumn) {
            barcode = getDisplay(row, barcodeColumn);
          }
          return {
            // aic vom objekt
            aic: row.aic_Stamm,
            // bezeichnung aus spalte...
            bezeichnung: row[columnBez],
            row,
            columns,
            favColumn,
            visibleColumns,
            percent,
            rowData,
            barcode,
          };
        });

        if (visibleColumns.length > 1 || favColumn) {
          mapped.unshift(headerItem);
        }
        this.rawlist = mapped;

        this.updateList();
        console.log(
          "loaded list async with " +
            this.rawlist.length +
            " items for " +
            this.description
        );

        this.enabled = !this.disabled && this.list.length > 0;
        if (this.column?.meta?.mobil && this.column?.meta?.readOnly) {
          this.enabled = false;
        }
        // erst hier darf ich die Werte inialisieren, denn erst jetzt ist
        // this.list befÃ¼llt!
        this.data = this.getInitValue();
        if (this.data) {
          const element = this.data;
          this.selected = element;
          this.selectedText = element.bezeichnung;
        }
        if (this.selectedItem) {
          this.selectedValueChanged(this.selectedItem, true);
        }
      } else {
        this.enabled = false; // wenn es keine Daten zur Auswahl gibt kann man das feld auf Disabled stellen..
        this.showSettings = false;
      }
      if (
        this.column &&
        this.column.meta?.Stt &&
        this.enabled &&
        !this.field.meta.noSubFom
      ) {
        const stt = this.field.meta.Stt;
        const begr = this.$globalsStt(Globals.Begriff, stt, "", true); // holt system Abfrage
        if (begr) {
          this.sysAbfrage = begr.kennung;
          if (this.field.meta.noSubFom) {
            this.showSettings = false;
          } else {
            this.showSettings = true;
          }
        }
      }
      if (this.barcodeButtonVisible) {
        this.anzahlCols = 10;
      } else if (
        this.showSettings &&
        (this.barcodeButtonVisible || this.useAutocomplete)
      ) {
        this.anzahlCols = 11;
      } else if (this.useAutocomplete || this.showSettings) {
        this.anzahlCols = 11;
      } else {
        this.anzahlCols = 12;
      }
    } catch (err) {
      console.log(err);
    }
    this.initialized = true;
    this.$emit("mountFinished", this.selected);
  }

  protected async scanBarcode() {
    const data = await scanBarcode();

    if (data.cancelled) {
      return;
    }
    if (data.content) {
      let txt = data.content;

      let compareVal: number | string = parseInt(txt, 10);
      if (isNaN(compareVal)) {
        compareVal = txt;
      }

      if (txt.indexOf("\n") >= 0) {
        txt = txt.split("\n")[0];
      }
      const foundItem = this.rawlist.find((item) => {
        let compareVal2: number | string = parseInt(item.barcode, 10);
        if (isNaN(compareVal2)) {
          compareVal2 = item.barcode;
        }

        return compareVal2 === compareVal;
      });
      if (foundItem) {
        this.selectedValueChanged(foundItem);
      } else {
        showSnackbar({
          text: this.$globalsBegriffMemo(
            Globals.Begriff,
            "WEB_Barcode_falsch",
            "Barcode falsch - bitte Ã¼berprÃ¼fen Sie diesen!"
          ),
          color: "error",
        });
      }
    }
  }

  public selectByAic(aic: number) {
    this.selectedValueChanged(aic);
  }

  protected selectedValueChanged(data: any, ausInit?: boolean) {
    const isMobile = Capacitor.getPlatform() !== "web";
    if (isMobile) {
      Keyboard.hide();
    }
    if (!this.list || this.list.length === 0) {
      return;
    }
    const comboVal = data; // .itemData;
    if (typeof comboVal === "number") {
      this.selected = this.list.find((x) => x.aic === comboVal);
    } else if (typeof comboVal === "string") {
      this.selected = this.list.find((x) => x.bezeichnung === comboVal);
    } else if (comboVal && comboVal.aic) {
      this.selected = comboVal;
    } else {
      this.selected = null;
    }
    const val = this.selected;
    if (
      val == null ||
      (val && val.aic && (this.fireOnText || typeof val.aic !== "string"))
    ) {
      let nurInitalisierung = false;
      if (this.noChange || ausInit === true) {
        nurInitalisierung = true;
      }
      this.valueChanged(val, nurInitalisierung); // zum event triggern
    }
  }

  protected getDefault() {
    if (this.list && this.list.length > 1 && this.list[0].headerHtml) {
      return this.list[1];
    }
    if (this.list && this.list.length > 0) {
      return this.list[0];
    }
    return null;
  }

  public validate() {
    try {
      if (!this.enabled) {
        this.errorText = "";
        return true;
      } // disabled Controls sind IMMER valide ;)
      if (!this.initialized) {
        // Liste leer also nicht initialisiert...
        return false; // soll eine noch nicht initialisierte Combobox valide sein ?
      }
      if (this.required) {
        if (this.selected && this.selected.aic) {
          this.errorText = "";
          return true;
        }
        this.errorText = this.requiredMessage;
        return false;
      }
      this.errorText = "";
      return true;
    } catch (err) {
      console.error(err);
    }
    return false;
  }
  public getInitValue(): any {
    if (this.column && this.column.meta) {
      if (this.column.meta.neuArt === "Limit" || this.column.meta.neuArt === "Last") {
        return this.getInitValueBase();
      }
    }
    return this.getInitValueBase();
  }
  protected openSettings() {
    const siteName = this.column.title;
    if (Constants.BottomMenuVisible || this.openFromDialog) {
      showDialog({
        title: siteName,
        showTitle: true,
        site: "EditStamm",
        data: {
          query: this.sysAbfrage,
          aic: this.selected.aic,
          showClose: false,
        },
        fullscreen: true,
        transition: "dialog-bottom-transition",
        onClose: () => {
          return true;
        },
      });
    } else {
      onShowSite({
        title: siteName,
        site: "EditStamm",
        data: {
          query: this.sysAbfrage,
          aic: this.selected.aic,
          showClose: false,
        },
        callOnLeave: () => {
          return true;
        },
      });
    }
  }

  protected changeFav(data: any) {
    this.showLoading = true;
    const me = this;
    this.$api
      .setFavorit(data.aic, data.val > 0, null)
      .then(() => {
        me.showLoading = false;
        this.$emit("onReloadData");
      })
      .catch((ex: any) => {
        me.showLoading = false;
      })
      .finally(() => me.updateList());
  }
}
