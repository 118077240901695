

















































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { Globals } from "@/Globals";
import { getKennungValueDisplay, getKennungTitle } from "@/DataHelper";
import { showSnackbar } from "@/UIHelper";

@Component
export default class Edit extends EditPage {
  constructor() {
    super();
    this.query = "Web_NewProjektstunden";
    this.showLoading = true;
  }
  public disabled: boolean = false;
  public clearable: boolean = false;
  private showVerbAuftrag: boolean = false;
  private showTaetigkeit: boolean = false;
  private showDeleteMask: boolean = false;
  private stdVorschlag: number = 0;
  private stdAktuell: number = 0;
  private auftrag: string = "";
  private taetigkeit: string = "";
  private kst: string = "";
  private endAuftrag: string = "";
  private calcToday: boolean = false;
  private isApiBusy: boolean = false;

  public mounted() {
    this.mountedBase();
    if (this.siteData.showDelete) {
      this.showDeleteMask = this.siteData.showDelete;
    }
    if (this.checkBegriff("WEB_heute_Berechnen")) {
      this.calcToday = true;
    }
  }
  protected initialize() {
    if (this.siteData.stdUebergabe) {
      this.stdVorschlag = this.stdAktuell = this.siteData.stdUebergabe;
    }
    if (this.bewegungsdaten) {
      this.auftrag = getKennungValueDisplay(this.data, "INSTRUCTION", this.columns);
      this.taetigkeit = getKennungValueDisplay(this.data, "ACTIVITY", this.columns);
      this.kst = getKennungValueDisplay(this.data, "COST_CENTRE", this.columns);
    }

    const auftragTitle = getKennungTitle("ENDKUNDENAUFTRAG", this.columns);
    if (auftragTitle) {
      this.showVerbAuftrag = true;
    }
    const taetigkeitTitle = getKennungTitle("ACTIVITY", this.columns);
    if (taetigkeitTitle) {
      this.showTaetigkeit = true;
    }
    const me = this;
    if (!this.showDeleteMask) {
      setTimeout(() => {
        me.clearAndFocus();
      }, 200);
    }
  }
  private clearAndFocus() {
    let timepicker: any = this.$refs.timeSpanPicker;
    if (timepicker) {
      if (timepicker.length && timepicker.length > 0) {
        timepicker = timepicker[0];
      }
    }
    timepicker.clearAndFocus();
    // es kommt immer ein Error
  }
  private bewSave() {
    if ((this.auftrag || this.kst) && this.stdAktuell) {
      const d = new Date();
      const datumHeute = new Date(d.setHours(0, 0, 0, 0));
      if (this.zeitbereich) {
        const vonZeitZone = this.zeitbereich.von;
        const von = new Date(vonZeitZone.setHours(0, 0, 0, 0));
        // ab Version 2.02 soll auch auf Modul - heute berechnen - geprÃ¼ft werden
        if (this.calcToday && datumHeute.valueOf() === von.valueOf()) {
          this.onSave(0, "Web_Zeit_Zulagenbuchung");
        } else if (datumHeute.valueOf() !== von.valueOf()) {
          this.onSave(0, "Web_Zeit_Zulagenbuchung");
        } else {
          this.onSave(0, "");
        }
      } else {
        this.onSave(0, "");
      }
    } else {
      const meldung = this.$globalsBegriffMemo(
        this.Globals.Begriff,
        "WEB_SpeichernNichtMoeglich",
        "Speichern kann nicht durchgefÃ¼hrt werden da Daten leer sind"
      );

      showSnackbar({
        text: meldung,
        color: "warning",
      });
    }
  }
  public bewDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      const d = new Date();
      const datumHeute = new Date(d.setHours(0, 0, 0, 0));
      if (this.zeitbereich) {
        const vonZeitZone = this.zeitbereich.von;
        const von = new Date(vonZeitZone.setHours(0, 0, 0, 0));
        if (this.calcToday && datumHeute.valueOf() === von.valueOf()) {
          this.onSave(0, "Web_Zeit_Zulagenbuchung");
        } else if (datumHeute.valueOf() !== von.valueOf()) {
          this.onDelete(
            this.data.aic_Bew_pool,
            "Web_Zeit_Zulagenbuchung",
            this.zeitbereich
          );
        } else {
          this.onDelete(this.data.aic_Bew_pool, "", this.zeitbereich);
        }
      }
      this.onDelete(this.data.aic_Bew_pool, "Web_Zeit_Zulagenbuchung", this.zeitbereich);
    }
    // wegschalten damit das refresh dann sauber greift!
    this.$emit("back");
  }
}
