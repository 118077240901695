export const deStrings = {
    dropdowns: {
        noRecordsTemplate: "Keine Daten gefunden",
        actionFailureTemplate: "Daten konnten nicht geladen werden"
    },
    daterangepicker: {
        placeholder: "WÃ¤hlen Sie einen Bereich aus",
        startLabel: "WÃ¤hlen Sie Startdatum",
        endLabel: "WÃ¤hlen Sie Enddatum",
        applyText: "Ãbernehmen",
        cancelText: "Abbruch",
        selectedDays: "AusgewÃ¤hlte Tage",
        days: "Tage",
        thisWeek: "aktuelle Woche",
        thisMonth: "aktueller Monat",
        lastMonth_3: "letzter Monat",
        lastYear_4: "letztes Jahr",
        customRange: "benutzerdefiniert",
        today: "heute"
    },
    datepicker: {
        placeholder: "WÃ¤hlen Sie ein Datum aus",
        today: "heute"
    },
    grid: {
        EmptyRecord: "Keine Aufzeichnungen angezeigt",
        GroupDropArea: "Ziehen Sie einen Spaltenkopf hier, um die Spalte zu gruppieren",
        UnGroup: "Klicken Sie hier, um die Gruppierung aufheben",
        EmptyDataSourceError: "kein Daten gefunden",
        Item: "Artikel",
        Items: "Artikel",
        Add: "Neu",
        Delete: "LÃ¶schen",
        Update: "Speichern",
        CancelButton: "Abbruch",
        OkButton: "Ok",
        Edit: "Bearbeiten",
        Print: "Drucken",
        Columns: "Spalten",
        ChooseColumns: "WÃ¤hlen Sie",
        SelectAll: "Alle",
        Search: "Suche",
        ClearFilter: "Filter entfernen"
    },
    pager: {
        currentPageInfo: "{0} von {1} Seiten",
        totalItemsInfo: "({0} BeitrÃ¤ge)",
        firstPageTooltip: "Zur ersten Seite",
        lastPageTooltip: "Zur letzten Seite",
        nextPageTooltip: "Zur nÃ¤chsten Seite",
        previousPageTooltip: "ZurÃ¼ck zur letzten Seite",
        nextPagerTooltip: "Zum nÃ¤chsten Pager",
        previousPagerTooltip: "Zum vorherigen Pager"
    },
    schedule: {
        day: "Tag",
        week: "Woche",
        workWeek: "Arbeitswoche",
        timelineWeek: "TimeLine Week",
        month: "Monat",
        agenda: "Agenda",
        weekAgenda: "Wochen Agenda",
        workWeekAgenda: "Arbeitswoche - Agenda",
        monthAgenda: "Monats -  Agenda",
        today: "heute",
        noEvents: "keine Daten",
        emptyContainer: "Keine Daten gefunden.",
        allDay: "ganzer Tag",
        start: "Start",
        end: "Ende",
        more: "mehr",
        close: "Schliessen",
        cancel: "Abbruch",
        noTitle: "(kein Titel)",
        delete: "LÃ¶schen",
        deleteEvent: "LÃ¶sche Eintrag",
        deleteMultipleEvent: "LÃ¶sche mehrere EintrÃ¤ge",
        selectedItems: "gewÃ¤hltes Objekt",
        deleteSeries: "LÃ¶sche Serie",
        edit: "Bearbeiten",
        editSeries: "Serie bearbeiten",
        editEvent: "bearbeiten",
        createEvent: "neu",
        subject: "Betreff",
        addTitle: "neuer Titel",
        moreDetails: "Details",
        save: "Speichern",
        editContent: "MÃ¶chten Sie nur dieses Ereignis bearbeiten oder die ganze Serie?",
        deleteRecurrenceContent:
            "MÃ¶chten Sie nur dieses Ereignis lÃ¶schen oder die ganze Serie?",
        deleteContent: "Sind Sie sicher, dass Sie dieses Ereignis lÃ¶schen mÃ¶chten?",
        deleteMultipleContent:
            "Sind Sie sicher, dass Sie die ausgewÃ¤hlten Ereignisse lÃ¶schen mÃ¶chten?",
        newEvent: "Neues Ereignis",
        title: "Titel",
        saveButton: "Speichern",
        cancelButton: "Abbruch",
        deleteButton: "LÃ¶schen"
    }
};
