var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"col-md-3"},[_c('CommonVCardDialog',{staticClass:"ma-2 pa-0",staticStyle:{"min-width":"300px"},attrs:{"textQuery":"Web_Periodensaldo","text":_vm.$globalsBezeichnung(
        _vm.Globals.Begriff,
        'Web_aktSaldo',
        'akt. Periodensaldo'
      ),"textKennung":"BERECHNUNG_MASS","zeitbereich":_vm.zeitbereich}}),_c('CommonVCardDialog',{staticClass:"ma-2 pa-0",staticStyle:{"min-width":"300px"},attrs:{"textQuery":"ZE_Gesamtsaldo_Neu","text":_vm.$globalsBezeichnung(
        _vm.Globals.Begriff,
        'Web_Gesamtsaldo',
        'akt. Gesamtsaldo'
      ),"textKennung":"BERECHNUNG_MASS","zeitbereich":_vm.zeitbereich}}),_c('CommonVCardDialog',{staticClass:"ma-2 pa-0",staticStyle:{"min-width":"300px"},attrs:{"query":"","textQuery":"ZE_Alle_Anspruche__In__Tagen","text":_vm.$globalsBezeichnung(_vm.Globals.Begriff, 'Web_Text_Anspruch', 'AnsprÃ¼che'),"textKennung":"Tage","text1Kennung":"Abgearbeitet","text1":_vm.$globalsBezeichnung(_vm.Globals.Begriff, 'Web_Stichtag', 'Stichtag'),"zeitbereich":_vm.zeitbereichUebergabe}}),_c('v-card',{staticClass:"ma-2 pa-0",staticStyle:{"min-width":"300px"},attrs:{"pa-5":"","ma-5":"","xs12":""}},[_c('v-card-title',{staticClass:"caption success--text pb-0"},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "Web_Berechnung_Resturlaub", "Berechnung Resturlaub per Stichtag" ))+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[(_vm.showTimeField)?_c('EditDatePicker',{ref:"datePicker",staticClass:"e-field",attrs:{"onFocus":true,"placeholder":_vm.$globalsBezeichnung(_vm.Globals.Begriff, 'Web_Stichtag', 'Stichtag'),"inputDate":_vm.Stichtag,"title":_vm.$globalsBezeichnung(_vm.Globals.Begriff, 'Web_Stichtag', 'Stichtag')},on:{"input":_vm.timeChanged},model:{value:(_vm.Stichtag),callback:function ($$v) {_vm.Stichtag=$$v},expression:"Stichtag"}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{staticClass:"pl-10",attrs:{"cols":"12","sm":"6"}},[_c('CalcButton',{staticClass:"calcButton",attrs:{"disabled":_vm.timeInvalid,"modell":"Web_Resturlaub_per_Stichtag","color":"blue","icon":"play_circle_outline","variable1":_vm.Stichtag,"varDatum":"WebStichtag"},on:{"calculating":_vm.onCalculating,"calculated":_vm.onCalculated}})],1)],1),(_vm.resturlaub)?_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(" "+_vm._s(_vm.$begriffBezeichnung("Web_Text_Anspruch"))+" "+_vm._s(_vm.resturlaub)+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }