



































import { Component } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";

@Component
export default class EditCheckBox3 extends EditComponent {
  protected disabledText: string = "";
  private currentVal: any = null;
  private text1: string = "offen";
  private text2: string = "bewilligt";
  private text3: string = "abgelehnt";

  private value1: string = "";
  private value2: string = "Ja";
  private value3: string = "Nein";

  private aic1: string = "";
  private aic2: string = "";
  private aic3: string = "";
  private error: boolean = false;

  public mounted() {
    this.mountedBase();
    let val = this.getInitValue();
    if (
      this.column &&
      this.column.meta &&
      this.column.meta.combo &&
      this.column.meta.combo.length === 3
    ) {
      const combo = this.column.meta.combo;
      this.text1 = combo[0].bezeichnung;
      this.text2 = combo[1].bezeichnung;
      this.text3 = combo[2].bezeichnung;

      this.value1 = combo[0].nr;
      this.value2 = combo[1].nr;
      this.value3 = combo[2].nr;

      this.aic1 = combo[0].aic;
      this.aic2 = combo[1].aic;
      this.aic3 = combo[2].aic;

      // remove wenn server aics liefert ;)
      if (val) {
        if (val !== this.aic1 && val !== this.aic2 && val !== this.aic3) {
          val = combo[val].aic;
        }
      }
    }

    this.currentVal = val ?? this.column.meta.combo[0].aic;

    if (!this.enabled) {
      this.disabledText = this.disabledValue();
    }
  }

  private checkChanged(val: any) {
    console.log("radio changed: " + val);
    this.currentVal = val;
    this.valueChanged(val);
  }

  protected disabledValue(): string {
    // oder welches Mapping wir da spÃ¤ter auch brauchen

    const element = this.column.meta.combo.find((f: any) => f.aic === this.currentVal);
    if (element) {
      return element.bezeichnung;
    } else {
      return this.column.meta.combo[this.currentVal].bezeichnung;
    }

    return " -- ";
  }
  public validate() {
    if (this.disabled) {
      this.errorText = "";
      this.error = true;
      return true;
    } // disabled Controls sind IMMER valide ;)
    if (this.required) {
      if (this.currentVal) {
        this.errorText = "";
        this.error = true;
        return true;
      }
      this.errorText = this.requiredMessage;
      this.error = true;
      return false;
    }
    this.errorText = "";
    return true;
  }
}
