













































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import VCardDialog from "@/components/common/VCardDialog.vue";

@Component
export default class SoftTerm extends EditPage {
  // @Prop({ default: null }) protected zeitbereich!: APIZeitbereich | null;
  constructor() {
    super();
  }
  // private anzeige: boolean = true;
  // private letzteBuchung: string = "---";
  // private letzteBuchungUm: string = "---";
  private memo: string = "";
  private begriff: any = null;
  private absolute: boolean = true;

  public mounted() {
    // if (!this.checkBegriff("Stempelungen") && !this.checkBegriff("Info")) {
    //   alert("Folder Info - steht nicht zur VerfÃ¼gung!");
    //   // this.$root.$emit("doLogout");
    // }
  }

  private titleTime: string = "Software Terminal";
  private modell: string | null = null;
  private dialog: boolean = false;
  private dialog1: boolean = false;
  private dialog2: boolean = false;
  private dialog3: boolean = false;
  private expand: boolean = false;
}
