import { Globals } from "@/Globals";

let app: Vue;
let isNavigating: boolean = false;
let activeBtn: number = 0;
const showZeitRaumInHeader: boolean = false;
let rootView: NavStackEntry = {
  site: "AllWelcome",
  title: "Willkommen",
  data: null
};

interface UIInterface {
  refreshZeitRaumInHeader: () => void;
  refreshFullPageContent: () => void;
  getActiveViewIndex: () => number;
  setActiveViewIndex: (index: number) => number;
  setBottomActiveButton: (activeBtn: number) => void;
}


interface NavStackEntry {
  site: string;
  title?: string;
  aic?: number;
  name?: string;
  data?: any;
  callOnLeave?: (() => void) | null;
}

interface NavStackEntryRequest {
  replaceCurrent?: boolean;
  site: string;
  title?: string;
  aic?: number;
  name?: string;
  data?: any;
  callOnLeave?: (() => void) | null;
}

let contentStack: NavStackEntry[] = [];
let UI: UIInterface;
export function initNavigationHelper(newApp: Vue, newContentStack: NavStackEntry[], ui: UIInterface) {
  app = newApp;
  UI = ui;
  contentStack = newContentStack;
  // ausleeren und neu beginnen
  contentStack.length = 0;
  contentStack.push({
    site: "AllWelcome",
    title: app.$globalsBezeichnung(
      Globals.Begriff,
      "Web_Willkommen",
      "Willkommen"
    )
  });
}

export function setRootView(newRoot: NavStackEntry) {
  rootView = newRoot;
  if (rootView.name) {
    rootView.title = app.$begriffBezeichnung(rootView.name);
  }
  if (newRoot.title === "Dashboard") {
    setBottomActiveButton(1);
  }
  contentStack.length = 0;
  contentStack.push(rootView);
  UI.refreshZeitRaumInHeader();
  // refreshZeitraumInHeader muss aufgerufen werden,
  // damit showZeitraumInHeaer richtig gesetzt wird!
}


export function pushSite(
  site: string,
  data: any,
  title: string | null = null,
  aic: any,
  callOnLeave: (() => void) | null = null
) {
  if (!title) {
    title = site.replace(/^(All|Base)/, "");
  }
  try {
    console.log("showing site:" + site + " with title " + title);

    const navOpt = { site, data, title, aic, callOnLeave };
    const navState = { site, data, title, aic };

    if (UI.getActiveViewIndex() === 0 && contentStack[0].site === site) {
      return;
    }

    isNavigating = true;
    contentStack.push(navOpt);
    UI.setActiveViewIndex(contentStack.length - 1);
    setBottomActiveButton(1);
    window.history.pushState(navState, title, site);
    isNavigating = false;
    UI.refreshZeitRaumInHeader();
  } catch (ex) {
    console.error("pushSite", site + ex);
  }
}

export function onShowSite(parameter: NavStackEntryRequest) {
  if (parameter.replaceCurrent) {
    goBack();
  }
  app.$nextTick(() =>
    pushSite(
      parameter.site as string,
      parameter.data,
      parameter.title,
      parameter.aic,
      parameter.callOnLeave
    )
  );
}


export function showSite(site: string, data: any, title: string, aic?: number) {
  if (contentStack.length > 1) {
    goBackTo(contentStack[0], 0);
  }

  setTimeout(() => {
    pushSite(site, data, title, aic);
  }, 100);
  return window;
}
export function goBackTo(item: any, i: number = 0) {
  isNavigating = true;
  for (let x = contentStack.length - 1; x >= 0; x--) {
    if (contentStack[x].site !== item.site) {
      UI.setActiveViewIndex(x);
      const currentPage = contentStack.pop();
      if (currentPage?.callOnLeave) {
        currentPage.callOnLeave();
      }
      window.history.back();
    } else {
      break;
    }
  }
  UI.refreshZeitRaumInHeader();
  isNavigating = false;
  // refreshZeitRaumInHeader();
}

export function refreshFullPageContent() {
  UI.refreshFullPageContent();
}

export function goBack() {
  isNavigating = true;
  if (contentStack.length > 1) {
    UI.setActiveViewIndex(Math.max(0, contentStack.length - 2));
    const currentPage = contentStack.pop();
    if (currentPage?.callOnLeave) {
      currentPage.callOnLeave();
    }
  }
  isNavigating = false;
  UI.refreshZeitRaumInHeader();
}

export function goToState(location: string, state: any) {
  if (isNavigating) {
    return;
  }
  // hier is der state aber die page die wir ansurfen...
  console.log(
    "window changed to " + location + " state " + JSON.stringify(state)
  );
  if (state && state.site) {
    for (let i = contentStack.length - 1; i >= 0; i--) {
      if (UI.getActiveViewIndex() === 0) { break; }
      if (contentStack[i].site !== state.site) {
        UI.setActiveViewIndex(i - 1);
        contentStack.pop();
      } else {
        // wir sind fertig ;)
        break;
      }
    }
  }
  if (contentStack.length <= 0) {
    // wird ausserhalb der Webapplikation gehÃ¼pft - bleibt er im ROOT!
    // if (confirm("Es wurde keine Seite gefunden! wollen Sie sich abmelden?")) {
    //   handleLogout();
    // } else {
    setBottomActiveButton(0);
    isNavigating = true;
    contentStack.push(rootView);
    UI.setActiveViewIndex(contentStack.length - 1);
    window.history.pushState(
      rootView,
      rootView.title ?? rootView.site,
      rootView.site
    );
    isNavigating = false;
    // }
  }
  UI.refreshZeitRaumInHeader();
}

function setBottomActiveButton(btn: number) {
  if (activeBtn !== btn) {
    activeBtn = btn;
    UI.setBottomActiveButton(btn);
  }
}

