var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('v-card',{staticClass:"mt-2 pa-0",staticStyle:{"width":"430px"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('PzeWfVorgesetzterFreigabenTemplateMaSaldenAntrag',{attrs:{"zeitbereich":this.siteData.zeitbereich,"aic":this.siteData.aic},on:{"back":function($event){return _vm.$emit('back')}}})],1)],1),_c('v-card',{staticClass:"mt-2 pa-0",staticStyle:{"min-width":"430px"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{staticClass:"pa-0"},[_c('v-expansion-panel-header',{staticClass:"subtitle-1 lighten-2 white--text dvhBackground",attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v("$expand")])]},proxy:true}])},[_c('span',[_vm._v("Salden")])]),_c('v-expansion-panel-content',{staticClass:"pa-0"},[_c('CommonVCardDialog',{staticClass:"mt-2 pa-0",staticStyle:{"min-width":"400px"},attrs:{"textQuery":"Web_Periodensaldo","text":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'Web_aktSaldo',
                  'akt. Periodensaldo'
                ),"textKennung":"BERECHNUNG_MASS","zeitbereich":_vm.zeitbereich,"aic":this.siteData.aic}}),_c('CommonVCardDialog',{staticClass:"mt-2 pa-0",staticStyle:{"min-width":"400px"},attrs:{"textQuery":"ZE_Gesamtsaldo_Neu","text":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'Web_Gesamtsaldo',
                  'akt. Gesamtsaldo'
                ),"textKennung":"BERECHNUNG_MASS","zeitbereich":_vm.zeitbereich,"aic":this.siteData.aic}}),_c('CommonVCardDialog',{staticClass:"mt-2 pa-0",staticStyle:{"min-width":"400px"},attrs:{"query":"","textQuery":"ZE_Alle_Anspruche__In__Tagen","text":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'Web_Text_Anspruch',
                  'AnsprÃ¼che'
                ),"textKennung":"Tage","text1Kennung":"Abgearbeitet","text1":_vm.$globalsBezeichnung(_vm.Globals.Begriff, 'Web_Stichtag', 'Stichtag'),"zeitbereich":_vm.zeitbereichUrlaub,"aic":this.siteData.aic}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }