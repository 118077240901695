





import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import BaseSyncGrid from "./BaseSyncGrid.vue";

@Component
export default class GridAggregateCell extends Vue {
  private text: string = "";
  private data!: any;
  private column!: any;
  private display: string = "";

  public mounted() {
    this.text = this.data.Custom;
    this.display = this.column.meta.ergebnisArt;
    if (this.display === "sum") {
      this.display = "Î£";
    }
    if (this.display === "min") {
      this.display = "â²";
    }
    if (this.display === "max") {
      this.display = "â¼";
    }
    if (this.display === "last") {
      this.display = "â§";
    }

    this.text = this.display + " " + this.text;
  }
}
