





























































































































































































































import { Component, Prop } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getWeekdayNamesShort, getMonthNames } from "@/editor/CalenderHelper";
import {
  getKZValue,
  getKZValueDisplay,
  getKZTitle,
  getKennungValue,
  getKZColumn,
  getKennungTitle,
} from "@/DataHelper";

@Component
export default class DialogDienstplanVG extends EditPage {
  public allColumns: DataColumn[] = [];
  private saveVisible: boolean = true;
  private showGanztag: boolean = false;
  private showAbwesenheit: boolean = false;
  private disableSwitch: boolean = false;
  private value1?: Date;
  private value2?: Date;
  private value3?: any;
  private value4?: any;
  private memo?: string;
  private tag!: number;
  private wochenTag: string = "";
  private monat: string = "-";
  private showVB3: boolean = false;
  private showVB4: boolean = false;
  private showAuftrag1: boolean = false;
  private showAuftrag2: boolean = false;
  private showAuftrag3: boolean = false;
  private showAuftrag4: boolean = false;
  constructor() {
    super();
    this.query = "Web_TE_TGM_Editierbar";
    this.showLoading = true;
  }
  public created() {
    if (this.siteData.abwesenheit) {
      this.showAbwesenheit = true;
    }
    if (this.siteData.selectedTGM) {
      this.selected = this.siteData.selectedTGM;
    }
    if (this.siteData.readOnlyMutable) {
      this.saveVisible = false;
      this.showGanztag = false;
    }
    if (this.siteData.tag) {
      this.zeitbereich = {
        von: this.siteData.tag,
        bis: this.siteData.tag,
        bereich: "Tag",
      };
    } else {
      if (this.siteData.zeitbereich?.von) {
        const von = this.siteData.zeitbereich.von;
        this.tag = von.getDate();
        const days = getWeekdayNamesShort();
        this.wochenTag = days[von.getDay()];
        this.monat = getMonthNames()[von.getMonth()];
      }
    }
  }
  protected initialize() {
    const vb3 = getKennungTitle("vb3", this.columns);
    if (vb3) {
      this.showVB3 = true;
    }
    const vb4 = getKennungTitle("vb4", this.columns);
    if (vb4) {
      this.showVB4 = true;
    }
    const auftrag1 = getKennungTitle("ZE_DIENSTPLAN_AUFTRAG_1", this.columns);
    if (auftrag1) {
      this.showAuftrag1 = true;
    }
    const auftrag2 = getKennungTitle("ZE_DIENSTPLAN_AUFTRAG_2", this.columns);
    if (auftrag2) {
      this.showAuftrag2 = true;
    }
    const auftrag3 = getKennungTitle("ZE_DIENSTPLAN_AUFTRAG_3", this.columns);
    if (auftrag3) {
      this.showAuftrag3 = true;
    }
    const auftrag4 = getKennungTitle("ZE_DIENSTPLAN_AUFTRAG_4", this.columns);
    if (auftrag4) {
      this.showAuftrag4 = true;
    }
  }
  public get showDelete() {
    return (
      this.data &&
      this.data.aic_Bew_pool &&
      this.data.aic_Bew_pool > 0 &&
      !this.siteData.readOnlyMutable
    );
  }
  public save() {
    const me = this;
    this.onSave(this.data, "")?.then(() => {
      this.$nextTick(() => me.$emit("save"));
    });

    // bringt beim DP nix, weil ich auch die MA Liste wegen den SaldoStÃ¤nden neu laden muss...
    // // get Data aktiviert -damit ich das gespeicherte zurÃ¼ckbekomme...
    // this.onSave(this.data, "", this.zeitbereich, 0, true)?.then(() => {
    //   this.$nextTick(() => me.$emit("save"));
    // });
  }
  public onDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.$emit("delete", this.data.aic_Bew_pool);
    }
  }

  private setVonBis(selected: any) {
    if (selected) {
      this.initialized = false;
      const kennung = "vb";
      const col = this.columns.find((c: any) => c.kennung === kennung);
      const val = getKennungValue(selected.row, kennung, selected.columns);
      this.value1 = val;
      if (col) {
        this.data[col.name] = this.value1;
      }
      const kennung1 = "vb1";
      const col1 = this.columns.find((c: any) => c.kennung === kennung1);
      const val1 = getKennungValue(selected.row, kennung1, selected.columns);
      this.value2 = val1;
      if (col1) {
        this.data[col1.name] = this.value2;
      }
      // 3 & 4 muss ich bei Wechsel auch leer stellen da das nicht initialisiert werden kann!
      const val2 = { IsAllDay: false, dauer: 0 };
      this.value3 = val2;
      this.value4 = val2;
      const memoValue = getKennungValue(this.data, "MEMO", this.columns);
      if (!memoValue || this.data.aic_Bew_pool === 0) {
        const valMemo = getKennungValue(
          selected.row,
          "ZE_MEMO_SCHICHTPLAN",
          selected.columns
        );
        this.memo = valMemo;
        const kennung2 = "MEMO";
        const col2 = this.columns.find((c: any) => c.kennung === kennung2);
        if (col2) {
          this.data[col2.name] = this.memo;
        }
      }
      this.$emit("setTGM", selected);
      this.$nextTick(() => (this.initialized = true));
      this.$forceUpdate();
    }
  }
}
