



















































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { Globals } from "@/Globals";
import Constants from "@/Constants";
import { getValue, getKZValueDisplay } from "@/DataHelper";
import CalcHelper from "@/components/CalcHelper";
import { showSnackbar } from "@/UIHelper";
import AUApi from "@/api";
@Component
export default class CalcButton extends CalcHelper {
  // @Prop() public zeitbereich!: APIZeitbereich | null;
  // @Prop() protected aic!: number;
  @Prop() public modell!: string;
  @Prop() public icon!: string;
  @Prop() public color!: string;
  @Prop() public block!: string;
  @Prop() public small!: boolean;
  @Prop() public smallIcon!: boolean;
  @Prop() public fab!: string;
  @Prop() public height!: string;
  @Prop() public width!: string;
  @Prop() public plain!: boolean;
  @Prop() public modellAic!: number;
  @Prop() public showDisabled!: boolean;
  @Prop() public bewAic!: boolean;
  @Prop() public vecStamm!: any[];
  @Prop() public vectorSelect!: any[];
  @Prop({ default: false }) public useVecStamm!: boolean;
  // @Prop() public text!: string;
  @Prop({ default: true }) public dark!: boolean;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: false }) public showResult!: boolean;
  // private colorText!: string;
  private showDef: boolean = false;

  private dialog: boolean = false;
  private queryAIC: any = undefined;
  private data: DataColumn[] = [];
  private columns: DataColumn[] = [];
  private isApiBusy: boolean = false;
  public mounted() {
    let vecStamm: any[] = [];
    if (this.vectorSelect && this.vectorSelect.length > 0) {
      this.vectorSelect.forEach((element) => {
        vecStamm.push(element.aic_Bew_pool);
      });
      this.vecStamm = vecStamm;
    } else {
      vecStamm = this.vecStamm;
    }

    if (this.modell) {
      this.begriffModell = this.$globalsKennung(Globals.Begriff, this.modell);
    }
    // ist der aktuelle user Def Berechtigt, darf er Debug und Abbruch setzen!
    if (this.$api.user.rechte === "Def") {
      this.showDef = true;
    }
    if (this.modellAic) {
      this.aic = this.modellAic;
    }
    // if (this.begriffModell?.parameter === "1" && !Constants.isMobile) {
    //   // es gibt keinen Parameter?? leide weiÃ ich nicht wozu das war?
    //   this.begriffModell = null;
    // }
    // wenn es eine Abgfrage OHNE Parameter MDialog gibt
    // dann geht es um eine Anzeige auf dem Button - zB Ampeln
    if (
      this.begriffModell !== null &&
      this.begriffModell.abfrage !== undefined &&
      !this.begriffModell.MDialog
    ) {
      const abfrageAic = this.begriffModell.abfrage;
      // console.log("calcButton asking for " + abfrageAic + ' zb: ' + JSON.stringify(this.zeitbereich));
      const me = this;
      if (abfrageAic) {
        if (this.useVecStamm && this.vecStamm && this.vecStamm.length === 0) {
          showSnackbar({
            text:
              "ACHTUNG: es muss mind. 1 Mitarbeiter ausgewÃ¤hlt sein! Die Berechnung wird abgebrochen",
            color: "error",
          });
          return;

          this.$emit("back");
        }
        this.$api.getQuery(
          abfrageAic,
          (data: any) => me.dataLoaded(data),
          this.queryAIC,
          this.zeitbereich,
          0,
          false,
          false,
          "",
          0,
          vecStamm
        );
      }
    }
  }
  private dataLoaded(data: any) {
    if (data.data.error !== undefined) {
      this.$api.onfail(data.data.error);
      console.log(data.data.error);
      alert("ACHTUNG: " + data.data.error);
      this.$emit("back");
      return;
    }

    this.columns = data.data.columns;
    this.data = data.data.data;
    if (this.data.length > 0) {
      const row = this.data[0];
      const ampelIcon = getKZValueDisplay(row, "icon", this.columns);

      if (ampelIcon) {
        this.iconAmpel = ampelIcon;
      }
    }
  }
  public created() {
    this.$root.$on("isApiBusy", (x: boolean) => {
      this.isApiBusy = x;
      this.$forceUpdate();
    });
  }
  private onCalcFunction() {
    if (this.disabled) {
      // Meldung?
    } else if (this.useVecStamm && this.vecStamm && this.vecStamm.length === 0) {
      showSnackbar({
        text:
          "ACHTUNG: es muss mind. 1 Mitarbeiter ausgewÃ¤hlt sein! Die Berechnung wird abgebrochen",
        color: "error",
      });
      return;
      this.$emit("back");
    } else {
      if (this.modellAic) {
        this.aic = this.modellAic;
      }
      this.calcFunction(this.modell, this.aic, false, this.vecStamm, this.bewAic);
    }
  }

  private onCalculated(ampel: any) {
    if (ampel) {
      this.iconAmpel = ampel;
    }
  }

  private onRefresh() {
    const comp = this.$refs.contentComponent as any;
    if (comp.onRefresh) {
      comp.onRefresh();
    }
  }

  private get currentColor() {
    if (this.disabled || this.isApiBusy) {
      return "#aaa";
    }
    return this.color;
  }
}
