<template>
  <div class="kanban-board">
    <div class="kanban-column" v-for="(column, columnIndex) in board" :key="columnIndex">
      <h3>{{ column.name }}</h3>
      <vue-dndrop
        :list="column.tasks"
        :external-source="true"
        :drop-effect="'move'"
        @drop="onDrop($event, columnIndex)"
      >
        <template v-slot="{ element }">
          <div class="task-card" :key="element.id">
            {{ element.name }}
          </div>
        </template>
      </vue-dndrop>
    </div>
  </div>
</template>

<script>
import VueDndrop from "vue-dndrop";

export default {
  components: {
    VueDndrop,
  },
  data() {
    return {
      board: [
        {
          name: "To Do",
          tasks: [
            { id: 1, name: "Task 1" },
            { id: 2, name: "Task 2" },
            { id: 3, name: "Task 3" },
          ],
        },
        {
          name: "In Progress",
          tasks: [
            { id: 4, name: "Task 4" },
            { id: 5, name: "Task 5" },
          ],
        },
        {
          name: "Done",
          tasks: [{ id: 6, name: "Task 6" }],
        },
      ],
    };
  },
  methods: {
    onDrop(event, columnIndex) {
      const { element, oldIndex } = event;
      const oldColumn = this.board.find((column) => column.tasks.includes(element));
      const oldColumnIndex = this.board.indexOf(oldColumn);

      // Remove the task from the old column
      oldColumn.tasks.splice(oldIndex, 1);

      // Add the task to the new column
      this.board[columnIndex].tasks.push(element);
    },
  },
};
</script>

<style scoped>
.kanban-board {
  display: flex;
  gap: 20px;
}

.kanban-column {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  width: 30%;
  min-height: 400px;
}

.task-card {
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}
</style>
