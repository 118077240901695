















































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import BaseTreeView from "../BaseHsTreeView.vue";
import CreateList from "@/components/CreateList";
declare interface HsSubAbfragen {
  aicAbf: number;
  aicEig: number;
  kennung: string;
  aicStt: number;
  aicSttVon: number;
  art: string;
}
@Component
export default class HSTreeView extends Vue {
  @Prop({ default: null }) public zeitbereich!: APIZeitbereich | null;
  private selectedAic: number | null = null;
  private selectedBezeichnung: string = "";
  private selectedQuery: string = "";

  public aic!: number; // muss der markierte Satz sein
  private hsItems: HsListEntry[] = [];
  public hsSubAbfragen: HsSubAbfragen[] = [];
  public hsStandardFormular: HsSubAbfragen[] = [];
  public druckItems!: PrintItems[];
  private hsAic!: number;

  private openAll: boolean = false;

  private componentKey: number = 0;
  private initalized: boolean = false;
  private title: string = "... Daten laden";
  public showZeitraumInHeader: boolean = true;
  private showLoading: boolean = false;
  private showSelectAustritt: boolean = false;
  @Prop({ default: null }) public siteData: any;

  public mounted() {
    this.reloadData();
  }

  private selected(c: any[], subAbfrage: any) {
    // das treeview gibt ggf mehrere zurÃ¼ck ( is fÃ¼r spÃ¤ter vielleicht sogar praktisch )
    if (c.length > 0) {
      const selected = c[0];
      this.selectedAic = selected.aic;
      this.selectedBezeichnung = selected.bezeichnung;
      this.selectedQuery = subAbfrage.kennung;
    } else {
      this.selectedAic = null;
    }
  }

  public reloadData() {
    try {
      const me = this;
      this.$api.hauptschirm().then((data: any) => me.dataLoaded(data));
    } catch (e) {
      console.error(e);
    }
  }

  protected dataLoaded(data: any) {
    this.showLoading = true;
    try {
      console.log("Hauptschirm data loaded");
      // Fehlerbehandlung
      if (data.data.error !== undefined) {
        this.$api.onfail(data.data.error);
        console.log(data.data.error);
        alert("ACHTUNG: " + data.data.error);
        this.$emit("back");
        return;
      }

      const dataList: DataListeEntry[] = data.data;
      this.hsItems = [];
      if (dataList && dataList.length > 0) {
        dataList.forEach((element) => {
          this.hsItems.push({
            Bezeichnung: element.Bezeichnung,
            Aic: element.Aic,
            Austritt: element.Austritt,
            Auswahl: element.Auswahl,
            Geloescht: element.Del, // gelÃ¶schte Daten
            Anzeige: element.VL,
            offen: element.offen, // alle Ebenen offen
            prog: element.prog,
          });
        });
      }
      this.hsAic = this.hsItems[0].Aic;
      this.title = this.hsItems[0].Bezeichnung;
      let prog = this.hsItems[0].prog;
      let austritt = this.hsItems[0].Austritt;
      this.openAll = this.hsItems[0].offen ?? false;
      if (this.siteData) {
        this.selectedAic = this.siteData.hsAicSelected ?? 0;
        const actualView = this.hsItems.find((x: any) => x.Aic === this.selectedAic);
        if (actualView) {
          this.hsAic = actualView.Aic;
          this.title = actualView.Bezeichnung;
          prog = actualView.prog;
          austritt = actualView.Austritt;
          this.openAll = actualView.offen ?? false;
        }
      } else if (localStorage.activeHS) {
        const hsAic = window.localStorage.getItem("activeHS");
        const hsAicNumber = Number(hsAic);
        const actualView = this.hsItems.find((x: any) => x.Aic === hsAicNumber);
        if (actualView) {
          this.hsAic = actualView.Aic;
          this.title = actualView.Bezeichnung;
          prog = actualView.prog;
          austritt = actualView.Austritt;
          this.openAll = actualView.offen ?? false;
        }
      }

      this.onHsItemClick(this.hsAic, this.title, prog, austritt, this.openAll); // Subabfragen laden"!
      this.showLoading = false;
    } catch (e) {
      console.error(e);
    }
  }
  private dynamicSort(property: any) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a: any, b: any) => {
      const result =
        a[property].localeCompare < b[property].localeCompare
          ? -1
          : a[property].localeCompare > b[property].localeCompare
          ? 1
          : 0;
      return result * sortOrder;
    };
  }
  private onHsItemClick(
    aic: number,
    bezeichnung?: string,
    prog?: number,
    austritt?: boolean,
    offen?: boolean
  ) {
    try {
      window.localStorage.setItem("activeHS", aic.toString());
      this.initalized = false;
      const me = this;
      this.hsAic = aic;
      this.showSelectAustritt = false;
      if (austritt) {
        this.showSelectAustritt = austritt;
      }
      if (bezeichnung) {
        this.title = bezeichnung;
      }
      this.openAll = offen ?? false;
      if (prog) {
        // drucke pro Stammtyp.... ??
        this.druckItems = this.$globalsDruck("Begriff", false, 0, false, 0, "", prog);
      }
      this.$api
        .getHSEinstellung(aic, "")
        .then((resData: any) => me.getHsSubAbfragen(resData))
        .finally(() => (this.initalized = true));
    } catch (e) {
      console.error(e);
    }
  }
  private getHsSubAbfragen(data: any) {
    const me = this;
    // im data stecken die AICs der Abfragen die pro Ebene geholt werden sollen
    // es stecken auch Parameter ob Ebene offen etc drinnen -daher reine Abfragen rausfiltern!

    // StandardFormular pro Ebene wird hier nun mitgeschickt...
    const allSubData = data.data;
    this.hsStandardFormular = allSubData.filter(
      (c: any) => c.standardFormular !== null && c.standardFormular !== undefined
    );
    this.hsSubAbfragen = allSubData.filter(
      (c: any) => c.aicAbf !== null && c.aicAbf !== undefined
    );
    this.forceRerender();
    this.initalized = true;
  }
  private forceRerender() {
    this.componentKey += 1;
  }
}

declare interface DataListeEntry {
  Bezeichnung: string;
  Aic: number;
  Austritt: boolean;
  Auswahl: boolean;
  Del: boolean;
  VL: string;
  offen: boolean;
  prog?: number;
  Std?: boolean;
  aicBegriff?: number;
  aicHauptschirm?: number;
}
declare interface HsListEntry {
  Bezeichnung: string;
  Aic: number;
  Austritt: boolean;
  Auswahl: boolean;
  Geloescht: boolean;
  Anzeige: string;
  prog?: number;
  offen?: boolean;
}
