


















































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import Constants from "../../Constants";
import VTimeLineCarouselBase from "@/components/common/VTimeLineCarouselBase";
import CreateList from "../CreateList";

@Component
export default class VTimeLineCarousel extends VTimeLineCarouselBase {
  @Prop({ default: 7 }) public daysBack!: number;
  @Prop({ default: 0 }) public daysForward!: number;
  @Prop({ default: null }) private goDate!: Date | null;
  @Prop({ default: false }) private showName!: boolean;
  @Prop() private nameUebergabe!: string;
  // @Prop({ default: false }) private admin!: boolean;

  @Prop() private textKennung!: string;
  private textValue: string = "";
  protected static scrollToDay: Date | undefined;
  private maxWidth: any = "";
  private minWidth: any = "";
  private minDate: string = "";

  public created() {
    if (this.goDate) {
      VTimeLineCarousel.scrollToDay = new Date(this.goDate);
    }
    this.setSizes();
    this.createdBase();
  }

  public setSizes() {
    if (Constants.isMobile && this.$isPhone()) {
      this.maxWidth = window.innerWidth;
      this.minWidth = window.innerWidth;
    } else {
      this.maxWidth = window.innerWidth - 20;
      this.minWidth = window.innerWidth;
    }
    // this.$nextTick(() => this.onRefresh());
    // ausgeschalten da sonst beim Bearbeiten der Reisen der memo Text durch das Keyboard ein reload aktiviert!
    // und damit die maske schliesst!
  }

  public onResize(event: any) {
    // console.log("VTimelineCarousel.OnResize");
    super.onResize(event);
    this.setSizes();
  }

  public mounted() {
    if (!VTimeLineCarousel.scrollToDay) {
      VTimeLineCarousel.scrollToDay = new Date();
    }
    VTimeLineCarouselBase.scrollToDate = VTimeLineCarousel.scrollToDay;

    this.mountedBase();
  }
  protected calcDates() {
    const validDaysBack = this.calcDaysBack();
    const startDate = new Date(new Date().toISOStringWithTZ()); // umgestellt damit es auch in anderen Zeitzonen klappt
    startDate.setHours(0, 0, 0, 0);
    for (let i = validDaysBack * -1; i <= this.daysForward; i++) {
      this.dates.push(startDate.addDays(i));
    }
  }

  private calcDaysBack() {
    // periodensperre checken
    let sperrDate = this.getSperrDate();
    if (sperrDate) {
      this.minDate = sperrDate.toISOStringWithTZ().substr(0, 10);
      sperrDate = sperrDate.addDays(-1);
      // 16.3.2022 sperrdate 1 tag abziehen - sonst rechne ich zu kurz - statt 1.3 - 2.3
      console.log("sperre: " + JSON.stringify(sperrDate));
      // +1 weg - sonst wird um 1 Tag zu weit gerechnet Isabelle
      const diff = Math.round(
        (this.today.valueOf() - sperrDate.valueOf()) / (1000 * 60 * 60 * 24)
      );
      return Math.min(diff, this.daysBack);
    }
    return this.daysBack;
  }

  public onRefresh() {
    this.showCarousel = false;
    const timelines = this.$refs.timeline as CreateList[];
    if (timelines) {
      if (timelines.length) {
        for (let i = 0; i < timelines.length; i++) {
          const timeline = timelines[i];
          timeline.reloadData();
        }
      } else {
        const timeline = this.$refs.timeline as CreateList;
        if (timeline && timeline.reloadData) {
          timeline.reloadData();
        } else {
          console.log("no timeline no cry...");
        }
      }
    }
    this.showCarousel = true;
  }
  protected onTabChange(tab: number) {
    if (!tab) {
      // @change wird bei einer gewissen grÃ¶Ãe schon beim Aufbau aufgerufen
      // da gibt es noch gar kein eingestelltes tab - daher muss dann auf HEUTE positioniert werden!
      VTimeLineCarousel.scrollToDay = new Date();
      VTimeLineCarouselBase.scrollToDate = VTimeLineCarousel.scrollToDay;
      return;
    }
    VTimeLineCarousel.scrollToDay = VTimeLineCarouselBase.scrollToDate = this.dates[
      tab * this.timelinesInPage + 1
    ];
  }
  protected gotoDateNow(date: Date | string) {
    this.gotoDate(date);
  }
}
