


































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import CreateList from "@/components/CreateList";
import BaseList from "@/components/baseList.vue";
import Constants from "@/Constants";
import { Globals } from "@/Globals";
import { getKZValueDisplay, getKZTitle, getKZValue } from "@/DataHelper";
import { showDialog } from "@/UIHelper";
import { showPDF2, showImage } from "@/PdfHelper";

@Component
export default class Kostenersatz extends CreateList {
  @Prop({ default: 300 }) public contentHeight!: number;

  private sperre: boolean = false; // dÃ¼rfen Tage nicht mehr bearbeitet werden- dann wird sperre gesetzt
  @Prop() protected aicReise!: number;
  @Prop() protected nameReise!: string;
  @Prop({ default: "RkMitarbeiterEditKostenersatz" })
  protected editMask!: string;
  @Prop() protected editQuery!: string;
  private itemHeight: string = "110";
  @Prop() protected setSperre!: boolean;
  constructor() {
    super();
    this.showLoading = true;
  }
  public mounted() {
    const Stamm = this.$globalsStt(Globals.Stt, "", "RK_TRAVEL");
    this.$api.setSyncStamm(Stamm.aic, this.aicReise);
    this.jokerStt.push({
      sync: this.aicReise, // aic Stammsatz
      aic: Stamm.aic, // aic Stammtyp
    });
    this.mountedBase();
    try {
      if (!this.mountedBase()) {
        return;
      }
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }
  protected refreshItems() {
    const y = this.$refs.list as BaseList;
    y?.reloadData();
    this.items = []; // ausleeren fÃ¼r refresh

    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      const header = getKZValueDisplay(row, "header", this.columns);

      let title = getKZValueDisplay(row, "titel", this.columns);
      if (title) {
        const textTitle = getKZTitle(row, "titel", this.columns);
        title = textTitle + ": " + title;
      }
      let title2 = getKZValueDisplay(row, "title2", this.columns);

      if (title2) {
        const textTitle2 = getKZTitle(row, "title2", this.columns);
        title2 = textTitle2 + ": " + title2;
      }
      let subtitel = getKZValueDisplay(row, "subtitel", this.columns);
      if (subtitel) {
        const textSubtitel = getKZTitle(row, "subtitel", this.columns);
        subtitel = textSubtitel + ": " + subtitel;
      }
      let subtitel1 = getKZValueDisplay(row, "subtitel1", this.columns);
      if (subtitel1) {
        const textSubtitel1 = getKZTitle(row, "subtitel1", this.columns);
        subtitel1 = textSubtitel1 + ": " + subtitel1;
      }
      let text = getKZValueDisplay(row, "text", this.columns);
      if (text) {
        const textTitel = getKZTitle(row, "text", this.columns);
        text = textTitel + ": " + text;
      }
      let kst = getKZValueDisplay(row, "kst", this.columns);
      if (kst) {
        const textKSt = getKZTitle(row, "kst", this.columns);
        kst = textKSt + ": " + kst;
      }
      let icon = getKZValueDisplay(row, "icon", this.columns);
      const sperre = getKZValue(row, "sperre", this.columns);
      let pdf = getKZValue(row, "pdf", this.columns);
      let memo = "";
      let checkbox = false;
      if (pdf && pdf.name && pdf?.type !== "application/pdf") {
        memo = pdf.name;
        icon = pdf;
        pdf = this.loadImage(pdf);
        this.itemHeight = "120";
      } else {
        checkbox = true;
      }
      this.items.push({
        header,
        title,
        title2,
        subtitel,
        subtitel1,
        textKst: kst,
        text,
        icon,
        divider: true,
        inset: true,
        aic: row.aic_Bew_pool,
        menu: false,
        pdf,
        memo,
        checkbox,
        sperre,
      });
    }
  }
  private openNew(bewegungsdaten: boolean) {
    const me = this;
    let showFullscreen = false;
    if (Constants.isMobile && this.$isPhone()) {
      showFullscreen = true;
    }
    const title = this.nameReise;
    showDialog({
      title,
      site: this.editMask,
      width: 400,
      fullscreen: showFullscreen,
      data: {
        zeitbereich: this.editZeitbereich,
        aicBewegung: "0",
        aicReise: this.aicReise,
        bewegungsdaten,
        editQuery: this.editQuery,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: (x: any) => {
        // me.reloadData();
        if (x.returnedData) {
          me.reloadData();
          const dataSaved = true;
          this.$emit("onReloadData", { dataSaved });
          return true;
        }
        return true;
      },
    });
  }
  private openEdit(aicBewegung: string) {
    const me = this;
    let showFullscreen = false;
    if (Constants.isMobile && this.$isPhone()) {
      showFullscreen = true;
    }
    showDialog({
      title: "bearbeiten",
      site: this.editMask,
      width: 400,
      fullscreen: showFullscreen,
      data: {
        zeitbereich: this.editZeitbereich,
        aicBewegung,
        aic: aicBewegung,
        aicReise: this.aicReise,
        bewegungsdaten: true,
        showDelete: true,
        editQuery: this.editQuery,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: (x: any) => {
        // me.reloadData();
        if (x.returnedData || x.data.aicBewegung) {
          me.reloadData();
          const dataSaved = true;
          this.$emit("onReloadData", { dataSaved });
          return true;
        }
        // const dataSaved = true;
        // this.$emit("onReloadData", { dataSaved });
        return true;
      },
    });
  }
  private loadImage(image: any) {
    if (image.datentyp === "Doku") {
      const base64data = this.$api.fullUrl(
        "pdf2/" + this.$api.user.id + ":" + +image.aic + "_" + image.name
      );
      return base64data;
    } else {
      const base64data = this.$api.fullUrl(
        "image2/" + this.$api.user.id + ":" + +image.aic + "_" + image.name
      );
      return base64data;
    }
  }
  private onShowPdf(pdf: any) {
    showPDF2(pdf.name, pdf.aic, this.$api.user.id);
  }
  private onShowImage(image: any) {
    showImage(image, image.aic, this.$api.user.id);
  }
}
