



























































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import Constants from "@/Constants";
import EditPage from "@/components/EditPage";
import BaseSyncSchedule from "../../../components/baseSyncSchedule.vue";
@Component
export default class AbwesenheitenVG extends EditPage {
  // @Prop() public aic: any;
  private abwZeitraum: Date = this.$api.zeitbereich.von;
  // private abwZeitraum: Date | undefined = new Date();
  protected showTimeLineMonth: boolean = false;
  protected showWeek: boolean = false;
  protected currentView: string = "Month";
  protected allowDragAndDrop: boolean = false;
  private toggleSight: any = "abwesenheiten";
  private showStempelantrag: boolean = false;
  private showDetailantrag: boolean = false;
  protected editorComponent: string = "";
  protected showQuickInfo: boolean = false;
  protected showMonth: boolean = true;
  protected setMinDate: boolean = false;
  protected query: string = "";
  protected saveQuery: string = "";
  protected showGroup: boolean = true;
  protected queryMitarbeiter: string = "";
  protected group2: string = "";
  protected readBewilligt: boolean = false;
  protected readOnly: boolean = false;
  protected onlyEdit: boolean = false;
  protected saveModell: string = "";
  protected useCellTemplate: boolean = false;
  protected gotoAic: string = "";
  private loading: boolean = false;
  private loading1: boolean = false;
  private disabled: boolean = false;
  private hideMeForRefresh: boolean = false;
  private showRow: number = 0;
  protected group: any = {};
  private loader: any = null;
  private showBack: boolean = false;
  private formularUebergabe: any;

  public mounted() {
    this.formularUebergabe = this.$globalsKennung(
    this.Globals.Begriff,
    "PzePersoenlichPlanungenAntraege"
  );
    this.showRow = 0;
    if (this.siteData?.showBack) {
      this.showBack = this.siteData.showBack;
    }
    if (this.checkBegriff("Lizenz_korrekturantraege")) {
      this.showStempelantrag = true;
      this.showRow++;
    }
    if (this.checkBegriff("Web_SW_Detailantrag")) {
      this.showDetailantrag = true;
      this.showRow++;
    }
  }
  public created() {
    if (this.siteData?.toggleUebergabe) {
      this.toggleSight = this.siteData.toggleUebergabe;
    } else if (localStorage.activeTabAntragMA) {
      if (
        localStorage.activeTabAntragMA !== "undefined" &&
        localStorage.activeTabAntragMA !== "" &&
        localStorage.activeTabAntragMA !== "null"
      ) {
        this.toggleSight = window.localStorage.getItem("activeTabAntragMA");
      } else {
        if (this.checkBegriff("Lizenz_korrekturantraege")) {
          this.toggleSight = "Stempelantraege";
        } else if (this.checkBegriff("Web_SW_Detailantrag")) {
          this.toggleSight = "Detailantraege";
        }
      }
    } else {
      if (this.checkBegriff("Lizenz_korrekturantraege")) {
        this.toggleSight = "Stempelantraege";
      } else if (this.checkBegriff("Web_SW_Detailantrag")) {
        this.toggleSight = "Detailantraege";
      }
    }
    this.changeView();
  }
  private onloaded(loaded: boolean, gotoAic: string) {
    if (loaded) {
      setTimeout(() => {
        this.loading = false;
        this.loading1 = false;
        this.disabled = false;
      }, 300);
    }
  }
  private onZeitraum(newZeitraum: any) {
    this.abwZeitraum = newZeitraum;
  }
  private changeView() {
    const me = this;
    this.loading = false;
    this.loading1 = false;
    this.disabled = true;
    this.hideMeForRefresh = true;

    const schedule = this.$refs.schedule as BaseSyncSchedule;
    // akt. Tab merken fÃ¼r refresh!
    const activeTabAntragMA = window.localStorage.setItem(
      "activeTabAntragMA",
      this.toggleSight
    );
    if (this.toggleSight === "Stempelantraege") {
      this.loading = true;
      this.query = "Web_offeneAntraege_proMA";
      this.editorComponent = "PzePersoenlichPlanungenTemplatesStempelungen";
      this.queryMitarbeiter = "";
      this.readBewilligt = true;
      this.formularUebergabe = "";
      this.allowDragAndDrop = false;
      this.setMinDate = false;
      this.saveQuery = "";
      this.useCellTemplate = true;
    } else if (this.toggleSight === "Detailantraege") {
      this.loading1 = true;
      this.setMinDate = false;
      this.query = "Web_Uebersicht_MA_Detailantrag";
      this.editorComponent =
        "PzePersoenlichPlanungenTemplatesDialogDetailantrag";
      this.queryMitarbeiter = "";
      this.readBewilligt = true;
      this.allowDragAndDrop = false;
      this.useCellTemplate = true;
      this.formularUebergabe = "";
      this.saveQuery = "";
    }
    this.$nextTick(() => {
      this.hideMeForRefresh = false;
    });
  }
}
