var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","offset-y":"","close-on-content-click":_vm.closeOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}])},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list',[_c('v-list-item',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"text":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.setUserPara()}}},[_c('v-icon',[_vm._v("settings_applications")]),_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$begriffBezeichnung("Speichern"))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("pers. Parameter speichern")])])],1),_c('v-list-item',{staticClass:"pt-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteUserPara()}}},[_c('v-icon',[_vm._v("settings_applications")]),_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$begriffBezeichnung("Loeschen"))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("pers. Parameter lÃ¶schen")])])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }