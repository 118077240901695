import * as Msal from "msal";

import { GetUserName, SetLoginHelper } from "@/LoginHelper/LoginHelper";

// if using cdn version, 'Msal' will be available in the global scope

/*
ReadMes:


 - https://github.com/AzureAD/microsoft-authentication-library-for-android
   - https://github.com/AzureAD/microsoft-authentication-library-for-android/wiki/MSAL-FAQ#redirect-uri-issues

 - https://github.com/AzureAD/microsoft-authentication-library-for-objc
*/

declare var ALLConfig: ALLConfigType;


import { Capacitor, registerPlugin } from '@capacitor/core';
import { StringIterator } from "lodash";
import { AzureOAuthPlugin as AzureOAuthPluginImport } from "@/plugins/AzureOAuthPlugin";

let AzureOAuthPlugin = AzureOAuthPluginImport;
if (!Capacitor.isPluginAvailable('AzureOAuthPlugin')) {
    AzureOAuthPlugin = undefined;
}

export function isAzureConfigSet() {
    return ALLConfig.azureConfig !== undefined;
}


let msalInstance: Msal.UserAgentApplication;
let currentToken: string;
let webAuthAccount: Msal.Account | undefined;

let userName: string;
let name: string;

let loginOn: Date;
let expiresOn: Date;
let gotToken: Date;
let initialized = false;

export async function initAzureLogin() {
    const msalConfig = ALLConfig.azureConfig?.msalConfig!;
    if (!ALLConfig.azureConfig) { return Promise.resolve(); }

    if (AzureOAuthPlugin) {
        await AzureOAuthPlugin.initAzureNative({
            clientId: ALLConfig.azureConfig.msalConfig.auth.clientId,
            tenantId: "",
            authority: ALLConfig.azureConfig.msalConfig.auth.authority
        });

        initialized = true;
        return;
    }

    msalInstance = new Msal.UserAgentApplication(msalConfig);
    msalInstance.handleRedirectCallback(tokenReceivedCallback, errorReceivedCallback);
    initialized = true;
    return;
}

function errorReceivedCallback(authErr: Msal.AuthError, accountState: string) {
    console.error(authErr.errorMessage);
}

function tokenReceivedCallback(response: Msal.AuthResponse) {
    setResponseData(response);
}

export function logoutAzure() {

    const doLogout = webAuthAccount !== undefined;
    currentToken = "";
    webAuthAccount = undefined;

    if (doLogout) {
        if (AzureOAuthPlugin) {
            AzureOAuthPlugin.logout();
            return;
        }
        msalInstance.logout();
    }
}

async function doAzureLoginViaPopup() {
    if (!msalInstance || !initialized) { initAzureLogin(); }

    const response = await msalInstance.loginPopup(ALLConfig.azureConfig?.authParameters);

    setResponseData(response);
    return true;
}

function doAzureLoginViaRedirect() {
    msalInstance.loginRedirect(ALLConfig.azureConfig?.authParameters);
}

export async function doAzureLogin() {
    if (!msalInstance || !initialized) { initAzureLogin(); }

    if (AzureOAuthPlugin) {
        const result = await AzureOAuthPlugin.Authenticate({ scopes: ALLConfig.azureConfig!.authParameters.scopes?.join(";") ?? "User.Read" });
        currentToken = result.token;
        expiresOn = new Date(result.expires);
        userName = result.userName;
        name = "";
        gotToken = new Date();

        SetAzureLoginHelper();
        return true;
    }
    doAzureLoginViaRedirect();
    return false;
}

export function SetAzureLoginHelper() {
    SetLoginHelper({
        login: doAzureLogin,
        logout: () => new Promise((r, j) => logoutAzure()),
        getDisplayName: () => new Promise((r, j) => r(getAzureAccountName())),
        getUserName: () => new Promise((r, j) => r(getAzureUserName())),
        refresh: checkRefreshToken,
        getToken: getAzureToken
    });

}

function handleResponse(authErr: Msal.AuthError, response?: Msal.AuthResponse) {
    if (response) {
        setResponseData(response);
    } else {
        // In case multiple accounts exist, you can select
        checkAccounts();
    }
}
async function checkAccounts(triggerLogin: boolean = false) {
    try {
        const currentAccounts = msalInstance.getAllAccounts();

        if (msalInstance.getAccount()?.idToken) {
            currentAccounts.push(msalInstance.getAccount());
        }
        if (currentAccounts.length === 0 && triggerLogin) {
            // no accounts signed-in, attempt to sign a user in
            msalInstance.loginRedirect(ALLConfig.azureConfig?.authParameters);
        } else if (currentAccounts.length > 1 && triggerLogin) {
            // Add choose account code here
            msalInstance.loginRedirect(ALLConfig.azureConfig?.authParameters);
        } else if (currentAccounts.length === 1) {
            setAccountData(currentAccounts[0]);
        }
    } catch (err) {
        console.error(err);
    }
}

function doLoginViaReditrect() {
    if (!msalInstance) { initAzureLogin(); }
    msalInstance.loginRedirect(ALLConfig.azureConfig?.authParameters);
}

function setResponseData(response: Msal.AuthResponse) {
    loginOn = new Date();
    currentToken = response.idToken.rawIdToken;
    // const tokenType = response.tokenType;
    // if (tokenType === "id_token") {
    //     currentToken = response.idToken.rawIdToken;
    // } else {
    //     currentToken = response.accessToken;
    // }
    webAuthAccount = response.account;
    userName = response.account.userName;
    name = response.account.name;
    expiresOn = response.expiresOn;
}

function setAccountData(newAccount: Msal.Account) {
    loginOn = new Date();
    webAuthAccount = newAccount;
    // const tokenType = newAccount.tokenType;
    // if (tokenType === "id_token") {
    //     currentToken = newAccount.idToken.rawIdToken;
    // } else {
    //     currentToken = newAccount.accessToken;
    // }
    currentToken = newAccount.idToken.rawIdToken;
    userName = newAccount.userName;
    name = newAccount.name;

    expiresOn = new Date(parseInt(newAccount.idToken.exp, 10) * 1000);
}

export async function checkRefreshToken() {
    if (AzureOAuthPlugin) {
        const newToken = (await AzureOAuthPlugin.checkRefreshToken())?.token;
        return newToken;
    }

    if (!currentToken || !webAuthAccount) {
        doAzureLogin();
        return;
    }

    const tokenDuration = loginOn.diffInMinutes(expiresOn);
    const remaining = loginOn.diffInMinutes(new Date());
    if (tokenDuration < 0 || remaining < tokenDuration / 2) {
        const response = await msalInstance.acquireTokenSilent({
            account: webAuthAccount,
            scopes: ALLConfig.azureConfig!.authParameters.scopes
        });
        setResponseData(response);
    }
    return currentToken;
}

export function getAzureAccountName() {
    return name + "(" + userName + ")";
}
export function getAzureToken() {
    return new Promise<string>((resolve, reject) => {
        if (AzureOAuthPlugin) {
            return AzureOAuthPlugin
                .getAccessTokenNative().then(r => resolve(r.token))
                .catch(e => reject(e));
        }
        resolve(currentToken);
    });
}
export function getAzureUserName() {
    return userName;
}

export async function getAzureCallbackUrl() {
    if (Capacitor.getPlatform() === "android") {
        return AzureOAuthPlugin?.getCallbackUrl() ?? "--";
    }
    return "--";
}
