

























































































































































import { Component, Prop } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { Globals } from "@/Globals";
import EditDateRange from "@/editor/EditDateRange.vue";
import { calcFunctionNew } from "@/CalcHelperStatic";
import {
  getKennungValueDisplay,
  getKennungValue,
  getKennungTitle,
  getColumnByKennung,
} from "@/DataHelper";
import { showSnackbar } from "@/UIHelper";

@Component
export default class Dialog extends EditPage {
  @Prop() public checkDateRange!:
    | ((von: Date, bis: Date, aic: string) => boolean)
    | undefined;
  private IsAllDay: boolean = false;
  private color: any = "success";
  private stellVertreter: boolean = false;
  private showStatusStellvertreter: boolean = false;
  private saveModell = "WEB_PZE_PlanungDoppelbuchungen";
  private stellBew: any = false;
  private date: any = "";
  private date1: any = "";
  public allColumns: any[] = [];
  private bewilligung: boolean = false;
  private bewilligt: boolean = false;
  private offen: boolean = false;
  private stellOffen: boolean = false;
  private sperrBegriff: any = "";
  private bewStell: any = "";
  private b2Aic: number = 0;
  private b2Titel: string = "";
  private aicOffen: number = 0;
  private aicEig: number = 0;
  private textOffen: string = "";
  // private step: number = 1;
  private resturlaub: string | undefined = "";
  private loading: boolean = false;
  private loader: any = null;
  constructor() {
    super();
    this.query = "Web_Planung_Dialog";
    this.showLoading = true;
    this.step = 1;
    this.formular = this.$globalsKennung(
      this.Globals.Begriff,
      "Web_Abwesenheiten_Uebersicht"
    );
  }
  public mounted() {
    this.mountedBase();
  }

  public get showDelete() {
    return this.data && this.data.aic_Bew_pool && this.data.aic_Bew_pool > 0;
  }

  public onDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.$emit("delete", this.data.aic_Bew_pool);
    }
  }
  protected initialize() {
    this.sperrBegriff = this.$globalsKennung(this.Globals.Begriff, this.query);
    const allDay = getKennungValueDisplay(this.data, "B_CALC_FIELD", this.columns);
    if (allDay === true) {
      this.IsAllDay = true;
    }

    const zuBewilligen = getKennungValueDisplay(this.data, "GRANTED", this.columns);

    if (zuBewilligen === "ja") {
      this.bewilligung = true;
    }
    const checkBewilligt = getKennungValueDisplay(this.data, "b1", this.columns);
    if (this.bewilligung) {
      if (checkBewilligt === "bewilligt") {
        this.bewilligt = true;

        // this.color = "warning";
      } else if (checkBewilligt === "abgelehnt") {
        // this.color = "white";
      } else if (checkBewilligt === "offen") {
        // this.color = "white";
        this.offen = true;
      }
    }
    const checkStellvertreter = this.checkBegriff("Web_Stellvertreter");
    if (checkStellvertreter) {
      this.stellVertreter = true;
      this.bewStell = getKennungValueDisplay(this.data, "b2", this.columns);
      this.b2Aic = getKennungValue(this.data, "b2", this.columns);
      this.b2Titel = getKennungTitle("b2", this.columns);
      const column = getColumnByKennung("b2", null, this.columns);
      const combo = column?.meta.combo;
      this.aicEig = column?.aicEig;
      if (combo) {
        this.aicOffen = combo[0].aic;
        this.textOffen = combo[0].bezeichnung;
      }
      if (this.bewStell === "bewilligt") {
        this.stellBew = true;
      } else if (checkBewilligt === "offen") {
        this.stellOffen = true;
      }
      const existStellvertreter = getKennungValueDisplay(this.data, "SV", this.columns);
      if (existStellvertreter) {
        this.showStatusStellvertreter = true;
      }
    }
    if (!this.data.aic_Bew_pool) {
      // ist es ein besteheder Satz dann braucht er nicht prÃ¼fen
      if (!this.checkIntersection(true)) {
        this.$emit("back");
      }
    }
  }

  private checkIntersection(skipChanging: boolean = false) {
    if (this.checkDateRange) {
      const dateRange = this.$refs.dateRange as EditDateRange;
      if (
        dateRange &&
        (skipChanging || dateRange.changing) &&
        dateRange.von &&
        dateRange.bis
      ) {
        const von = dateRange.von;
        let bis = dateRange.bis;
        if (von.valueOf() === bis.valueOf() && dateRange.IsAllDay) {
          bis = bis.addDays(1);
        }
        if (!this.checkDateRange(von, bis, this.data.aic_Bew_pool)) {
          return false;
        }
      }
    }
    return true;
  }

  private save() {
    if (!this.checkIntersection()) {
      showSnackbar(
        this.$globalsBezeichnung(Globals.Begriff, "Ueberschneidung", "Ãberschneidung!")
      );
      return;
    }
    const aicAbfAllBez = this.$globalsKennung(Globals.Begriff, "WebPlanungSpeichern");
    const varName = "BewAic";
    const varUbergabe: any[] = [];
    if (this.data.aic_Bew_pool) {
      const wert = this.data.aic_Bew_pool;

      varUbergabe.push({
        var: varName,
        type: "double",
        wert,
        art: 2,
        user: this.$api.user.user,
        perm: false,
      });
    }

    const me = this;
    const dateRange = this.$refs.dateRange as EditDateRange;

    // ich muss von - bis das erfasst ist als Zeitraum ans modell Ã¼bergeben!!
    // das kleinste Datum muss genommen werden, das alle Timer zurÃ¼ck
    // gesetzt werden kÃ¶nnen!
    let vonModellZeitbereich = dateRange.von;
    const vonZeitbereich = this.siteData.zeitbereich.von;
    if (vonModellZeitbereich) {
      if (vonModellZeitbereich > vonZeitbereich) {
        vonModellZeitbereich = vonZeitbereich;
      }
    }
    let bisModellZeitbereich = dateRange.bis;
    let bisZeitbereich = this.siteData.zeitbereich.bis;
    // siteData BIS ist 1 Tag MEHR!

    bisZeitbereich = new Date(bisZeitbereich).addDays(-1);
    if (bisModellZeitbereich) {
      if (bisModellZeitbereich < bisZeitbereich) {
        bisModellZeitbereich = bisZeitbereich;
      }
    }
    const modellZeitbereich = {
      von: vonModellZeitbereich,
      bis: bisModellZeitbereich,
      bereich: "Tag",
    };
    calcFunctionNew(
      this.saveModell,
      this.$api.user.aic,
      false,
      me,
      modellZeitbereich,
      varUbergabe
    )?.then((response: any) => {
      if (response !== "Error" && response?.msgType !== "Error") {
        if (aicAbfAllBez) {
          const aicAbfAll = aicAbfAllBez.aic;
          me.onSave(aicAbfAll, "", modellZeitbereich)?.then(() => {
            this.$nextTick(() => me.$emit("save"));
            // me.$emit("save");
          });
        } else {
          showSnackbar({
            text: "Abfrage nicht gefunden",
            color: "error",
          });
          this.showLoading = false;
          return;
        }
      }
      return;
    });
  }
  private checkValue(evt: any) {
    this.bewStell = this.textOffen;
    this.stellOffen = true;
    const spalte = "e" + this.aicEig;
    for (const i in this.data) {
      if (i === spalte) {
        this.data[i] = this.aicOffen;
      }
    }
  }
  private calcSalden() {
    this.loading = true;
    const me = this;

    const modell = "Web_Resturlaub_per_Stichtag";
    let begriff: any;
    if (modell) {
      begriff = this.$globalsKennung(Globals.Begriff, modell);
    }
    if (!begriff) {
      showSnackbar({
        text: "Modell nicht gefunden:" + modell,
        color: "error",
      });
      this.showLoading = false;
      return;
    }
    this.$api.postCalc(
      [],
      [],
      begriff.aic,
      this.aic,
      this.zeitbereich,
      0,
      0,
      "",
      this.successCalc,
      begriff.maxB
    );
  }
  private successCalc(res: any) {
    this.resturlaub = res.data.ergebnis;
    this.loading = false;
    this.step++;
    return;
  }
}
