























































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
interface TimeSpanItem {
  text: string;
  value: number;
}

@Component
export default class EditTimeSpanPicker extends EditComponent {
  public times: TimeSpanItem[] = [];
  public hourparts: number = 2; // da einstellen ob 2 oder 4 teile (30 min oder 15 min)
  private selectedItem: number | null = null;
  private format: string = "HH:mm";
  private mask: string = "11:51";
  private showField: boolean = true;
  private inputText: string = "--:--";
  private showPicker: boolean = false;

  @Prop() public minHours!: number;
  @Prop({ default: 0 }) protected maxHours!: number;

  private menuLeft: number = 0;
  private menuTop: number = 0;

  private zeroPad(num: number, places: number) {
    return String(num).padStart(places, "0");
  }

  public mounted() {
    console.log(this.maxHours);
    for (let hour = 0; hour < 24; hour++) {
      for (let min = 0; min < 60; min += 60 / this.hourparts) {
        this.times.push({
          text: this.zeroPad(hour, 2) + ":" + this.zeroPad(min, 2),
          value: hour + min / 60,
        });
      }
    }

    this.mountedBase();
    // this.mask = CalenderHelper.getMask(this.format);
    this.fillInitValue();
  }
  protected get disabledText() {
    const text = this.description + ": " + this.inputText;
    return text;
  }
  private fillInitValue() {
    console.log(JSON.stringify(this.maxHours));
    let valObjekt = this.getInitValue();
    if (!valObjekt) {
      valObjekt = this.maxHours;
    }
    let val = valObjekt;

    if (valObjekt) {
      if (valObjekt.value) {
        val = valObjekt.value;

        if (valObjekt.faktor > 0) {
          val = val / valObjekt.faktor;
        }

        if (typeof val === "string") {
          val = this.parseTimeString(val);
        }
      }

      if (val && val > 0) {
        let hour = Math.floor(val);
        let min = Math.round((val - hour) * 60);
        if (min === 60) {
          hour = 1;
          min = 0;
        }
        this.inputText = this.zeroPad(hour, 2) + ":" + this.zeroPad(min, 2);
        // value Changed muss aufgerufen werden, damit der Wert zum Speichern sauber gesetzt ist!
        this.valueChanged(val);
      }
    }
  }

  public focusMe() {
    (this.$refs.masked as any).focusMe();
  }

  public clearAndFocus(toInitValue: boolean = true) {
    this.showField = false;
    if (toInitValue) {
      this.fillInitValue();
    } else {
      this.inputText = "--:--";
    }
    this.$nextTick(() => {
      this.showField = true;
      this.$nextTick(() => {
        this.focusMe();
      });
    });
  }

  private clickAppendTime() {
    this.selectedItem = this.times.findIndex(
      (t) => t.text === this.inputText.substring(0, 2) + ":00"
    );
    this.showPicker = !this.showPicker;
    try {
      const rect = (this.$refs.markerspan as HTMLSpanElement).getBoundingClientRect();
      const txtRect = (this.$refs.masked as any).$vnode.elm.getBoundingClientRect();

      this.menuLeft = rect.left;
      if (rect.width + 250 > window.innerWidth) {
        this.menuLeft -= 250;
      } else if (rect.top + 300 > window.innerHeight) {
        this.menuLeft += txtRect.width;
      }
      this.menuTop = rect.top;
    } catch (e) {
      console.error(e);
    }
  }

  private changedTime(x: string) {
    let val: number | null = null;
    let isEmpty = true;
    let isNotFull = true;
    this.errorText = "";
    this.$emit("saveNichtMoeglich");
    if (x) {
      isEmpty = x.replace(/\s:-/g, "").length === 0;
      isNotFull = x.indexOf("-") >= 0;
    }

    if (isNotFull || isEmpty) {
      if (this.required) {
        this.errorText = this.requiredMessage;
      }
      this.valueChanged(null);
      return;
    }

    if (x.indexOf(":") >= 0) {
      val = this.parseTimeString(x);
    }

    if (this.minHours && (val ?? 0) < this.minHours) {
      this.errorText = "zu klein";
      return;
    }
    if (this.maxHours && (val ?? 0) > this.maxHours) {
      this.errorText = "zu groÃ";
      return;
    }

    if (val) {
      console.log("EditTimeSpanPicker: " + JSON.stringify(val));
    }

    if (val) {
      if (this.errorText === "") {
        this.$emit("saveMoeglich");
      }
      this.valueChanged(val);
    } else {
      this.valueChanged(null);
    }
  }

  private parseTimeString(x: string) {
    const contents = x.split(":");
    const hour = parseInt(contents[0], 10);
    const min = parseInt(contents[1], 10);
    return hour + min / 60;
  }

  private setTime(item: TimeSpanItem) {
    this.showField = false;
    this.showPicker = false;
    this.inputText = item.text;
    this.$emit("saveMoeglich");
    this.valueChanged(item.value);
    this.$nextTick(() => {
      this.showField = true;
      this.$nextTick(() => {
        this.focusMe();
      });
    });
  }
}
