









































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Container, Draggable } from "vue-dndrop";
Vue.component("draggable", Draggable);
Vue.component("container", Container);

@Component
export default class Kanban extends Vue {
  @Prop({ default: false }) protected loading!: boolean;
  @Prop({ default: () => [] }) protected columnBoard!: any[];
  @Prop({ default: "" }) protected colWidth!: number | string;
  @Prop({ default: "" }) protected colMinWidth!: number | string;
  @Prop({ default: "" }) protected colMaxWidth!: number | string;
  @Prop({ default: "Drop here" }) protected dropText!: string;
  @Prop({ default: "copy" }) protected dropIcon!: string;
  @Prop({ default: false }) protected noBorder!: boolean;
  @Prop({ default: false }) protected innerColCount!: boolean;
  @Prop({ default: "" }) protected colBgColor!: string;
  @Prop({ default: "itens" }) protected countText!: string;
  @Prop({ default: () => [] }) protected columnCount!: any[];

  private sourceContainerIndex: number | null = null;
  private kanbanColumns: any[] = [];
  private dropPlaceholderOptions = {
    className: "drop-preview",
    animationDuration: "150",
    showOnTop: true,
  };

  private get colSkeleton(): number {
    const screenSize = window.innerWidth - 300;
    const quantity = screenSize / (Number(this.colMinWidth) || 180);
    return Math.ceil(quantity);
  }

  private get columnStyle(): string {
    return `width: ${this.colWidth}px;
          min-width: ${this.colMinWidth}px;
          max-width: ${this.colMaxWidth}px;
          `;
  }

  private get contentStyle(): string {
    return `background-color: ${this.colBgColor}`;
  }

  private get hasExternalCount(): boolean {
    return this.columnCount && !!this.columnCount.length;
  }

  private mounted() {
    this.kanbanColumns = [...this.columnBoard];
  }

  private dropAction(arr: any[], dragResult: any, column: any): any[] {
    const { removedIndex, addedIndex, payload } = dragResult;
    const { status } = column;
    if (removedIndex === null && addedIndex === null) {
      return arr;
    }

    const items = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = items.splice(removedIndex, 1)[0];
    }
    if (addedIndex !== null) {
      items.splice(addedIndex, 0, itemToAdd);
      if (payload.status.length > 1) {
        this.$emit("item-dropped", { item: payload, column: status });
      } else if (payload.status !== status) {
        this.$emit("status-change", {
          item: payload,
          newStatus: status,
        });
      }
    }

    return items;
  }

  private validation(
    src: any,
    payload: any,
    index: number,
    validator: (src: any, payload: any, index: number) => boolean = () => true
  ): boolean {
    const isSource = index === this.sourceContainerIndex;
    return isSource || validator(src, payload, index);
  }

  private onDrop(index: number, dropResult: any, column: any) {
    this.kanbanColumns[index].columnItems = this.dropAction(
      this.kanbanColumns[index].columnItems,
      dropResult,
      column
    );
  }

  private getDraggedItem(item: number, indice: number) {
    debugger;
    this.sourceContainerIndex = indice;
    return this.kanbanColumns[indice].columnItems[item];
  }

  private dropNotAllowed({ payload, container }: { payload: any; container: any }) {
    console.log("drop not allowed", payload);
  }

  private countValue(column: any, index: number) {
    if (!this.hasExternalCount) {
      return column.columnItems.length;
    }

    const isNumeric = (value: any) => !isNaN(Number(value));
    const trueValue = (value: any) => value || value === 0;
    const validNumericArray =
      this.columnCount.length === this.columnBoard.length &&
      this.columnCount.every((value: any) => isNumeric(value));

    if (validNumericArray) {
      return trueValue(this.columnCount[index])
        ? this.columnCount[index]
        : column.columnItems.length;

      const currentValue = this.columnCount.find((item: any) => item.id === column.id)
        ?.value;

      return trueValue(currentValue) ? currentValue : column.columnItems.length;
    }
  }
}
